.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.loginWrapper {
  display: flex;
  justify-content: center;

  height: 100%;

  background-image: url('./assets/computer-background.png');
  background-position: center;
  background-size: cover;
}

.loginBox {
  margin-top: 100px;

  width: 600px;
  height: 600px;

  text-align: center;
  background-color: white;
  border: 1px solid var(--grey-1);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.horizontalPadding {
  padding: 0 170px;
}

.headerLogo {
  position: absolute;
  top: var(--offset-lg);
  left: var(--offset-xxx-lg);
  width: 100px;
}

.logo {
  width: 220px;
  margin: 50px 0;
}

.schoolName {
  margin-top: var(--offset-xx-sm);
  padding: 0 60px;
}

.checkboxLabelText {
  text-align: left;
}
.checkboxContainer {
  margin: var(--offset-lg) auto 0 auto;
}

.preferredNameField {
  text-align: left;
  margin-top: var(--offset-lg);
}

.googleClassroomButton {
  margin-top: var(--offset-sm);
  background-color: white;

  border: 2px solid #23a566;
  border-radius: 2px;
}
.googleClassroomButton:hover {
  background: #7bcfa9;
}
.googleClassroomButtonText {
  font-weight: 600;
  text-transform: none;
}

.link {
  color: var(--new-blue4);
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}

.linkToLogin {
  color: var(--new-blue1);
  text-decoration: none;
  font-weight: 600;
  border-bottom: 2px solid var(--pink-4);
}
.linkToLogin:hover {
  border-bottom: 3px solid var(--pink-4);
}
.notFromSchool {
  margin-top: var(--offset-xxx-lg);
}

/* Mobile + Tablet */
@media only screen and (max-width: 992px) {
  .loginBox {
    width: 70%;
  }

  /* Tablet devices */
  @media only screen and (min-width: 600px) {
    .loginWrapper {
      background-image: url('./assets/tablet-background.png');
    }
    .horizontalPadding {
      padding: 0 20%;
    }
    .logo {
      width: 180px;
    }
  }

  /* Mobile devices */
  @media only screen and (max-width: 600px) {
    .loginWrapper {
      background-image: url('./assets/mobile-background.png');
    }
    .horizontalPadding {
      padding: 0 10%;
    }
    .logo {
      width: 150px;
    }
  }
}
