.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoSmall {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}
.logoBig {
  display: block;
  width: 200px;
  margin-bottom: 40px;
}

.logInButton {
  position: absolute;
  top: 30px;
  right: 50px;
  border-radius: 5px;
}

.container {
  padding-bottom: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paper {
  margin-top: 100px;
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--new-grey5);
  padding: 30px 100px 50px 100px;
}

.checkboxContainer {
  margin: 24px 0;
}

.checkboxLabel {
  margin-left: -12px;
  margin-right: 0;
  align-items: flex-start;
}

.checkboxLabelText {
  text-align: left;
  padding-top: 9px;
}

.googleButtonLabel {
  display: flex;
  font-size: 14px;
  text-transform: none;
}

.googleButton {
  background: #4285f4;
  border: #4285f4 1px solid;
  border-radius: 2px;
  padding: 0;
  margin-bottom: 24px;
}

.googleButton:disabled {
  border: 0;
  background-color: var(--new-grey6);
}

.googleButton:disabled .googleButtonIcon {
  background: transparent;
}

.googleButton:hover {
  background: #4285f4;
}

.googleButtonIcon {
  width: 38px;
  height: 38px;
  margin: 0px;
  padding: 0px var(--offset-xx-sm);
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-self: center;
}

.googleSignUpText {
  width: 100%;
}

.helperText {
  padding-top: var(--offset-xx-sm);
  width: 100%;
}

.error {
  text-align: right;
  width: 100%;
  padding-top: var(--offset-xxx-sm);
}

.link {
  color: var(--new-blue4);
  text-decoration: none;
}

.signUpError {
  margin-top: 20px;
  text-align: center;
}

@media only screen and (max-width: 670px) {
  .container {
    padding-bottom: 0;
  }

  .paper {
    width: 100%;
    border-radius: 0;
    border: 0;
    background: transparent;
    padding: 30px;
    margin-top: 0;
    box-shadow: none;
  }
}
