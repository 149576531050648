.monthCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 375px);
  grid-gap: 10px;
  align-items: flex-start;
}
/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .monthCards {
    grid-template-columns: repeat(auto-fit, 320px);
  }
}

.monthName {
  padding: var(--offset-x-sm) 0 var(--offset-xx-sm) 0;
}
