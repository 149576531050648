@import '_scss-variables.scss';

.headerContainer {
  grid-column-gap: var(--offset-xx-sm);
  max-width: 1010px;
}

.headerContainer > .assignmentHeader {
  grid-column: start / end;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.commentsContainer {
  grid-column: start / end;
}

.comments.comments {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.feedContainer {
  grid-gap: var(--offset-xx-sm);
  max-width: 1010px;
}

.assignmentInfo {
  padding: var(--offset-sm);

  .standardsList {
    margin-top: var(--offset-xx-sm);

    @media screen and (max-width: $max-tablet-screen) {
      margin-top: var(--offset-xxx-sm);
    }
  }
}

.flexRowContainer {
  display: grid;
  grid-template-columns: 100px 300px 1fr;
  grid-gap: var(--offset-sm);
  margin-top: 15px;
  margin-bottom: var(--offset-x-sm);
}

.attachment {
  display: inline-flex;
  align-items: flex-end;
  margin-top: var(--offset-xx-sm);
}

.attachmentName {
  margin-left: var(--offset-xx-sm);
}

.tasksContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--offset-xx-sm);
  margin-top: var(--offset-xx-sm);
  align-items: flex-start;
}

.assignmentDescription {
  /* Make sure if link is placed in description, it does not go out of container */
  overflow-wrap: break-word;
}

/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .tasksContainer {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--offset-sm);
  }
}
