.pageTitle {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
}
.pageTitle > .doneButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pageTitle > .doneButton > button {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.pageTitle > .doneButton > button:hover,
.pageTitle > .doneButton > button:focus {
  text-decoration: none;
}
.pageTitle > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.audioPlayer {
  display: flex;
  padding-bottom: var(--offset-xx-sm);
}

.audioAuthor {
  font-weight: bold;
  color: var(--dark-blue);
}

.taskSwitch {
  height: 48px;
  padding-left: 18px;
  padding-right: 18px;
}

.buttonContainer {
  padding: var(--offset-sm) var(--offset-x-sm);
  background-color: var(--new-grey7);
}
.buttonContainer > button {
  display: flex;
  width: 60%;
  max-width: 400px;
  margin: auto;
}

.audioContainer {
  background-color: var(--new-grey7);
}

.audioContainerIcon {
  padding-left: var(--offset-x-sm);
}
.audioContainerIcon > svg {
  margin-left: var(--offset-md);
}
