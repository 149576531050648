@import '_scss-variables.scss';
.scoreExplanationItem {
  display: flex;
  align-items: center;
  gap: var(--offset-xx-sm);

  width: 100%;

  @media screen and (min-width: $max-mobile-screen) {
    justify-content: center;
  }
}

.textContainer {
  display: flex;
  gap: var(--offset-xx-sm);
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
}

.title {
  color: var(--Pathways-P-Purple-1, #585981);
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  font-size: 17px;
}

.progress {
  width: 100%;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  background-color: 'white';
  gap: 20px;

  @media screen and (min-width: $max-mobile-screen) {
    width: 100%;
  }
}

.percentage {
  color: var(--Pathways-P-Purple-1, #585981);
  font-family: Roboto;
}
