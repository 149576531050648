.notification {
  padding: var(--offset-sm) var(--offset-x-sm) var(--offset-sm) 0;
}

.icon {
  width: 32px;
  height: 32px;
}

.newBadge {
  width: 65px;
  height: 16px;
}

.titleCount {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: 4px;
}

.pagination {
  padding: var(--offset-md);
}

.pagination > ul {
  justify-content: center;
}

.noNotifications {
  position: relative;
  height: 300px;

  background-image: url('./assets/no_notifications.png'),
    url('./assets/background.png');
  background-repeat: no-repeat;
  background-position: center top 30%, center top 50%;
}
.noNotificationsHeader {
  position: absolute;
  left: 0;
  right: 0;
  top: 190px;
}
