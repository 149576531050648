.dialog {
  width: 480px;
  padding: var(--offset-lg) var(--offset-x-lg) var(--offset-x-lg)
    var(--offset-x-lg);
}

.optionsContainer {
  max-height: 400px;
  padding: var(--offset-xx-sm) 0;
  overflow-y: auto;
}

.standardOption {
  cursor: pointer;
  padding: var(--offset-xx-sm) 0;
}

.standardOption:hover {
  background-color: var(--new-grey6);
}

.label {
  padding-bottom: var(--offset-sm);
}

.searchIcon {
  margin-left: 0;
  margin-right: 10px;
}
