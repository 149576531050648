.gridViewContainer {
  padding: 0 var(--offset-sm) var(--offset-xxx-lg) var(--offset-sm);
}

.captureWorkButton {
  width: 235px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  .cameraIcon {
    margin-right: 4px;
  }
}
