@import '_scss-variables.scss';
.tabbedChartSelectionContainer {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  gap: var(--offset-x-sm);
  text-transform: uppercase;
  color: #5a608d;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin tab {
  height: 3px;
  align-self: stretch;
  margin-top: 8px;
  padding-bottom: 9px;
  height: 30px;
  line-height: 30px;
}

.tab {
  @include tab();
  border-bottom: 3px solid #5a608d;
  cursor: pointer;
}

@media screen and (max-width: $max-mobile-screen) {
  .tab {
    cursor: default;
  }
}

.tabActive {
  @include tab();
  border-bottom: 3px solid var(--pink-3);
}
