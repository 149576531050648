.cameraContainer {
  margin-top: 1rem;
}

.placeholder {
  border: 2px dashed gray;
}

.status {
  border: 2px dashed gray;
  padding: 0.2rem;
}
