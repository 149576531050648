.paper {
  padding: var(--offset-lg) var(--offset-md) var(--offset-sm) var(--offset-md);
  width: 100%;
  margin: var(--offset-x-sm);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.dummyRightMenu {
  width: 195px;
  flex-grow: 0;
  flex-shrink: 0;
}

.imgSkeleton {
  margin-bottom: var(--offset-lg);
}
