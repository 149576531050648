.dialog {
  max-width: 571px;
  padding: var(--offset-lg) var(--offset-x-lg);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .deleteButton {
      margin-left: var(--offset-xxx-lg);
    }

    .saveButton {
      width: 103px;
    }
  }

  .sortTitleInput {
    margin-top: var(--offset-sm);
  }

  .meetingShareSelect {
    margin-top: var(--offset-sm);
    border: 1px solid var(--new-grey4);
    border-radius: 5px;

    .selectAll {
      display: flex;
      align-items: center;
      background-color: var(--new-grey7);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .selectableMeetingShare {
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
        background-color: var(--new-grey7);

        transition: background-color 0.2s ease;
      }

      &.selected {
        background-color: var(--new-grey6);
      }

      &.disabled {
        cursor: not-allowed;
      }
    }
  }
}
