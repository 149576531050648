.flex {
  display: flex;
  justify-content: center;
}

.flex.spaceBetween {
  justify-content: space-between;
}

.flex.flexStart {
  justify-content: flex-start;
}

.flex.flexEnd {
  justify-content: flex-end;
}

.flex.wrapped {
  flex-wrap: wrap;
}

.flex.column {
  flex-direction: column;
}

.flex.alignCenter {
  align-items: center;
}
