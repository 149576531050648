.noResults {
  position: relative;
  margin-top: var(--offset-xx-lg);
  width: min(100%, 1010px);
  text-align: center;
}

.noResults > .noResultsImage {
  max-width: 100%;
}

.noResults > .noResultsHeader {
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
}
