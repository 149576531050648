.topOffset {
  margin-top: var(--offset-sm);
}

.name:placeholder-shown {
  background-color: white;
  transition: background-color 0.2s ease;
}

.addTaskButton {
  width: 100%;
  height: 50px;
  padding: var(--offset-x-sm) var(--offset-sm);
  margin-top: var(--offset-xxx-sm);
  border: 1px dashed var(--grey-2);
  color: var(--new-blue3);
}

.tasks {
  margin-top: var(--offset-sm);
}

.tasks > .tasksBox {
  padding: var(--offset-sm);
  margin-top: var(--offset-x-sm);
  border: 1px solid var(--grey-1);
  border-radius: 5px;
}

.fakeColumn {
  width: 20px;
}

.criteriaTitle {
  margin-top: var(--offset-md);
  margin-left: var(--offset-sm);
  margin-bottom: var(--offset-xx-sm);
}

.removeCriteria {
  transition: opacity 0.2s ease;

  @media (hover: hover) {
    /* Drop opacity to 0 if "hover" is available(it is not available on touch browsers) */
    opacity: 0;
  }
}

.criteriaBox:hover .removeCriteria {
  opacity: 1;
}

.addCriteriaButton {
  height: 32px;

  color: var(--new-blue3);
  background-color: var(--new-grey6);
  padding: var(--offset-xxx-sm) var(--offset-xx-sm);

  &:hover {
    background-color: var(--new-grey5);
  }
}

.buttonContent {
  display: flex;
  align-items: center;
  text-transform: none;
  font-weight: 600;
}

.buttonIcon {
  font-size: 24px;
  margin-right: var(--offset-xxx-sm);
}

.criteriaNameField {
  margin-bottom: var(--offset-xx-sm);

  &:placeholder-shown {
    background-color: white;
    transition: background-color 0.2s ease;
  }
}

.tasks > .errorAlert {
  font-size: 1rem;
}

.rotated {
  transform: rotate(90deg);
}

.action {
  height: 25px;
  width: 25px;
  color: var(--new-grey3);

  &:hover {
    color: var(--new-grey1);
    cursor: pointer;
  }

  &.disabled,
  &:disabled:hover {
    color: var(--new-grey4);
    cursor: not-allowed;
  }
}

.standardButton > p {
  font-weight: 600;
}
