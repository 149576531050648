@import '_scss-variables.scss';

.monthName {
  padding: var(--offset-x-sm) 0 var(--offset-xx-sm) 0;

  @media screen and (max-width: $max-mobile-screen) {
    background-color: white;

    left: 0;
    top: -1px;
    z-index: 2;
    position: sticky;
    padding: var(--offset-x-sm) var(--offset-xx-sm);
    border-bottom: 1px solid var(--new-grey5);
  }
}

.monthCards {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 375px);

  @media screen and (max-width: $max-mobile-screen) {
    gap: unset;
    display: flex;
    flex-direction: column;
  }
}
