.iconButton {
  padding: 10px;
}
.menuPaper {
  border-radius: 5px;
}

.menuList {
  padding: 0;
}
.menuList > li {
  border-bottom: 1px solid var(--grey-1);
  padding: var(--offset-xx-sm) var(--offset-sm);
}
.menuList > li:last-child {
  border-bottom: none;
}
.studentLink {
  display: flex;
  align-items: center;
}
.studentLink > svg {
  margin-left: var(--offset-xxx-sm);
}
