.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  font-weight: bold;
  text-align: center;

  h2 {
    color: #ff4081;
    margin-bottom: 8px;
    font-size: 25px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }

  p {
    color: #8c88c2;
    font-size: 20px;
    margin: 0 auto;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
    }
  }
}
