@import '_scss-variables.scss';

.dialogContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $max-mobile-screen) {
    display: block;
    width: calc(100vw - 20px);
  }
}

.dialog {
  .paperRoot {
    padding: var(--offset-x-sm);
    overflow: hidden;
    width: 100%;
    max-width: 60vw;
    max-height: 90vh;
    overflow-y: scroll;
  }

  @media screen and (max-width: $max-mobile-screen) {
    margin-top: 10px;
    margin-bottom: 10px;
    div[class*='closeButton'] {
      background: none;
      top: 8px;
      right: 5px;
    }

    .paperRoot {
      max-width: 90%;
      max-height: fit-content;
    }
  }
}

.studentName {
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--new-blue1);
}

.dialogContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--offset-x-sm);
  width: 100%;

  @media screen and (max-width: $max-mobile-screen) {
    display: flex;
    flex-direction: column;
    max-height: none;
  }
}

.leftCard {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  border-radius: 8px;
}

.imageButtonGroup {
  color: white;
  white-space: nowrap;
}

.rightCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--offset-x-sm);

  @media screen and (max-width: $max-mobile-screen) {
    margin-bottom: var(--offset-x-sm);
    > div {
      max-width: 100%;
    }
  }
}

.studentHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.headingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.svgIcon {
  color: var(--new-blue1);
}
