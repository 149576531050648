.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--offset-sm);
}

.footer > * {
  max-width: 25%;
}

.studentName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paper {
  margin: var(--dialog-close-button-size);

  /* The whole viewport width without the margins of the dialog */
  max-width: calc(
    100vw - var(--dialog-close-button-size) - var(--dialog-close-button-size)
  );

  /* The whole viewport height without the margins of the dialog */
  max-height: calc(
    100vh - var(--dialog-close-button-size) - var(--dialog-close-button-size)
  );
  text-align: center;
}

.image {
  max-width: 100%;

  /* Dialog max-height without the height of the footer */
  max-height: calc(
    100vh - var(--dialog-close-button-size) - var(--dialog-close-button-size) -
      80px
  );

  width: auto;
  height: auto;

  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.arrow.left {
  left: 0;
}
.arrow.left svg {
  transform: rotate(180deg);
}
.arrow.right {
  right: 0;
}

.tasksButtons {
  display: block;
}

.tasksButtons > p {
  display: inline-flex;
  justify-content: center;
}
