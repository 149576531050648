.container {
  & > * {
    position: relative;
    z-index: 2; // so that everything appears above the background gif.
  }

  .confetti {
    top: 0;
    left: 0;
    z-index: 1;
    position: absolute;

    width: 100%;
    height: 100%;
  }

  .title {
    margin-top: var(--offset-lg);
    margin-bottom: var(--offset-x-lg);
  }

  .sectionContainer {
    margin-top: var(--offset-lg);

    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
