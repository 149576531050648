.noMarginPaper {
  margin: 0;
  border-top: none;
}
.smallMarginPaper {
  margin: var(--offset-x-sm) 170px var(--offset-x-sm) var(--offset-sm);
}
.bigMarginPaper {
  margin: var(--offset-x-sm) 170px;
}

.paperHeading {
  padding: var(--offset-md) var(--offset-x-lg) var(--offset-md) var(--offset-sm);
}

.paperSection {
  padding: var(--offset-md) var(--offset-xxx-lg);
}

.assignedSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trashIcon {
  width: 16px;
  height: 16px;
}
.archiveAllButton {
  margin-right: var(--offset-sm);
}
