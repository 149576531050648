.textAreaRoot {
  border-radius: 0px;
  border: 1px solid var(--new-grey2);
  width: 100%;
  height: auto;
}

.confirmationHeader {
  margin-bottom: var(--offset-xx-sm);
}
