.pulseButton {
  color: #ffffff;
  position: relative;
  border: none;
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  border-radius: 50%;
  background-color: #e84c3d;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.noPulseButton {
  color: var(--blue);
  position: relative;
  border-radius: 50%;
  background-color: rgb(243, 243, 243, 0.75);
  cursor: pointer;
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
  }
}

.micIcon {
  height: 70px;
  width: 70px;
}

.iconButton {
  position: relative;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important; /* overrides inline style */
  height: 100% !important; /* overrides inline style */
}
