.contentText {
  padding: var(--offset-x-sm);
  color: var(--new-grey2);
}

.line {
  border-bottom: 2px solid var(--new-grey5);
}

.radio {
  padding: 20px var(--offset-x-sm);
}

.radioGroup {
  display: flex;
  flex-direction: row;
  gap: var(--offset-xx-sm);
  justify-content: center;
  align-items: center;
}

.formGroup {
  display: flex;
  flex-direction: row;
  gap: var(--offset-xxx-lg);
  justify-content: center;
  align-items: center;
}

.option {
  color: var(--new-grey2);
}
