.root {
  width: 100%;
  cursor: pointer;
}

.likeButton {
  color: #d9d9d9;
  height: var(--offset-md);
  min-width: 180px;
  background-color: #f5f5f5;
  font-size: 11px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 12px;
  padding-right: 8px;
}

.likeButton p {
  text-transform: none;
  font-size: 11px;
}

.likeButton:hover {
  background-color: var(--primaryColor);
}

.thumbDownButton {
  color: #d9d9d9;
  background-color: #f5f5f5;
  height: var(--offset-md);
  width: var(--offset-xx-lg);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 2px solid #d9d9d9;
}

.thumbDownIcon {
  width: 20px;
  height: 20px;
}

.thumbDownButton:hover {
  background-color: var(--primaryColor);
}

.readButtonArrow {
  color: #f05b94;
  transform: scale(0.65);
}

.buttonsRow {
  display: flex;
  flex-direction: row;
  gap: var(--offset-xx-sm);
  justify-content: center;
}

.spacer {
  display: flex;
  width: 100%;
}

.thumbsButtonLayout {
  margin: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cardContent {
  display: flex;
  flex-direction: row;
  gap: var(--offset-xx-sm);
  padding-bottom: 0px !important;
}

.cardTitle {
  color: var(--new-blue2);
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px;
  margin-bottom: 8px;
}

.cardText {
  color: var(--new-blue2);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
}
