@import '_scss-variables.scss';

.objectives {
  padding: 0 var(--offset-md) var(--offset-lg) var(--offset-md);

  > .objective {
    margin-top: var(--offset-lg);
  }

  @media screen and (max-width: $max-tablet-screen) {
    padding: 0;

    :first-child {
      margin-top: 0;
    }
  }
}

.objectives > .objective.isChild {
  margin-top: var(--offset-sm);
}

.criteriaLabel {
  padding-left: var(--offset-md);
}

.criteriaName {
  padding-left: var(--offset-sm);
}
