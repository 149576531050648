.container {
  position: absolute;
  top: 30%;
  left: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
  height: 240px;
  background: var(--white);

  padding: var(--offset-lg) var(--offset-x-lg);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  .header {
    width: 90%;

    margin-bottom: var(--offset-lg);
    color: var(--new-blue3);
    text-align: center;
  }

  .cancelButton {
    margin-bottom: var(--offset-md);
    background-color: var(--new-blue3);
  }

  .confirmButton {
    padding: 0px;
  }
}
