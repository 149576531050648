.container {
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  box-sizing: border-box;
  padding: var(--offset-md);

  animation: fadeIn 0.3s linear;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &:before {
    content: '';

    top: 0;
    left: 0;
    position: absolute;

    width: 100%;
    height: 100%;

    background-color: var(--new-blue6);
    opacity: 0.9;
    border-radius: 5px;
  }

  .content {
    position: relative;
    text-align: center;

    border-radius: 2px;
    border: 2px dashed var(--new-blue4);

    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .icon {
      margin-bottom: var(--offset-sm);
    }
  }
}
