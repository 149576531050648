.statusBox {
  margin: 0;
  padding: 0;
  border-radius: 5px;
  width: 145px;
  box-shadow: 0px 0px 5px var(--new-grey3);
  background-color: var(--white);
  top: 1.5rem;
}
.arrow {
  font-size: 20px;
  color: var(--white);
}
.statusPopper {
  pointer-events: all;
}

.statusWrapper {
  padding: var(--offset-xxx-sm) var(--offset-xx-sm);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.statusWrapper:not(:last-of-type) {
  border-bottom: 1px solid var(--new-grey6);
}
.statusWrapper:last-of-type {
  border-radius: 0 0 5px 5px;
}
.statusWrapper:first-of-type {
  border-radius: 5px 5px 0 0;
}

.statusWrapper:hover {
  background-color: var(--new-grey7);
}

.statusText {
  padding: 0 var(--offset-xxx-sm);
  text-transform: capitalize;
}

.dropdownIcon {
  width: 17px;
  height: 17px;
}
