.assignmentsList {
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
}

.assignmentItem {
  border: 1px solid #100b0b;
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.assignmentMain {
  flex-grow: 1;
}

.assignmentTitle {
  margin: 0 0 5px 0;
  color: #000000;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }
}

.assignmentClass {
  color: #6366f1;
  font-size: 10px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

.creatorName {
  padding: 5px;
  color: #5a608d;
}

.assignmentDue {
  color: #ec4899;
  margin: 0;
  font-size: 10px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

.assignmentStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 5px;
}

.statusIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;

  @media (min-width: 768px) {
    width: 28px;
    height: 28px;
  }

  @media (min-width: 1024px) {
    width: 32px;
    height: 32px;
  }
}

.statusText {
  text-transform: uppercase;
  font-size: 10px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

.complete {
  color: seagreen;
}

.missing {
  color: deeppink;
}

.revised {
  color: plum;
}

.submitted {
  color: deepskyblue;
}

.assigned {
  color: goldenrod;
}

.chevronRight {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #6b7280;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 28px;
  }

  @media (min-width: 1024px) {
    font-size: 32px;
  }
}
