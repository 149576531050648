.plannerPage {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  overflow-x: hidden;
}

.calendarWrapper {
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  flex-grow: 0;
  flex-shrink: 0;
}

.assignmentsWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
}

.statusFilter {
  display: flex;
  justify-content: right;
  padding: 5px;
  position: relative;
  border-bottom: 2px solid black;

  select {
    padding: 5px;
    border-radius: 5px;
    border-width: 2px;
  }
}
