.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 600px;
  }

  .content {
    margin-top: var(--offset-lg);
    margin-bottom: var(--offset-lg);

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      border-bottom: 1px solid var(--new-grey5);
      margin-bottom: var(--offset-md);
      padding-left: var(--offset-xx-sm);
      border-radius: 5px;

      &:hover {
        background-color: var(--new-grey5);
        cursor: pointer;
      }

      .arrowDown {
        transform: rotate(180deg);
      }
    }

    .groups {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: row;
      grid-gap: var(--offset-md);
    }
  }

  .title {
    grid-column: 1;
    color: var(--pink-1);

    &:not(:first-child) {
      margin-top: var(--offset-md);
    }

    & + .placeholderBox {
      grid-column: 1;
    }
  }
}
