@import '_scss-variables.scss';

.tabsWrapper {
  height: 66px;

  width: 1010px;
  background: var(--white);

  border: 1px solid var(--new-grey5);
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;

  border-bottom: none;

  display: flex;

  padding: var(--offset-sm) var(--offset-sm) var(--offset-xx-sm)
    var(--offset-sm);

  color: var(--new-grey3);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;

  @media screen and (max-width: 1024px) {
    width: 95vw;
  }

  .tab {
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    padding: 0 var(--offset-xx-sm) var(--offset-xx-sm) var(--offset-xx-sm);

    cursor: pointer;

    & + .tab {
      margin-left: var(--offset-lg);
    }

    &.activeTab {
      color: var(--pink-1);
      border-bottom: 1px solid var(--pink-1);
      box-sizing: border-box;
    }
  }
}
