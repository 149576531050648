.filtersDrawer {
  z-index: 900 !important; /* This overrides an inline style property */
}

.filtersDrawerPaper {
  width: 285px;
  height: calc(100% - 48px);
  border-top-left-radius: 5px;
  border: 1px solid var(--new-grey5);

  .filterTitle {
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--new-grey5);

    margin-bottom: var(--offset-x-sm);
    padding: var(--offset-sm) var(--offset-x-sm);

    .backIcon {
      margin-right: var(--offset-xxx-sm);
    }

    .filterIcon {
      margin-right: var(--offset-xx-sm);
    }
  }

  .menuSection {
    padding: var(--offset-xx-sm) var(--offset-x-sm);

    .filterName {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: var(--grey-3);

      margin-bottom: var(--offset-xxx-sm);
    }

    .transparentInput {
      background-color: var(--white);

      svg {
        color: var(--grey-4);
      }
    }
  }

  .applyButtonContainer {
    border-top: 1px solid var(--new-grey6);

    margin-top: var(--offset-sm);
    padding: var(--offset-sm) var(--offset-x-sm) 0 var(--offset-x-sm);
  }

  .resetButtonContainer {
    padding: var(--offset-xxx-lg) var(--offset-x-sm) var(--offset-lg)
      var(--offset-x-sm);

    margin-top: auto;
  }
}
