.dialog {
  width: 577px;
  padding: var(--offset-x-lg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createButton {
  width: 120px;
}

.label {
  margin-top: var(--offset-lg);
}

.multiSelect {
  margin-top: var(--offset-sm);
}
.selectedElementRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--offset-xx-sm);
}
.selectedElementRow:last-child {
  margin-bottom: 0;
}
.minusButton {
  width: 24px;
  min-width: 24px;
}

.linearProgress {
  margin-top: var(--offset-x-sm);
}
