@import '_scss-variables.scss';

.almostThere {
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: $max-mobile-screen) {
    width: 600px;
  }
}

.image {
  margin-bottom: 120px;
}

.textWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  color: var(--new-blue8);
  text-align: center;
  text-transform: initial;

  margin-bottom: 17px;
}

.text {
  color: var(--new-blue8);
  text-align: center;

  margin-bottom: 111px;
}

.icon {
  position: absolute;
  right: 16px;
  margin-top: -10px;
}
