@import '_scss-variables.scss';

.noResultsPlaceholderContainer {
  display: flex;
  justify-content: center;
}

.gridViewContainer {
  padding: 0 var(--offset-sm) var(--offset-xxx-lg) var(--offset-sm);

  @media screen and (max-width: $max-mobile-screen) {
    padding: unset;
  }
}
