.bold {
  font-weight: 700;
}

.offset {
  margin-bottom: var(--offset-xx-sm);
}

.currentPaymentMethod {
  margin-left: var(--offset-sm);
}

.quickGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: var(--offset-md);
}

.header {
  background-color: var(--grey-7);
  padding: var(--offset-sm) var(--offset-lg);

  margin-bottom: var(--offset-lg);

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.pricePlan {
  margin-top: var(--offset-xxx-sm);
  padding-right: var(--offset-xx-sm);
  background-color: var(--new-grey6);
}
.pricePlan.checked {
  background-color: var(--new-blue6);
}

.priceNotice {
  margin-right: var(--offset-xxx-sm);
}

.lockIcon {
  position: absolute;
  right: 20px;
  top: 25%;
}
