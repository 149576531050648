.dialogContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  .paperRoot {
    max-width: 910px;
    margin: 0;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.dialogContent {
  width: 100%;
  height: 100%;

  max-height: calc(100vh - 2 * var(--dialog-close-button-size));

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 2 * var(--dialog-close-button-size) - 64px);
    overflow: hidden;
  }

  .imageContainer img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .categoryContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;

    padding: 0 80px;

    max-height: 64px;
    min-height: 64px;

    border-top: 1px solid var(--new-grey3);

    text-align: center;
    text-transform: uppercase;

    color: var(--new-blue3);

    .strategyText {
      max-width: 650px;
      word-wrap: break-word;
    }

    .arrow {
      cursor: pointer;
    }
  }
}
