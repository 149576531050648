.calendar {
  display: flex;
  background-color: white;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  border-bottom: 2px solid black;
  width: 100%;
}

.calendarMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.calendarSide {
  display: flex;
  align-items: center;
}

.calendarHeader {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #bbbbbb;
}

.monthSelector {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;

  span {
    margin-right: 5px;
  }
}

.calendarDays {
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  font-size: 5px;
  gap: 5px;
  padding: 1px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.dayLabel {
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: #130303;
  padding-bottom: 5px;
}

.dayCell {
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
  max-width: 100%;
  box-sizing: border-box;

  &.selected {
    background-color: #6366f1;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

.assignmentDot {
  position: absolute;
  background-color: #ec4899;
  border-radius: 100%;
  margin-top: 20px;
  width: 7px;
  height: 7px;
}

.navArrow {
  color: #666;
  cursor: pointer;
}

.currentMonth {
  color: #130303;
}

.otherMonth {
  color: #a0a0a0;
}

.studentName {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: #f30578;
}
