.container {
  width: 100%;
  min-width: 250px;
  padding: var(offset-sm);

  .progressBar {
    display: flex;
    gap: var(offset-xx-sm);
    margin-bottom: var(offset-lg);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: var(offset-sm);
  }
}
