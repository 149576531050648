.paper.paper {
  border-radius: 5px;
  padding: var(--offset-sm);
}

.author {
  display: grid;
  grid-gap: var(--offset-xx-sm);
  grid-template-columns: auto 1fr;
  margin-top: var(--offset-sm);
}

.initials {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 1px solid var(--new-grey5);
  background-color: var(--new-grey5);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.nameAndDateContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.nameAndDateContainer > * {
  margin-right: var(--offset-sm);
  margin-bottom: var(--offset-xxx-sm);
}

.nameAndDateContainer > *:last-child {
  margin-right: 0;
}

.attachment {
  margin-top: var(--offset-xx-sm);
  display: grid;
  grid-gap: var(--offset-xx-sm);
  grid-template-columns: auto 1fr;
  align-items: flex-end;
}
.attachment:hover {
  cursor: pointer;
}

.inputFieldContainer {
  margin-top: var(--offset-x-sm);
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
  align-items: center;
}

.inputField {
  background-color: var(--new-grey6);
  border-radius: 5px;
}
