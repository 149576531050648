.scoreExplanationList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--offset-x-sm);
}

.description {
  color: var(--new-blue1);
  line-height: 13px;
  letter-spacing: normal;
  font-size: 14px;
}
