.container {
  margin: var(--offset-sm);

  .headerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .headerText {
    text-transform: none;
  }
  .detailContainer {
    padding: var(--offset-x-sm);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
  }
  .standardsContainer {
    display: flex;
    align-items: center;
    margin-top: var(--offset-xx-sm);
    gap: 10px;
  }
}
