.selectContainer {
  display: flex;
  flex-direction: row;
  gap: var(--offset-x-sm);
  align-items: center;
}

.buttonRoot {
  border-radius: 20px;
  cursor: pointer;
}

.buttonRootActive {
  border-radius: 20px;
  cursor: pointer;
  background-color: #fff;
}

.buttonLabel {
  text-transform: none;
}

.buttonSpace {
  margin-bottom: var(--offset-x-sm);
}

.contentContainer {
  width: 450px;
}
