.drawer {
  width: var(--side-nav-width-big);

  background: var(--new-blue1);
  color: #fff;
  overflow-x: hidden;

  padding-top: 0;
  padding-bottom: var(--offset-xx-lg);

  @media only print {
    display: none;
  }

  .menuContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .menuActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .logo {
      width: 100%;
      height: var(--top-bar-height);
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        width: 100px;
        height: 32px;
      }
    }

    .addButton {
      border-radius: 50%;

      width: 50px;
      min-width: 50px;
      height: 50px;

      margin-bottom: 60px;
      margin-top: 60px;

      > svg {
        font-size: 30px;
      }
    }
  }

  .menuList {
    width: 100%;
    padding: 0;

    margin-top: var(--offset-lg);

    .listItemLink {
      color: inherit;
      text-decoration: none;
    }

    .listItem {
      padding: var(--offset-x-sm) var(--offset-x-sm) var(--offset-x-sm)
        var(--offset-sm);
      display: flex;
      justify-content: flex-start;
    }

    .selected {
      background-color: var(--new-blue2);
      box-shadow: inset 3px 0 var(--pink-2);
    }

    .listItemText {
      padding-left: var(--offset-xx-sm);
    }
  }

  .arrowIcon {
    width: 30px;
    height: 30px;
  }
}

.small {
  width: var(--side-nav-width-small);

  .logo > img {
    width: 24px;
    height: 32px;
  }

  .menuList .listItem {
    justify-content: center;
    padding: var(--offset-x-sm) 0;
  }
}

.assignmentCreateDialog {
  width: 100%;
  max-width: 820px;
}

.dialogPaper {
  padding: var(--offset-lg) var(--offset-sm);
}
