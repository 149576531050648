.task {
  display: grid;
  align-items: center;
  grid-template-columns: 35% auto;
  margin: var(--offset-sm) 0;
}

.valueType {
  display: flex;
  align-items: center;
  margin-left: var(--offset-lg);
}

.criteria {
  display: grid;
  row-gap: var(--offset-x-sm);
  white-space: pre-wrap;
  word-break: break-word;
}
