.messageContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.replyContainer {
  border-radius: var(--offset-md) var(--offset-md) 0;
  background: linear-gradient(89deg, #f1f2ff, #fff4fb);
  display: flex;
  flex-direction: column;
  width: 271px;
  padding: var(--offset-x-sm);
  justify-content: center;
  align-items: center;
  gap: var(--offset-xx-sm);
  position: relative;
}

.icon {
  position: absolute;
  z-index: var(--offset-xx-sm);
  left: -8px;
  top: -8px;
  width: 24px;
  height: 24px;
}

.replyHelperText {
  font-weight: 600;
  color: #33385c;
  text-align: left;
}

.replyActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #cdcff3;
}

.likeActionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--offset-x-lg);
}

.messageLiked {
  color: var(--new-blue3);
}
.messageDisliked {
  color: var(--darker-hover-pink);
}
.messageNeutral {
  color: var(--new-grey3);
}
.actionsIcon {
  margin-top: var(--offset-xx-sm);
  width: 16px;
  height: 16px;
}
