.legendContainer {
  display: flex;
  flex-direction: column;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.colorBox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  flex-shrink: 0;
}
