.emptyWorkPlaceholder {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emptyWorkPlaceholder > svg {
  width: 100px;
  height: 100px;
}
.emptyWorkPlaceholder > svg > * {
  fill: var(--new-grey5);
}

.leftArrow {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.rightArrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.disableHighlightHover {
  opacity: 0 !important;
}

.exemplarCard {
  background-color: var(--new-yellow6);
}
