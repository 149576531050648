.dialog {
  border-radius: 5px;
}

.dialogHeader {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.link {
  cursor: pointer;
}
