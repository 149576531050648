.container {
  display: flex;
  align-items: center;

  height: 100%;
  max-width: 700px;
  padding: var(--offset-xxx-sm) var(--offset-xx-sm);

  color: var(--new-blue3);

  background-color: var(--new-grey6);
  transition: background-color 0.2s ease;

  border-radius: 5px;

  &:hover {
    background-color: var(--new-grey5);
  }

  .content {
    display: flex;
    align-items: center;

    font-weight: 600;
    text-transform: none;

    .buttonIcon {
      font-size: 24px;
      color: var(--new-blue3);
      margin-right: var(--offset-xx-sm);
    }

    .standardDescription {
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }
}
