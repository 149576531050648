.table tr:last-child td {
  border-bottom: 0;
}

.headerCell {
  background-color: var(--white); /* Counteracts the MUI default color */
}

.headerCell:not(:first-child) {
  max-width: 110px;
  text-align: center;
}
