.createSortCard {
  height: 100%;
  min-height: 250px;
}

.createSortCardContent {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  background-color: var(--new-blue6);
  border: 1px dashed var(--new-grey2);
}
