.pageContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.computerBackground {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tabletBackground {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobileBackground {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Mobile + Tablet */
@media only screen and (max-width: 992px) {
  .computerBackground {
    display: none;
  }
  .tabletBackground {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .tabletBackground {
    display: none;
  }
  .mobileBackground {
    display: block;
  }
}
