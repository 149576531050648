@import '_scss-variables.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(var(--top-bar-height) + 100px);
}

.progress {
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  width: 60px;
}

.navigationTitle {
  width: 100%;
  color: var(--new-blue1);
  font-weight: 600;
  text-align: center;
  padding-left: var(--offset-x-lg);

  @media screen and (min-width: $min-desktop-screen) {
    padding-left: 100px;
  }
}

.journeyTitle {
  margin: 0;
  text-transform: none;
  text-align: center;
  line-height: 58px;
  background-color: #f6f5ff;
  border-bottom: 2px solid #e5e7ff;
  margin-bottom: var(--offset-sm);
}
