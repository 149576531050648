.signUpWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background-image: url('./assets/computer-background.png');
  background-position: center;
  background-size: cover;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.inputField {
  width: 100%;
}

.helperText {
  text-align: left;
  width: 100%;
  margin-top: var(--offset-sm);
}

.error {
  text-align: right;
  width: 100%;
}

.signUpBox {
  text-align: center;
}

.logo {
  width: 200px;
  padding: 30px 0 20px 0;
}

.signUpButton {
  padding: 10px 0px;
  margin-top: var(--offset-xxx-lg);
}

.fieldLabel {
  text-align: left;
  margin-bottom: var(--offset-xx-sm);
  margin-top: 20px;
}

.link {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: var(--pink-3);
  text-decoration-thickness: 2px;
  margin-top: var(--offset-lg);
}

/* Mobile + Tablet */
@media only screen and (max-width: 992px) {
  .signUpBox {
    width: 70%;
  }

  /* Mobile devices */
  @media only screen and (max-width: 600px) {
    .signUpWrapper {
      background-image: url('./assets/mobile-background.png');
      align-items: flex-start;
    }
    .signUpBox {
      align-items: flex-start;
      padding-top: 15%;
      overflow-y: auto;
    }
    .logo {
      padding-top: 0;
    }
  }

  /* Tablet devices */
  @media only screen and (min-width: 600px) {
    .signUpWrapper {
      background-image: url('./assets/tablet-background.png');
    }

    .signUpBox {
      width: 548px;
      min-height: min(75%, 726px);
      background-color: white;

      border: 1px solid var(--grey-1);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

/* Computers */
@media only screen and (min-width: 992px) {
  .signUpBox {
    width: 560px;
    height: 740px;
    max-height: 750px;
    background-color: white;

    border: 1px solid var(--grey-1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}
