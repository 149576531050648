@import '_scss-variables.scss';

.dialog {
  width: 800px;
  padding: var(--offset-lg) var(--offset-x-lg);

  @media screen and (max-width: $max-mobile-screen) {
    padding: var(--offset-x-sm);
  }

  .selectForm {
    margin-bottom: var(--offset-xx-sm);
    width: 100%;
  }

  .middleSelectForm {
    margin-left: var(--offset-xx-sm);
    margin-right: var(--offset-xx-sm);
  }

  .actionButtons {
    min-width: 130px;
  }

  .searchField {
    height: fit-content;
    background-color: white;
    padding-top: 0;
    min-width: 240px;
    width: 100%;

    & > *:first-child {
      min-height: 40px;
      padding: 0 var(--offset-md) var(--offset-xxx-sm) var(--offset-xxx-sm);

      input {
        min-width: 100px;
        min-height: 30px;
      }
    }

    .selectedStandard {
      border-radius: 5px;
      align-items: center;
      box-sizing: border-box;
      margin-right: var(--offset-xx-sm);
      margin-top: var(--offset-xxx-sm);
      background-color: var(--new-grey5);

      .blueIcon {
        color: var(--new-blue3);
      }
    }
  }

  .option {
    color: var(--new-grey2);
  }
}
