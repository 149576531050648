.endIcon {
  margin-right: var(--offset-xx-sm);
}

.option {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.startIcon {
  font-size: 18px;
  width: 18px;
  height: 18px;
  margin-right: var(--offset-xxx-sm);
}
