.content {
  display: flex;
  flex-direction: column;
  gap: var(--offset-sm);
  min-width: 250px;
  flex-grow: 1;
}

.dialogTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 10px 5px;
  gap: 5px;
  border-bottom: 1px solid #d0d0d0;

  .arrowIconContainer {
    width: 30px;
    height: 30px;
  }
}

.arrow {
  cursor: pointer;
  font-size: 30px;
  color: var(--new-grey4);
  &:hover {
    color: var(--new-grey2);
  }
}

.selectBoxes {
  display: flex;
  flex-direction: column;
  gap: var(--offset-sm);
  margin: 0px 10px;
  flex-grow: 1;
}
.drawerSelect {
  width: 100%;
  border-bottom: 2px solid #757575;
}

.spacer {
  flex-grow: 1;
}

.assignmentButton {
  width: 100%;
  margin-bottom: var(--offset-sm);
  background-color: var(--pink-2);
  cursor: pointer;
  &:hover {
    background-color: var(--pink-2);
  }
}
