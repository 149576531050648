.container {
  padding: 0 var(--offset-x-sm);

  .author {
    display: grid;
    grid-gap: var(--offset-xx-sm);
    grid-template-columns: auto 1fr;
    margin-top: var(--offset-sm);
  }

  .initials {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: 1px solid var(--new-grey5);
    background-color: var(--new-grey5);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nameAndDateContainer {
    padding-right: var(--offset-x-sm);

    .nameAndDate {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        margin-bottom: var(--offset-xxx-sm);

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .inputFieldContainer {
    margin-top: var(--offset-x-sm);
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 8px;
    align-items: center;

    .inputField {
      background-color: var(--new-grey6);
      border-radius: 5px;
      height: 30px;
    }

    .addCommentIcon {
      width: 40px;
      height: 40px;
    }
  }
}
