.loginWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background-image: url('./assets/computer-background.png');
  background-position: center;
  background-size: cover;
}

.loginBox {
  text-align: center;
}

.logo {
  width: 200px;
  padding: 60px 0 60px 0;
}

.field input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.field:hover input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--light-grey) inset !important;
}
.field.hasValue input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--light-grey) inset !important;
}
.field input:-webkit-autofill:hover,
.field input:-webkit-autofill:focus,
.field input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--light-grey) inset !important;
}

.field input + * .icon {
  opacity: 0.5;
}

input:focus + * .icon {
  opacity: 1;
}

.usePasswordButton {
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* Mobile + Tablet */
@media only screen and (max-width: 992px) {
  .loginBox {
    width: 70%;
  }

  /* Mobile devices */
  @media only screen and (max-width: 600px) {
    .loginWrapper {
      background-image: url('./assets/mobile-background.png');
      align-items: flex-start;
    }
    .loginBox {
      align-items: flex-start;
      padding-top: 15%;
      overflow-y: auto;
    }
    .logo {
      padding-top: 0;
    }
  }

  /* Tablet devices */
  @media only screen and (min-width: 600px) {
    .loginWrapper {
      background-image: url('./assets/tablet-background.png');
    }

    .loginBox {
      width: 548px;
      min-height: min(75%, 726px);
      background-color: white;

      border: 1px solid var(--grey-1);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

/* Computers */
@media only screen and (min-width: 992px) {
  .loginBox {
    width: 560px;
    height: 630px;
    max-height: 750px;
    background-color: white;

    border: 1px solid var(--grey-1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.googleButton {
  background: #4285f4;
  color: #fff;
  border: #4285f4 1px solid;
  border-radius: 0;
  height: 40px;
  width: 185px;
  padding: 0;
}
.googleButton:hover {
  background: #4285f4;
}
.googleButtonLabel {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  text-transform: none;
}
.googleButtonIcon {
  width: 38px;
  height: 38px;
  margin-left: 0px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cleverButton {
  background: var(--white);
  color: #4274f6;
  border: 1px solid #7b9ef9;
  border-radius: 3px 3px 0px 0px;
  height: 40px;
  width: 185px;
  padding: 0;
}
.cleverButton:hover {
  background: var(--white);
}
.cleverButtonLabel {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  text-transform: none;
}
.cleverButtonIcon {
  width: 35px;
  height: 30px;
  margin-left: 5px;
  margin-top: 1px;
  border-right: 1px solid #bdcefc;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fieldLabel {
  text-align: left;
  margin-bottom: var(--offset-x-sm);
}
.link {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: var(--pink-3);
  text-decoration-thickness: 2px;
}
.mainError {
  white-space: pre-wrap;
}
