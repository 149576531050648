.pagination {
  display: flex;
  justify-content: flex-end;
}
.pagination > * {
  height: 20px;
  width: 20px;
  min-width: 20px;
  padding: 0;
}
