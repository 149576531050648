.dialog {
  width: 380px;
  padding: var(--offset-lg) var(--offset-x-lg);
}

.actionButtons {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  padding-top: var(--offset-lg);
}
