.learningTrendsContainer {
  display: flex;
  width: 500px;
  text-transform: uppercase;
  padding: var(--offset-sm);
}

.learningTrendsContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.arrowIcon {
  cursor: pointer;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contentContainer {
  display: flex;
}

.listItem {
  margin-top: 15px;
}

.inlineLink {
  text-decoration: underline;
  cursor: pointer;
}

.inlineLink:hover {
  text-decoration: none;
}

.title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--new-blue2);
}

.loader {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
