.stepperIcon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-4);
}

.activeStepperIcon {
  background-color: var(--pink-1);
}

.completedStepperIcon {
  background-color: var(--pink-4);
}
