.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Nunito', sans-serif;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.backButton {
  background-color: #b2c1f4;
  color: #020f29;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
  font-family: 'Nunito', sans-serif;
}

.nextButton {
  background-color: #db2777;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
  font-family: 'Nunito', sans-serif;
}

.navTabs {
  display: flex;
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  padding-top: 25px;

  .tab {
    flex: 1;
    font-size: 11px;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 0.5px;
    position: relative;
    text-align: center;
    margin-left: 10px;
    color: #e11d48;

    &::before {
      content: '';
      position: absolute;
      top: -14px;
      left: 0;
      width: calc(100% - 1px);
      height: 4px;
    }

    &:first-child::before {
      background-color: #e11d48;
    }

    &:nth-child(2)::before {
      background-color: #e11d48;
    }

    &:last-child::before {
      background-color: #e11d48;
      opacity: 0.3;
      width: 100%;
    }

    &.active {
      color: #e11d48;
    }

    &.tabProgress {
      color: #9ca3af;
      cursor: default;
    }
  }
}

.formGroup {
  margin-bottom: 16px;
  position: relative;

  input,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    transition: all 0.2s;
    line-height: 1.5;
    letter-spacing: normal;

    &.error {
      border-color: #ef4444;
      background-color: #fef2f2;
    }

    &:focus {
      outline: none;
      border-color: #e11d48;
    }

    &::placeholder {
      color: #818791;
      font-size: 14px;
      letter-spacing: normal;
      text-transform: none;
    }
  }

  textarea {
    resize: vertical;
    min-height: 80px;
  }

  &.hasError {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }

  .errorText {
    color: #ef4444;
    font-size: 12px;
    margin-top: 4px;
    font-weight: 500;
  }
}

.dropdownContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin: 16px 0;
}

.dropdown {
  position: relative;
  width: 100%;

  select {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    appearance: none;
    background-color: white;
    color: #6b7280;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;

    &.error {
      border-color: #ef4444;
      background-color: #fef2f2;
    }

    &:focus {
      outline: none;
      border-color: #e11d48;
    }
  }

  &:has(select)::after {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  &.hasError {
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
}

.customInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;

  .customInput {
    width: 60px;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    appearance: none;
    background-color: white;
    color: #6b7280;
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    transition: all 0.2s;

    &.error {
      border-color: #ef4444;
      background-color: #fef2f2;
    }

    &:focus {
      outline: none;
      border-color: #e11d48;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .pagesText {
    color: #6b7280;
    font-size: 14px;
  }
}

.standardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.standardTag {
  display: inline-flex;
  align-items: center;
  background-color: #eef2ff;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 12px;
  color: #4f46e5;
  font-weight: 500;
  gap: 6px;

  .standardCode {
    font-weight: 600;
  }

  .closeButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #4f46e5;

    &:hover {
      color: #e11d48;
    }
  }
}

.addStandardBtn {
  display: inline-flex;
  align-items: center;
  background-color: #eef2ff;
  border: none;
  border-radius: 4px;
  padding: 6px 10px;
  color: #4f46e5;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  gap: 6px;

  &:hover {
    background-color: #e0e7ff;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.advancedSettings {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-top: 24px;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    background-color: #f9fafb;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #374151;
  }

  .content {
    padding: 16px;
    border-top: 1px solid #e5e7eb;

    .tabs {
      display: flex;
      border-bottom: 1px solid #e5e7eb;
      margin-bottom: 16px;

      button {
        padding: 8px 16px;
        background: none;
        border: none;
        color: #6b7280;
        font-size: 14px;
        cursor: pointer;
        position: relative;

        &.active {
          color: #e11d48;
          font-weight: 500;

          &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #e11d48;
          }
        }
      }
    }

    .description {
      text-align: center;
      color: #6b7280;
      font-size: 14px;
      margin-bottom: 16px;
    }

    .uploadArea {
      border: 2px dashed #e5e7eb;
      border-radius: 8px;
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        border-color: #d1d5db;
        background-color: #f9fafb;
      }

      .uploadButton {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #4f46e5;
        font-weight: 600;
        font-size: 13px;
      }
    }
  }
}

.attachmentList {
  margin-top: 16px;

  .attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: #f3f4f6;
    border-radius: 6px;
    margin-bottom: 8px;

    span {
      font-size: 14px;
      color: #374151;
    }

    button {
      background: none;
      border: none;
      padding: 4px;
      color: #6b7280;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        color: #e11d48;
      }
    }
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.assignmentCreateDialog {
  width: 100%;
  max-width: 820px;
}
