.container {
  width: 1010px;
  background: var(--white);

  border: 1px solid var(--new-grey5);
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
}

/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .container {
    /* Take 95% of the visual width */
    width: 95vw;
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--offset-xxx-sm);
  padding: var(--offset-xx-sm) var(--offset-sm);
  min-height: 45px;
}

.skeletonRow > * {
  margin-right: var(--offset-sm);
}

.headerRow {
  border-bottom: 1px solid var(--new-grey5);
  align-items: center;
}

.contentRow {
  align-items: center;
  border-bottom: 1px solid var(--new-grey6);
}
.contentRow:hover {
  cursor: pointer;
  background: var(--new-grey6);
}

.paginationRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noMeetings {
  padding: var(--offset-x-sm);
  width: 100%;
  text-align: center;
}
