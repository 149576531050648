@import '_scss-variables.scss';

.container {
  padding-bottom: var(--offset-lg);

  .header {
    margin-bottom: var(--offset-md);
  }
}

.boxesContainer {
  display: grid;
  grid-gap: var(--offset-sm);
  grid-template-columns: minmax(278px, 1fr) minmax(225px, 1fr) minmax(
      225px,
      1fr
    );

  @media screen and (max-width: $max-tablet-screen) {
    grid-auto-rows: 240px;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  }

  .box {
    text-align: center;
    border-radius: 5px;
    border: 1px solid var(--new-grey5);
    transition: background-color 0.3s linear;

    display: flex;
    align-items: center;
    flex-direction: column;

    padding: var(--offset-sm) var(--offset-x-sm) var(--offset-x-sm)
      var(--offset-x-sm);

    &.hoverable:hover {
      cursor: pointer;
      background-color: var(--new-blue6);
    }

    .boxTitle {
      width: 100%;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: var(--offset-md);

      &.reducedBottomMargin {
        margin-bottom: var(--offset-xx-sm);
      }

      .boxTitleIcon {
        right: 0;
        position: absolute;
      }
    }

    .button {
      width: 100%;

      &.transparent {
        color: var(--black);
        background-color: transparent;
        border: 1px solid var(--new-grey5);
        white-space: nowrap;
        line-height: 10px;
      }

      & + .button {
        margin-top: var(--offset-sm);
      }

      .googleClassroomButtonContent {
        display: flex;
        gap: var(--offset-xx-sm);
        align-items: center;
      }
    }

    .scanImage {
      height: 133px;
    }

    .boxDescription {
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;

      margin-top: auto;
    }
  }
}
