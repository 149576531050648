.teamCard {
  display: flex;
  flex-direction: column;
}

.teamCard:hover .editIcon {
  opacity: 1;
}

.teamCardLink {
  text-decoration: none;
}

.header {
  padding: var(--offset-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  padding: var(--offset-sm);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.usersNames {
  margin-top: var(--offset-xxx-sm);
}

.countWrapper {
  margin-top: var(--offset-x-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  text-decoration: none;
}

.editIcon {
  opacity: 0.2;
  transition: opacity 0.2s;
}
