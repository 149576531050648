.container {
  padding: var(--offset-sm) var(--offset-sm) var(--offset-xx-lg)
    var(--offset-sm);
  max-width: 1050px;
}
.feedContainer > * {
  margin-bottom: var(--offset-x-sm);
}
.feedContainer > *:last-child {
  margin-bottom: 0;
}

.createMeetingButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--offset-x-lg);
}
.createMeetingButton > button + button {
  width: 228px;
}

.shareInfoTitle {
  margin-bottom: var(--offset-xxx-sm);
}

.tasksContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--offset-xx-sm);
  margin-top: var(--offset-xx-sm);
  align-items: flex-start;
}

.loadingPlaceholder {
  margin-top: var(--offset-x-sm);
}

.meetingSelectPaper {
  max-width: 380px;
  text-overflow: ellipsis;
}

.meetingSelectFormControl {
  max-width: 400px;
  text-overflow: ellipsis;
}

.link {
  text-decoration: none;
  color: inherit;
}
