@import '_scss-variables.scss';

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin slide-in-from-right($duration: 1s, $timing-function: ease) {
  animation: slideInFromRight $duration $timing-function;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  padding-top: var(--offset-lg);
  min-height: calc(100vh - var(--top-bar-height));
  background-color: #e5e7ffa3;

  @media screen and (min-width: $max-mobile-screen) {
    background-color: white;
    margin-top: 0;
  }
  @include slide-in-from-right(0.3s, ease-in-out);
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 230px;
}

.bar {
  margin-bottom: var(--offset-md);
}

.title {
  color: #585981;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: var(--offset-x-sm);
}

.subtitle {
  color: var(--Pathways-P-Purple-1, #585981);
  text-align: center;
  line-height: 19px;
  margin-bottom: var(--offset-xxx-lg);
}

.progress {
  color: #b0b3f4;
}

.progressContentBox {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressContent {
  color: #585981;
  font-size: 62px;
  font-style: normal;
  line-height: normal;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 90px;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 19px;
  gap: 8px;
}

.scrollableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  overflow-y: auto;

  height: calc(
    100vh - var(--top-bar-height) - 48px - 24px - 24px - 6px
  ); // 48px is the height of the bottom button, 24px is the padding around the button
}

.text {
  color: var(--dark-blue);
}

.startButton {
  background-color: var(--pink-2);
  height: 48px;
  width: 343px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 90px;
}

.bottomButton {
  position: fixed;
  bottom: var(--offset-md);
}
.startButton .icon {
  position: absolute;
  right: var(--offset-x-sm);
  margin-top: -10px;
}

.noUnderline {
  text-decoration: none;
  color: var(--white);
}

.progress {
  width: 100%;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;

  padding: 0 var(--offset-lg) var(--offset-md) var(--offset-x-lg);

  @media screen and (min-width: $max-mobile-screen) {
    width: 600px;
  }
}

.percentage {
  color: var(--new-grey3);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
