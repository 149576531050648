.wrapper {
  margin: 0 var(--offset-sm) var(--offset-x-sm) var(--offset-sm);
}
.smallwrapper {
  margin: 0 var(--offset-sm) var(--offset-lg) var(--offset-sm);
}

.cardTitle {
  color: var(--darker-blue);
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardContent {
  padding: 0;
  padding-bottom: 0 !important;
}
.workInfo {
  padding: var(--offset-x-sm) var(--offset-x-sm) 0 var(--offset-x-sm);
}
.objectivesInfo {
  padding: 0 var(--offset-x-sm) var(--offset-sm) var(--offset-x-sm);
}

.objectiveHeader {
  padding-top: var(--offset-md);
  color: var(--darker-grey);
}

.objectiveChild {
  padding-top: var(--offset-xx-sm);
  color: var(--darker-grey);
}

.statusWrapper {
  padding: var(--offset-xx-sm) 0;
}
.statusIcons {
  display: flex;
  align-items: center;
}

.workSelect {
  height: 22px;
}

.objectiveValue {
  text-align: center;
}

.workPagination > * {
  margin-left: 6px;
}

.allTasksWrapper {
  margin: 0;
  width: 100%;
}

.captureWorkButton {
  width: 235px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  .cameraIcon {
    margin-right: 4px;
  }
}

.studentCardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 330px);
  grid-gap: 10px;

  align-items: flex-start;
}
/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .studentCardsContainer {
    grid-template-columns: repeat(auto-fit, 320px);
  }
}

.exemplarCard {
  background-color: var(--new-yellow6);
}
