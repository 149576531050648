.header {
  display: flex;
  align-items: center;
  min-width: 0;
}

.header:hover .icon.edit {
  opacity: 1;
}

.icon {
  transition: opacity 0.2s;
  margin-left: var(--offset-xx-sm);
}

.icon.save {
  opacity: 1;
}
.icon.edit {
  opacity: 0;
}

.input {
  height: 30px;
  width: 400px;
}
