@import '_scss-variables.scss';

.pageWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pageWrapper .container {
  max-width: 768px;
  width: 100%;
  margin: auto;
}

.pageWrapper .gridContainer {
  height: 48px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: $max-mobile-screen) {
    grid-template-columns: 48px 1fr 48px;
  }
}

.pageWrapper .title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  text-align: center;
}

.pageWrapper .topNavigation {
  height: 48px;
  background-color: var(--dark-blue);
  color: var(--white);
}

.pageWrapper .content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--white);
  border: 1px var(--grey-1);

  &.greyOnDesktop {
    background-color: var(--new-grey7);

    @media screen and (max-width: $max-mobile-screen) {
      background-color: var(--white);
    }
  }
}

.group {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .viewSwitcher {
    margin-right: 90px;

    @media screen and (max-width: $max-tablet-screen) {
      margin-right: 40px;
    }

    @media screen and (max-width: $max-mobile-screen) {
      margin-right: unset;
    }
  }
}

.pageWrapper .bottomNavigation {
  height: 48px;
  background-color: var(--light-grey);
  position: relative;
  z-index: 1000;
}

.pageWrapper .bottomNavigation:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 2px solid var(--grey-1);
}

.cameraWrapper {
  position: absolute;
  z-index: 10;
  top: -33px;
  left: 50%;
  margin-left: -33px;
  width: 66px;
  height: 66px;
  background-color: var(--white);
  border: 2px solid var(--grey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cameraWrapper > button {
  width: 100%;
  height: 100%;
}

.cameraWrapper svg {
  fill: var(--dark-blue);
}

.filterIcon {
  width: 48px;
}

.invisible {
  visibility: hidden;
}
