.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
}

.container > * {
  margin-bottom: 1rem;
}

.subContainer {
  width: 100%;
  max-width: 1024px;

  border: 1px solid var(--darker-pink);

  border-radius: 5px;

  padding: 1rem;
}
