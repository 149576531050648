.taskNavigation {
  width: 100%;
  height: 170px;
  border-top: 2px solid var(--grey-2);
  background: var(--dark-blue);

  box-shadow: inset 15px 0px 20px -10px rgba(0, 0, 0, 0.7),
    inset -15px 0px 20px -10px rgba(0, 0, 0, 0.7);

  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  /* Tablets and desktop */
  .taskNavigation {
    width: 768px;
  }
}

.taskInfoWrapper {
  padding: 8px 0 8px 0;
  width: 100%;

  display: grid;
  grid-template-columns: 10% auto 10%;
  align-items: center;
}
.taskInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imgWrapper > .status > svg {
  width: 17.5px;
  height: 17.5px;
  margin-bottom: 5px;
}
.imgWrapper.noImage > .status > svg {
  width: 50px;
  height: 50px;
}

.imgPreview {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  object-fit: contain;
}

.info {
  padding-left: 14px;
}

.taskName {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.criteriaName {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: var(--lighter-grey);
  margin-left: 10px;
}
.checkmark {
  font-size: 1rem !important;
  margin: 0 12px 0 0 !important;
  color: var(--green);
}
.checkmark.disabled {
  color: var(--grey-1);
}

.buttonsWrapper {
  /* -(button height / 2) => aligns the buttons in the middle of the navigation border */
  margin-top: -51px;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.buttonsWrapper .uploadButton {
  background-color: var(--white);
  border-radius: 50%;
  border: 2px solid var(--grey-2);
}

.buttonsWrapper .rightSidePlaceholder {
  width: 55px;
}

.webcam {
  width: 100%;
}
.webcamView {
  position: relative;
}
.webcamViewSetDims {
  width: 100%;
  height: 100%;
}
.previewImage {
  /* Set height and width so that the dimension are equal to the viewport 
  768 = max width of the viewport and 
  170 = sum of the bottom and top nav bars */
  width: min(768px, 100%);
  height: calc(100% - (170px));
  /* Contain the preview image within the dimensions that are set above*/
  object-fit: contain;
}
/* This is the pink box overlaid ontop of the the webcam display */
.webcamBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 70%;
  height: 90%;
  outline: 5px solid var(--pink);
}

.confirmDeleteButtons {
  display: flex;
}

.cameraBtn {
  width: 99px;
  height: 99px;
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  border-radius: 50%;
  border: 2px solid var(--grey-2);
}
.cameraBtn > i {
  margin: 0;
  color: #fff;
}

.cameraBtn.screenshot {
  background: #fff;
}
.cameraBtn.screenshot:after {
  content: '';
  position: absolute;
  top: 7px;
  left: 7px;
  right: 7px;
  bottom: 7px;

  border-radius: 50%;
  border: 2px solid var(--grey-2);
}
.cameraBtn.delete {
  background: #c21d41; /* todo */
  margin-right: 5rem;
}
.cameraBtn.confirm {
  background: var(--green);
}

.errorContainer {
  background: var(--dark-blue);
  display: flex;
  justify-content: center;
  height: 100vh;

  padding: 80px 50px 0 50px;
}
.errorContainer > div {
  height: 350px;
}
