.progressBarWrapperTablet {
  position: relative;
}
.progressBar {
  margin-left: var(--offset-xxx-sm);
}
.progressBarTablet {
  /* Make sure the bar appears behind the progress text */
  z-index: 100;
}
.progressTextTablet {
  position: absolute;
  padding-left: var(--offset-xxx-sm);

  /* Make sure the text appears over the progress bar */
  z-index: 200;
}
