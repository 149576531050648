@import '_scss-variables.scss';

.returnButton {
  border-radius: 50%;
  border: 2px solid #ff4b94;
  display: flex;
  color: #ff4b94;
  margin: 2px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    border-color: #6366f1;
  }
  @media (min-width: 768px) {
    width: 28px;
    height: 28px;
  }

  @media (min-width: 1024px) {
    width: 32px;
    height: 32px;
  }
}

.standardsList {
  margin-top: var(--offset-md);

  @media screen and (max-width: $max-tablet-screen) {
    margin-top: var(--offset-sm);
  }
}

.statusIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;

  @media (min-width: 768px) {
    width: 28px;
    height: 28px;
  }

  @media (min-width: 1024px) {
    width: 32px;
    height: 32px;
  }
}

.assignmentHeader {
  border-bottom: 3px solid black;
}

.assignmentDescription {
  border-bottom: 3px solid black;
}

.assignmentStandards {
  border-bottom: 3px solid black;
}

.horizontal-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 10px;
}

.box {
  padding: 10px 20px;
  text-align: center;
  margin-right: 10px;
}

.right-align {
  margin-right: 0;
  margin-left: auto;
  right: 10px;
  top: 50%;
}

.navArrow {
  color: #666;
  cursor: pointer;
  margin-right: 0;
  margin-left: auto;
}

.expandable-bar {
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  .expand-btn {
    color: white;
    padding: 10px;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 16px;
    cursor: pointer;
    background-color: transparent;
    display: block;
    margin: 10px 0;
  }

  .assignmentResolution {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    padding: 0 10px;

    &.expanded {
      max-height: 150px;
    }
  }
}

.teacher-feedback {
  text-align: center;
}

.complete {
  color: seagreen;
}

.missing {
  color: deeppink;
}

.revised {
  color: plum;
}

.submitted {
  color: deepskyblue;
}

.assigned {
  color: goldenrod;
}

.statusText {
  text-transform: uppercase;
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}

.horizontal {
  margin-right: 10px;
}

.studentWorkContainer {
  display: 'flex';
  flex-direction: 'column';
}

.studentWorkImage {
  width: 100%;
}

.accordion {
  box-shadow: none;
}

.feedbackInfo {
  margin-bottom: 3px;
}
