@import '_scss-variables.scss';

.container {
  display: flex;
  align-items: flex-start;
  padding: var(--offset-x-sm) var(--offset-xx-sm);

  overflow-y: hidden;
  height: 100%;

  max-height: calc(100vh - 60px);

  @media screen and (max-width: $max-tablet-screen) {
    max-height: calc(100vh - 120px);
  }
}
