@import './config.scss';

.root {
  display: flex;
}

.content {
  flex-grow: 1;

  overflow-x: auto;
  overflow-y: auto;

  margin: var(--top-bar-height) 0 0 var(--side-nav-width-small);

  @media only print {
    margin-left: 0;
  }
}

.small {
  margin: var(--top-bar-height) 0 0 var(--side-nav-width-big);
}

.fullWidth {
  margin: var(--top-bar-height) 0 0 0;
}

.noTopNavigation .content {
  margin-top: 0;
  min-height: 100vh;
}
