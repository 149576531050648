.container {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.audioPlayer {
  margin: 0;
}

.playerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--offset-xxx-sm);
}

.deleteButtonRoot {
  cursor: pointer;
  color: var(--pink-3);

  &:hover {
    color: var(--pink-4);
  }
}
