.inputContainer {
  width: 480px;
  margin: var(--offset-lg) auto 0 auto;
  text-align: left;

  @media only screen and (max-width: 670px) {
    width: 100%;
  }

  .inputLabel {
    margin-bottom: var(--offset-xx-sm);
  }

  .textField {
    .icon {
      opacity: 0.5;
    }

    input:focus + * .icon {
      opacity: 1;
    }
  }
}

.submitButton {
  margin-top: var(--offset-x-lg);
}

.loaderContainer {
  display: flex;
  justify-content: center;

  margin-top: var(--offset-x-lg);
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid palevioletred;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
