.incompleteImg {
  width: 75px;
  margin: var(--offset-md) auto;
}

.dialogPaper {
  display: inline-grid;
  width: 275px;
  padding: var(--offset-lg) var(--offset-x-lg);
}

.finishButton {
  margin: var(--offset-md) 0px var(--offset-sm) 0px;
}
