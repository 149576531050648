.container {
  display: flex;
  height: 100%;
}

.feedbackPaper {
  flex: 1;
  margin: var(--offset-x-sm);
  overflow: auto;
  height: fit-content;
}

/* Mobile + Tablet */
@media only screen and (max-width: 992px) {
  .feedbackPaper.feedbackPaper {
    margin: 0;
    border-radius: 0;
  }

  .container {
    min-height: 100vh;
  }
}

.taskSwitch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--offset-xx-sm);
}

.taskSwitch > * + * {
  margin-left: var(--offset-sm);
}

.feedbackImage {
  position: relative;
  display: flex;
  justify-content: center;
}
.feedbackImage img {
  width: 100%;
}

.workGrid {
  padding: var(--offset-lg) var(--offset-md) var(--offset-xx-sm)
    var(--offset-md);
}
