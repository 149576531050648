@import '_scss-variables.scss';

.segmentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: var(--offset-xx-sm);
  min-width: 12%;
}

.hoverableSegment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 11px;
}

.progressSegment {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  height: 11px;
  width: 100%;

  transition: transform 0.3s ease;
}

.progressSegment.celebrate {
  background-color: #f05b94;
}

.progressSegment.support {
  background-color: #fd81ba;
}

.progressSegment.intervene {
  background-color: #ffb0d6;
}

.progressSegment.unsubmitted {
  background-color: var(--new-grey4);
}

.hoverPopup {
  display: none;
  position: absolute;

  padding: 8px;
  color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  width: auto;
  max-width: 250px;

  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;

  z-index: 10;
  cursor: pointer;
}

.hoverableSegment:hover .hoverPopup {
  display: block;
}

.hoverableSegment:hover .progressSegment {
  transform: scaleY(1.4);
  cursor: pointer;
}

.labelTextWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $max-tablet-screen) {
    min-height: 50px;
  }
}

.labelText {
  @media (max-width: $min-tablet-screen) {
    font-size: 13px;
  }

  @media (max-width: $min-mobile-screen) {
    font-size: 9px;
  }
}
