.mainContainer {
  display: flex;
  flex-direction: column;
  gap: var(--offset-lg);
  padding: var(--offset-sm);
  max-width: 400px;
  margin: auto;
  margin-bottom: 150px;
  text-align: center;
  position: relative;
}

.pageTitle {
  width: 100%;
  color: var(--darker-blue);
  text-align: center;
  text-transform: none;
  margin-left: var(--offset-x-sm);
}

.backButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  user-select: none;
  & > svg {
    fill: var(--darker-blue);
  }
}
