@import '_scss-variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;

    & + .step {
      margin-left: var(--offset-xxx-sm);
    }

    .prevStepConnector {
      width: 250px;
      height: 4px;
      background-color: #ffb0d6;

      margin-bottom: var(--offset-xxx-sm);

      @media screen and (max-width: 1024px) {
        width: 150px;
      }

      @media screen and (max-width: $max-tablet-screen) {
        width: 110px;
      }
    }

    .name {
      color: var(--new-grey3);
      text-transform: uppercase;

      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }

      @media screen and (max-width: $max-tablet-screen) {
        font-size: 10px;
      }
    }

    &.completed {
      .prevStepConnector {
        background-color: var(--pink-1);
      }

      .name {
        color: unset;
      }
    }

    &.active {
      .prevStepConnector,
      .iconContainer {
        background-color: var(--pink-1);
      }
      .name {
        color: var(--pink-1);
      }
    }
  }
}
