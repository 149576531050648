@import '_scss-variables.scss';

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin slide-in-from-right($duration: 1s, $timing-function: ease) {
  animation: slideInFromRight $duration $timing-function;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  background-color: #fff;
  padding-top: var(--offset-sm);
  height: calc(100vh - var(--top-bar-height));
  @include slide-in-from-right(0.3s, ease-in-out);
}

.scrollableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  overflow-y: auto;

  height: calc(
    100vh - var(--top-bar-height) - 48px - 24px - 24px
  ); // 48px is the height of the bottom button, 24px is the padding around the button
}

.resultsAreReady {
  color: var(--pink-1);
  text-transform: none;

  text-align: center;
}

.startButton {
  background-color: var(--new-blue3);
  height: 48px;
  width: 343px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.startButton .icon {
  position: absolute;
  right: var(--offset-x-sm);
  margin-top: -10px;
}

.bottomButton {
  position: fixed;
  bottom: var(--offset-sm);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.linkButton {
  text-decoration: none;
  color: var(--white);
}
