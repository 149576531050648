.dialogContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialogTitle {
  margin: 17px 0 var(--offset-xxx-sm);
  text-align: center;
}

.dialogSubtitle {
  text-align: center;
  margin-bottom: var(--offset-sm);
}

.dialogContent {
  text-align: center;
  margin-bottom: var(--offset-xx-sm);
  padding: 0 var(--offset-md);
}

.dialogButton {
  margin: var(--offset-xx-sm) auto 17px auto;
  height: 40px;
  width: 247px;
  color: var(--white);
  display: block;
  text-align: center;
}
