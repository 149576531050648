.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 40px 30px;
  background: #fff;

  border: 1px solid var(--grey-1);
  box-sizing: border-box;
  border-radius: 10px;
}

.cameraImg {
  width: 84px;
  height: 84px;
  margin: 20px 0;
}

.header {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  margin: 0;
}
.header.pink {
  color: var(--pink);
}
.header.blue {
  color: var(--blue);
  letter-spacing: 1px;
}

.info {
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: var(--dark-blue);

  margin: 16px 0;
}
