.letsGetStartedText {
  margin-top: var(--offset-x-lg);
}

.plansContainer {
  margin-top: var(--offset-x-lg);
}

.planPaper {
  width: 300px;
  height: 400px;
  position: relative;
  padding: var(--offset-x-lg) var(--offset-lg);

  border: 1px solid var(--new-grey5);
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.planPriceContainer {
  margin-top: var(--offset-x-sm);
  height: 65px;
}

.freePlanPriceText {
  font-size: 48px;
  line-height: 65px;
}

.planFeature > * {
  margin-top: var(--offset-x-sm);
}
.planFeature > * > .planFeatureName {
  width: 190px;
  margin-left: var(--offset-xxx-sm);
}
.centralRosterManagementFeature {
  width: 212px !important;
}

.choosePlanButton {
  height: 50px;
  width: 235px;
  position: absolute;
  bottom: 30px;
}

.bold {
  font-weight: 700;
}

.icon {
  display: flex;
}
