.container {
  width: 100%;
  max-width: 670px;

  border-radius: 5px;
  border: 1px solid var(--new-grey5);
  background-color: #fbfbfb;
  flex-direction: column;

  display: flex;
  align-items: center;

  white-space: nowrap;

  padding: var(--offset-xx-sm) var(--offset-sm) var(--offset-xx-sm);
  margin-bottom: var(--offset-xx-sm);

  .infoAndDeleteContainer {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;

    .fileInfoContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .uploadIcon {
        min-width: 32px;
        height: 32px;
        margin-right: var(--offset-xx-sm);
      }

      .fileData {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }

      .name {
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--new-blue3);
      }

      .fileDataText {
        color: #33385c;
        padding-right: var(--offset-xx-sm);
      }
    }
  }

  .progressBar {
    padding-top: 5px;
    width: 100%;
  }
}
