.statusTooltip {
  padding: 0px;
  background-color: var(--white);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  margin-top: 0;
  pointer-events: all;
}

.statusText {
  text-transform: capitalize;
  padding-left: var(--offset-xxx-sm);
}

.statusWrapper {
  padding: var(--offset-xxx-sm) var(--offset-xx-sm);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.statusWrapper:not(:last-of-type) {
  border-bottom: 1px solid var(--new-grey6);
}

.statusWrapper:hover {
  background-color: var(--new-grey6);
}

.dropdownIcon {
  width: 17px;
  height: 17px;
}

.tooltipArrow::before {
  background-color: var(--white);
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
}

.iconButton {
  padding: 0;
}

.iconLabel {
  display: block;
}
