@import '_scss-variables.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-top: var(--offset-xxx-lg);
  padding: 0;

  @media screen and (max-width: $max-tablet-screen) {
    padding: 0px;
    margin-top: var(--offset-lg);
  }

  .header {
    display: grid;
    align-items: center;
    grid-template-columns: 313px 1fr 313px;

    width: 100%;
    max-width: 1140px;

    .heading {
      text-align: center;
      grid-column-start: 2;
    }

    .actions {
      display: flex;
      align-items: center;

      & > * + * {
        margin-left: var(--offset-sm);
      }

      .helpButton,
      .rotateButton {
        height: 40px;
        width: 40px;
      }
    }
  }

  .imageContainer {
    width: 100%;
    height: 100%;
    max-width: 1140px;

    min-height: 870px;

    margin-top: var(--offset-x-sm);
    padding: var(--offset-x-sm) 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--new-grey7);

    @media screen and (max-width: $max-tablet-screen) {
      min-height: unset;

      margin-top: 0;
      padding: var(--offset-x-sm);
    }

    .regionSelect {
      max-width: 100%;
      user-select: none;

      .image {
        height: 100%;

        @media screen and (max-width: $max-tablet-screen) {
          width: 100%;
        }
      }
    }

    .gifContainer {
      width: 100%;

      display: flex;
      align-items: center;
      flex-direction: column;
      align-self: flex-start;

      @media screen and (max-width: $max-tablet-screen) {
        align-self: flex-start;
      }

      .gifHeader {
        text-align: center;
        margin-top: var(--offset-xxx-sm);
      }
    }

    .gif {
      width: 100%;
      padding: var(--offset-sm) var(--offset-x-lg) 0 var(--offset-x-lg);

      @media screen and (max-width: $max-tablet-screen) {
        padding: var(--offset-sm) 0 0 0;
      }
    }
  }
}
