.formControlContainer {
  min-width: 150px;
}

.selectStyle {
  margin-bottom: var(--offset-xx-sm);
}

.chips {
  display: flex;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.chip {
  max-width: 280px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}
