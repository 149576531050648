@import '_scss-variables.scss';

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin slide-in-from-right($duration: 1s, $timing-function: ease) {
  animation: slideInFromRight $duration $timing-function;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
  height: calc(
    100vh - var(--top-bar-height) - 48px - 24px - 24px
  ); // 48px is the height of the bottom button, 24px is the padding around the button

  overflow-y: auto;

  @media screen and (min-width: $max-mobile-screen) {
    width: 600px;
    padding-top: var(--offset-md);
    gap: var(--offset-lg);
  }
  @include slide-in-from-right(0.3s, ease-in-out);
}

.welcomeTitle {
  color: #8d8fca;
  margin-top: 26px;
  margin-bottom: var(--offset-xx-sm);
  text-transform: none;
}

.content {
  font-weight: 600;
  color: var(--new-blue1);
  width: 300px;
  margin-bottom: 36px;
  text-align: center;
}

.clickText {
  color: #33385c;
  margin-bottom: 36px;
}

.linkText {
  color: var(--new-blue3);
  text-decoration: underline;
  margin-bottom: var(--offset-sm);
  cursor: pointer;
  flex: 1;
  align-content: end;
}

.startButton {
  background-color: var(--pink-2);
  height: 48px;
  width: 343px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.startButton .icon {
  position: absolute;
  right: 16px;
  margin-top: -10px;
}

.noUnderline {
  text-decoration: none;
  color: var(--white);
}

.bottomButton {
  position: fixed;
  bottom: var(--offset-sm);
}
