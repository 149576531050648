.chartResponsiveContainer {
  :focus,
  & > g:focus,
  & > g > g:focus {
    outline: none !important;
    outline-color: none;
    outline-style: none;
    outline-width: none;
  }

  & ul[class='recharts-default-legend'] {
    margin: 0px;
    text-align: left !important;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 5px !important;

    > li {
      line-height: 20px;
    }
  }

  .legendText {
    color: #5a608d;
    text-transform: uppercase;
  }
}

.customTooltip {
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.tooltipArrow {
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid #f05b94;
  border-bottom: 8px solid transparent;
}

.label {
  margin: 0;
}
