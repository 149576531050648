.statusTooltip {
  padding: 0px;
  background-color: var(--white);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  margin-top: 0;
  pointer-events: all;
}

.tooltipArrow::before {
  background-color: var(--white);
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
}

.emojiContainer {
  display: grid;
  grid-gap: 2px;
  padding: 10px;
  grid-template-columns: repeat(7, 1fr);
}

.emojiContainer button {
  padding: 5px;
}

.emojiContainer img {
  width: 24px;
  height: 24px;
}

.textContainer {
  margin-left: var(--offset-xxx-sm);
}
