.container {
  overflow: hidden;
  background-color: white;

  border-radius: 5px;
  border: 1px solid var(--new-grey5);

  display: flex;
  flex-direction: column;

  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &.columnStart {
    grid-column: 1;
  }

  img {
    width: 100%;
    height: 199px;

    object-fit: cover;
    object-position: top center;
  }

  .buttonContainer {
    display: flex;
    align-items: center;

    border-top: 1px solid var(--new-grey5);
    padding: var(--offset-xx-sm) var(--offset-xx-sm);

    .button {
      flex: 2;
      margin-right: var(--offset-xx-sm);

      height: 40px;

      &.disabled {
        cursor: not-allowed;
      }

      &.hidden {
        opacity: 0;
      }
    }

    .element {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      line-height: 20px;
      text-align: center;

      display: inline-block;

      background-color: var(--new-blue3);
      color: white;
    }
  }
}
