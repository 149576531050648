.dialog {
  padding: var(--offset-lg);
  width: 100%;
  max-width: 900px;
}

.submitButton {
  margin-top: var(--offset-sm);
}

.label {
  margin-bottom: var(--offset-sm);
}
