@import '_scss-variables.scss';

.tableCell {
  padding: var(--offset-xxxx-sm);
}

.noBorderTableCell {
  padding: var(--offset-xxxx-sm);
  border-bottom: none;
}

.assignmentTitleText {
  color: #33385c;
}

.checkbox :checked {
  color: pink;
}

.hoverLink {
  color: #7477b8;
}
