.icon {
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
  align-content: center;
}

.messageTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.messageText {
  color: #666;
  line-height: 1.5;
  max-width: 80%;
  margin: auto;
  font-size: 15px;
}
