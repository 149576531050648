@import '_scss-variables.scss';

.scrollableAssignmentsContainer {
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
  padding-right: var(--offset-x-sm);

  .assignmentBox {
    width: 375px;
    flex: 375px 0;
    flex-shrink: 0;

    margin: var(--offset-xx-sm) var(--offset-xxx-sm) 0 var(--offset-xxx-sm);
  }
}

.scrollableAssignmentsContainer::-webkit-scrollbar {
  display: none;
}
