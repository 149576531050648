.container {
  padding: var(--offset-x-sm) var(--offset-sm);
}

.headerLimiter {
  max-width: 1125px;
}

.labelContainer {
  margin-bottom: var(--offset-sm);
}

.noResultsContainer {
  padding: var(--offset-x-sm);
  margin-top: var(--offset-sm);
  max-width: 1010px;
}

.addButton > button + button {
  width: 200px;
}
