.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: var(--offset-x-sm);

  .container {
    width: 330px;
    height: 310px;
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    position: relative;

    border-radius: 5px;
    background-color: var(--white);
    border: 1px solid var(--new-grey5);

    padding: var(--offset-sm) var(--offset-sm) var(--offset-x-sm)
      var(--offset-sm);

    &.hoverable {
      cursor: pointer;
      transition: background-color 0.2s linear;

      &:hover {
        background-color: var(--new-blue6);
      }
    }

    & + .container {
      margin-left: 15px;
    }

    .topRow {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      .icon {
        position: absolute;
        right: var(--offset-md);
      }
    }

    .description {
      max-width: 262px;

      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
    }
  }
}
