@import '_scss-variables.scss';

.container {
  height: 94px;
  background-color: var(--white);

  border: 1px solid var(--grey-1);
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-sizing: border-box;

  @media screen and (max-width: $max-tablet-screen) {
    border-right: 0;
    border-left: 0;

    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .content {
    display: flex;

    padding: var(--offset-sm);

    .studentInfo {
      width: 229px;

      @media screen and (max-width: $max-tablet-screen) {
        width: 150px;
      }

      @media only print {
        width: auto;
        max-width: 100px;
        margin-right: 10px;
        word-break: break-all;
      }
    }

    .filtersContainer {
      & > :first-child {
        margin-bottom: var(--offset-xx-sm);
      }

      .filters {
        display: flex;
        align-items: baseline;

        .filter {
          margin: 0 4px;

          button {
            height: 24px;
          }
        }

        .text {
          font-weight: 400;
        }

        .resetFilters {
          margin-left: 36px;

          @media only print {
            margin-left: 10px;
          }

          button {
            background-color: var(--new-grey7);
            color: var(--pink-2);

            &:hover {
              background-color: var(--new-grey6);
            }

            @media screen and (max-width: $max-tablet-screen) {
              > span p {
                font-weight: 600;
                font-size: 11px;
                line-height: 15px;

                span {
                  display: none;
                }
              }
            }
          }

          @media screen and (max-width: $max-tablet-screen) {
            margin-left: 45px;
          }
        }
      }
    }
  }
}
