.dialogTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--offset-x-lg) var(--offset-x-lg) var(--offset-lg)
    var(--offset-x-lg);

  border-bottom: 1px var(--new-grey5) solid;
}
.dialogTitle {
  display: flex;
  flex-direction: column;
}

.overallWrapper {
  display: flex;
  flex-direction: column;

  padding: var(--offset-sm) var(--offset-x-lg) var(--offset-lg)
    var(--offset-x-lg);

  border-bottom: 1px var(--new-grey5) solid;
}
.overallTitle {
  padding-bottom: 18px;
}
.statusBox {
  display: flex;
  align-items: center;
}
.statusIcon {
  width: 30px;
  height: 30px;
}
.statusText {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: var(--offset-xx-sm);
}
.statusProgress {
  height: 6px;
}

.tableWrapper {
  display: flex;
  flex-direction: column;

  /* The IconButtons have 12px padding */
  padding: calc(var(--offset-sm) - 12px) var(--offset-x-lg) var(--offset-xxx-lg)
    var(--offset-x-lg);
}
.tableTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: calc(18px - 12px); /* The IconButtons have 12px padding */
}

.tableContainer {
  overflow: auto;
  border-radius: 5px;
  max-height: 370px;
}

/* Hide table scrollbar for Chrome, Safari and Opera */
.tableContainer::-webkit-scrollbar {
  display: none;
}

/* Hide table scrollbar for IE, Edge and Firefox */
.tableContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
