.container {
  margin-top: var(--offset-lg);

  display: grid;
  grid-gap: var(--offset-xx-sm);
  grid-template-columns: repeat(auto-fit, 250px);

  justify-content: center;

  .title {
    grid-column: 1;

    &:not(:first-child) {
      margin-top: var(--offset-md);
    }

    & + .placeholderBox {
      grid-column: 1;
    }
  }
}
