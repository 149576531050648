.closeButton {
  position: absolute;
  top: calc(-1 * var(--offset-x-lg));
  right: calc(-1 * var(--offset-x-lg));

  width: var(--dialog-close-button-size);
  height: var(--dialog-close-button-size);
  border-radius: 50%;
  color: #fff;
  background: rgba(237, 237, 237, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.dialog {
  border-radius: 5px;
  /* The following styles allow us to add custom close button outside the dialog body */
  position: relative;
  overflow-x: visible;
  overflow-y: visible;
  /* Make sure the close icon doesn't go off screen */
  margin: calc(var(--dialog-close-button-size) + var(--offset-x-lg)) 0;
}

.scrollBody {
  vertical-align: top;
}
