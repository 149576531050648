.container > *:not(.divider) {
  padding: var(--offset-sm);
}

.notes,
.infoRow {
  display: flex;
}

.notesContent {
  width: 100%;
  margin-left: var(--offset-xx-sm);
}

/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 320px);
    grid-column-gap: var(--offset-xx-sm);
  }
}
