@import '_scss-variables.scss';

.container {
  position: absolute;
  right: 0;
  top: 40px;

  @media screen and (max-width: $max-tablet-screen) {
    top: 10px;
  }

  .menuPaper {
    height: 66px;
    width: 127px;

    padding: 0;
  }
  .menuList {
    padding: 0;
  }
  .menuList > li {
    padding-left: 10px;
  }
  .item {
    height: 22px;

    color: var(--new-grey2);
  }

  .itemSelected {
    color: var(--new-grey7);
  }
}

.optionContainer {
  display: flex;
  align-items: center;

  > svg {
    margin-right: 5px;
    width: 14px;
    height: 14px;
  }
}
