.illustration {
  display: block;
  min-height: 200px;
  margin: 20px auto;
}

.dialogButton {
  width: 270px;
}

.assignmentName {
  margin: 0 auto;
}

.dialogPaper {
  width: 350px;
  box-sizing: border-box;
}

.dialogActions {
  padding-top: 10px;
}
