@import '_scss-variables.scss';

.overviewContainer {
  margin: var(--offset-lg) var(--offset-sm);
}

.button {
  color: var(--dark-grey);
  border-radius: 25px;
  border-color: var(--grey-1);
  font-weight: 600;
}

.buttonActive {
  background-color: var(--grey-3);
  color: white;
}

.buttonActive:hover {
  background-color: var(--dark-grey);
}

.trackerIcon {
  margin-right: var(--offset-xxx-sm);
}
.trackerIconLarge {
  font-size: 37px;
}

.trackerName {
  width: 100%;
}

.sectionTitle {
  padding-left: var(--offset-x-lg);
}

.assignmentRow {
  display: grid;
  align-items: center;
  grid-column-gap: var(--offset-sm);
  grid-template-columns: 1.5fr repeat(5, 1fr);

  width: 100%;
  padding: var(--offset-sm) 0;
  border-bottom: 1px solid var(--new-grey5);
}

.titleRow {
  padding-left: var(--offset-lg);
}
.titleRowTablet {
  grid-column-start: 1;
  grid-column-end: 7;

  padding-left: var(--offset-sm);
  padding-right: var(--offset-sm);

  margin-bottom: var(--offset-x-sm);
}

.headerRowLarge {
  display: grid;
  align-items: center;

  grid-column-start: 2;
  grid-column-end: 7;

  grid-column-gap: var(--offset-sm);
  grid-template-columns: repeat(5, 1fr);
}
.headerRowTablet {
  display: grid;
  align-items: center;

  grid-column-start: 1;
  grid-column-end: 7;

  grid-column-gap: var(--offset-sm);
  grid-template-columns: 1.5fr repeat(5, 1fr);

  padding: 0 var(--offset-sm);
}

.sectionRow {
  text-decoration: none;

  display: grid;
  align-items: center;

  grid-column-start: 1;
  grid-column-end: 7;

  grid-column-gap: var(--offset-sm);
  grid-template-columns: 1.5fr repeat(5, 1fr);

  padding: var(--offset-xx-sm) 0;
}
.sectionRow:hover {
  background-color: var(--new-grey7);
}
.sectionRowLarge > :first-child {
  margin-left: var(--offset-xx-lg);
}
.sectionRowTablet {
  padding-left: var(--offset-sm);
  padding-right: var(--offset-sm);
}

.pagination {
  padding: var(--offset-md);
}

.pagination > ul {
  justify-content: center;
}

.noAssignmentsImage {
  display: block;
  margin: 65px auto;
  max-width: 100%;
  cursor: pointer;
}
