@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

/* COLOR VARIABLES */
:root {
  --darker-hover-pink: rgb(167, 62, 102);
  --darker-pink: #dd3d7b;
  --pink: #ef5a93;
  --pink-1: #cc2364;
  --pink-2: #dd3d7b;
  --pink-3: #ef5a93;
  --pink-4: #ff73a2;
  --pink-5: #ffe5ee;
  --light-pink: #ff6ea7;
  --lighter-pink: #f8adca;
  --darker-green-2: #438642;
  --darker-green: #61a460;
  --green: #75b874;
  --lighter-green: #89cc88;
  --dark-yellow: #d29f3d;
  --light-yellow: #f0bd5b;
  --lighter-yellow: #fff0d0;
  --lighter-yellow-1: #fff4de;
  --darker-blue: #292e52;
  --dark-blue: #33385c;
  --blue: #555893;
  --light-blue: #7477b8;
  --lighter-blue: #b0b3f4;
  --darker-grey: #4d4d4d;
  --dark-grey: #7e7e7e; /* this is palette.colors.greyDark */
  --grey-7: #f5f5f5; /* replaced with --new-grey-7 */
  --grey-4: #b7b7b7; /* replaced with --new-grey-4 */
  --grey-3: #949494; /* replaced with --new-grey-3 */
  --grey-2: #c4c4c4; /* replaced with --new-grey4 */
  --grey-1: #d9d9d9; /* replaces with --new-grey-6 */
  --grey: #e3e3e3;
  --light-grey: #ededed;
  --lighter-grey: #f3f3f3;
  --white-grey: #fafafa;
  --white: #ffffff;
  --black: #000000;
  --new-grey1: #4d4d4d;
  --new-grey2: #757575;
  --new-grey3: #949494;
  --new-grey4: #b7b7b7;
  --new-grey5: #d0d0d0;
  --new-grey6: #ededed;
  --new-grey7: #f5f5f5;
  --new-blue1: #292e52;
  --new-blue2: #333966;
  --new-blue3: #555893;
  --new-blue4: #7477b8;
  --new-blue5: #b0b3f4;
  --new-blue6: #e6e7ff;
  --new-blue7: #535c9f;
  --new-blue8: #585981;
  --new_blue9: #5a608d;
  --new-green1: #3a7339;
  --new-green2: #438642;
  --new-green3: #4e994d;
  --new-green4: #6cb36b;
  --new-green5: #daf2da;
  --new-yellow1: #b37909;
  --new-yellow2: #bf881d;
  --new-yellow3: #d29f3d;
  --new-yellow4: #e4b14e;
  --new-yellow5: #ffeecc;
  --new-yellow6: #fff1e1;
}

html {
  height: 100%;
}

body {
  font-size: 16px; /* This overwrites the MaterialCssBaseline deafult one of 14px */
  height: 100%;
}

p,
ul,
li {
  line-height: 1.5rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
}

/* Removes standard outline: -webkig-focus-ring-color auto 1px */
ul:focus {
  outline: none;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.pushLeft {
  margin-left: 1rem !important;
}

.pointer {
  cursor: pointer;
}

.noscrollbar::-webkit-scrollbar {
  display: none !important;
}

@media print {
  .noPrint,
  .noPrint * {
    display: none !important;
  }
}
