.container {
  position: absolute;
  max-width: 100%;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, 0);
}

.stickersContainer {
  margin: auto;
  width: 50%;
}

.filterSliderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filterSlider {
  width: 250px;
}

.transparentButton {
  background-color: rgba(255, 255, 255, 0.5);
}

.closeButton {
  background-color: rgb(243, 243, 243, 0.75);
}
