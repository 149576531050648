.header {
  display: grid;
  grid-template-columns: repeat(2, auto) max-content;
  grid-gap: var(--offset-sm);
  align-items: center;
  margin-top: var(--offset-sm);
}

.googleClassroomWidget {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.googleClassroomWidget > *:last-child {
  margin-left: var(--offset-xx-sm);
}

.assignment {
  display: grid;
  align-items: center;
  grid-template-columns:
    minmax(200px, 300px)
    repeat(2, minmax(200px, max-content))
    min-content;
  grid-gap: var(--offset-sm);
  margin-top: var(--offset-sm);
}

.link {
  text-decoration: none;
}

.dateContainer {
  display: flex;
  align-items: center;
}
.datePicker {
  height: 30px;
  margin-left: var(--offset-xx-sm);
}
