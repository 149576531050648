.mainContainer {
  display: flex;
  padding: var(--offset-sm);
  background-color: #f9f9f9;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.disabled {
    cursor: not-allowed;
  }

  .summary {
    flex-direction: column;
    justify-content: space-between;

    .titleContainer {
      display: flex;
      align-items: center;
      gap: var(--offset-xx-sm);
    }
    .description {
      font-weight: 600;
    }
    .rectangle {
      width: 18px;
      height: 11px;
    }
  }
}
