.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.container {
  padding-bottom: 40px;
  padding-top: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}

.logInButton {
  position: absolute;
  top: 30px;
  right: 50px;
  border-radius: 5px;
}

.welcomeText {
  margin-bottom: 40px;
  text-align: center;
}

.welcomeTextMobile {
  display: none;
}

.iconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconsContainer svg {
  cursor: pointer;
}

.teacherContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.teacherContainer svg {
  opacity: 0.75;
}
.teacherContainer:hover svg {
  opacity: 1;
}

/* This is a one-off height change, so it's not in the components/Button implementation */
.teacherContainer .teacherButton {
  height: 50px;
  width: 164px;
  margin: auto;
}

.studentContainer {
  margin-left: 120px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.studentContainer svg {
  opacity: 0.75;
}
.studentContainer:hover svg {
  opacity: 1;
}

/* This is a one-off height change, so it's not in the components/Button implementation */
.studentContainer .studentButton {
  height: 50px;
  width: 164px;
  margin: auto;
}

.mobileScreen {
  display: none;
}

@media only screen and (max-width: 670px) {
  .page {
    display: flex;
    height: auto;
    min-height: 100%;
    position: static;
  }
  .page > * {
    flex-shrink: 0;
  }
  .container {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 600px) {
  .mobileScreen {
    display: block;
  }

  .lgScreen {
    display: none;
  }

  .mobileLogo {
    margin: 10px auto 0 auto;
    text-align: center;
  }

  .mobileLogo img {
    width: 136px;
    height: auto;
  }

  .logInButton {
    display: none;
  }

  .welcomeText {
    display: none;
  }

  .welcomeTextMobile {
    display: block;
    text-align: center;
    margin-bottom: 40px;
  }

  .teacherContainer {
    grid-gap: 6px;
  }

  .teacherContainer > svg {
    width: 148px;
    height: auto;
    opacity: 1;
  }

  .studentContainer {
    margin-left: 30px;
  }

  .studentContainer > svg {
    width: 148px;
    height: auto;
    opacity: 1;
  }

  .teacherContainer .teacherButton {
    height: 40px;
    width: 110px;
    margin: auto;
    font-weight: 700;
  }

  .studentContainer .studentButton {
    height: 40px;
    width: 110px;
    margin: auto;
    font-weight: 700;
  }

  .mobileLogInContainer {
    margin-top: 45px;
    text-align: center;
  }

  .mobileLogInButton {
    margin-top: 7px;
  }
}
