@import '_scss-variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(var(--top-bar-height) + 100px);
}

.navigationTitle {
  width: 100%;
  color: var(--new-blue1);
  font-weight: 600;
  text-align: center;
  padding-left: var(--offset-x-lg);

  @media screen and (min-width: $min-desktop-screen) {
    padding-left: 100px;
  }
}

.title {
  color: var(--new-blue3);
  margin-top: var(--offset-md);
  margin-left: var(--offset-x-lg);
  margin-right: var(--offset-x-lg);
  margin-bottom: var(--offset-x-sm);
  text-align: center;
  text-transform: none;
}

.communityContent {
  text-align: center;
  margin-left: var(--offset-x-lg);
  margin-right: var(--offset-x-lg);
  margin-bottom: var(--offset-md);
  color: var(--new-blue1);
}

.backButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  user-select: none;
  & > svg {
    fill: var(--darker-blue);
  }
}

.submitButton {
  width: 327px;
  background-color: var(--pink-2);
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: var(--pink-2);
  }
}
