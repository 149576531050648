.container {
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 81px;
  background-color: #f6f5ff;
  padding: var(----offset-sm) var(--offset-xx-sm);
}

.dailyChart {
  width: 58px;
  height: 81px;
}
.arrow {
  display: flex;
  align-items: center;
  cursor: pointer;
}
