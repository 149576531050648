@import '_scss-variables.scss';

.assignmentCell {
  padding: var(--offset-xx-sm);
  padding-left: 0px;
  background-color: #ffffff;
  width: 20%;
}

.studentCell {
  padding: var(--offset-xx-sm);
  padding-left: 0px;
  background-color: #ffffff;
  width: 40%;
}

.nextStepsCell {
  padding: var(--offset-xx-sm);
  padding-left: 0px;
  background-color: #ffffff;
  width: 20%;
}

.resourcesCell {
  padding: var(--offset-xx-sm);
  padding-left: 0px;
  background-color: #ffffff;
  width: 15%;
}
