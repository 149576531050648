.container {
  margin-bottom: var(--offset-x-sm);
  display: flex;
  flex-direction: column;

  .sortsContainer {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--offset-xx-sm);
    margin-top: var(--offset-xx-sm);
    align-items: flex-start;
  }
}
