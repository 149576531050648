.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.tabs {
  background-color: var(--new-blue1);
  height: 76px;
}
.tabs > .tab {
  position: relative;
  cursor: pointer;
}

.tabs > .tab > .count {
  position: absolute;
  right: calc(50% - 50px);
  top: 30px;

  width: 21px;
  height: 21px;
  line-height: 23px;
  text-align: center;

  border-radius: 50%;
  color: var(--new-blue1);
  background-color: var(--new-blue6);
}

.tabs > .tab.active {
  color: var(--pink);
  padding-top: 3px;
  border-bottom: 3px solid var(--pink);
}

.tabs > .tab.active > .count {
  color: white;
  background-color: var(--pink-2);
}

.tabs > .tab.active > .count.assigned {
  color: white;
}

.listContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.assignments {
  flex: 1;
  overflow-y: auto;
}

.tasks > .task > .arrow > i {
  display: flex;
  justify-content: flex-end;
}

.assignments > .noAssignments {
  position: relative;
  height: 300px;

  background-image: url('./assets/celebrate.png'),
    url('./assets/background.png');
  background-repeat: no-repeat;
  background-position: center top 20%, center top 50%;
}

.assignments > .noAssignments > .header {
  width: 12rem;

  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.5rem;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: var(--dark-blue);
}

.pagination {
  padding: var(--offset-md);
}

.pagination > ul {
  justify-content: center;
}

.assignmentContainer {
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--new-grey6);
}

.assignmentContainer .title {
  padding-bottom: 10px;
}
