.column {
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-right: var(--offset-xx-sm);

  border-radius: 5px;
  background-color: var(--new-blue2);

  max-width: 350px;
  min-width: 350px;
  max-height: 100%;
}
.column:last-child {
  margin-right: var(--offset-sm);
}

.columnHeader {
  height: 40px;
  min-height: 40px;

  padding: var(--offset-xx-sm);
  background-color: var(--new-blue3);

  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.columnNameInputField {
  height: 20px;
}
.columnNameWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.columnControls {
  display: flex;
  align-items: center;
}

.scrollable {
  border: 1px solid var(--new-blue2);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  overflow-y: auto;
  -ms-overflow-style: none; /* Hide scrollbar on IE & Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar {
  display: none;
}
