.filtersContainer {
  background-color: var(--new-grey6);
  padding: 8px 16px;
}

.filterGrid {
  min-height: 47px;
}

.chip {
  display: flex;
  align-items: center;
  padding: var(--offset-xxx-sm);
  border-radius: 2px;
  height: 16px;
  background-color: var(--new-grey5);
}

.chipIcon {
  padding-left: var(--offset-xxx-sm);
  width: 14px;
  height: 14px;
  cursor: pointer;
  fill: var(--new-grey1);
}
