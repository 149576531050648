.container {
  overflow: hidden;
  background-color: white;

  border-radius: 8px;
  border: 1px solid var(--new-grey5);

  display: flex;
  flex-direction: column;
  width: 250px;

  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  .header {
    height: 30px;
    background-color: var(--new-grey2);
    padding: 5px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  img {
    width: 100%;
    height: 100px;

    object-fit: cover;
    object-position: top center;
  }

  .buttonContainer {
    border-top: 1px solid var(--new-grey5);
    padding: var(--offset-xx-sm) var(--offset-xx-sm);

    .button {
      height: 25px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      color: var(--new-blue4);
      font-weight: 600;

      background-color: var(--new-grey7);
      border: 1px solid var(--new-grey5);
      border-radius: 5px;

      padding: 0 var(--offset-xx-sm);

      > svg {
        width: 16px;
        height: 16px;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.hidden {
        opacity: 0;
      }

      .editIcon {
        width: 16px;
        height: 16px;
      }
    }

    .element {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      line-height: 20px;
      text-align: center;

      display: inline-block;

      background-color: var(--new-blue3);
      color: white;
    }
  }
}
