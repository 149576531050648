.wrapper {
  background: var(--light-grey);
  border-radius: 20px;
  display: flex;
}
.wrapper.small {
  height: 6px;
}
.wrapper.medium {
  height: 14px;
}
.wrapper.big {
  height: 25px;
}

.progress {
  border-radius: 20px;
  height: 100%;

  float: left;
  margin-left: -20px; /* displays every next bar under its left sibling */
  position: relative;
}

.progress:first-child {
  margin-left: 0;
}

.progress > h1 {
  color: #fff;
  line-height: 25px; /* equal to the height of the multi progress bar */
  padding-left: var(--offset-md);
}

.progress:first-child > h1 {
  margin-left: 0;
  padding-left: var(--offset-xx-sm);
}
