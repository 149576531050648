.bottomNavigation {
  position: fixed;
  z-index: 1000;
  bottom: 0;

  width: 100%;
  height: 65px;

  border-top: 1px solid #ededed;
}

.item {
  color: #8086b3;
  position: relative;
}

.itemSelected {
  font-weight: bold;
}

.itemSelected path {
  fill: var(--pink-3);
}
