.dialog {
  width: 600px;
  max-width: 600px;
}

.titleContainer {
  padding: var(--offset-sm) var(--offset-x-lg);
  background-color: var(--new-grey6);
  display: flex;
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom: 1px solid var(--new-grey5);
}
.titleContainer.backButtonVisible {
  padding-left: var(--offset-xx-sm);
}
.titleContainer.backButtonVisible > p {
  margin-left: var(--offset-xx-sm);
}

.contentContainer {
  padding: var(--offset-md) var(--offset-x-lg) var(--offset-x-lg)
    var(--offset-x-lg);
}
.contentContainer > * {
  margin-bottom: var(--offset-md);
}
.contentContainer > *:last-child {
  margin-bottom: 0;
}

.msaWrapper {
  border-radius: 5px;
  background-color: var(--new-blue6);
  padding: var(--offset-xx-sm) var(--offset-sm);
  width: fit-content;
}

.description {
  white-space: pre-wrap;
}
