.dialog {
  width: 700px;
  padding: var(--offset-x-lg);
}
.dialog.hidden {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saveButton {
  width: 130px;
}

.container {
  margin-top: var(--offset-lg);
}

.label {
  margin-top: var(--offset-sm);
}

.teamContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 0.5fr 2fr;
}
.createTeamButton {
  height: 100%;
}

.textarea {
  /* Imitating Typography B-Text-2 */
  font-size: 15px;
  font-weight: 400;
}

.agendaContainer {
  display: grid;
  align-items: center;
  grid-gap: var(--offset-sm);
  grid-template-columns: repeat(2, 1fr);

  margin-top: var(--offset-xx-sm);
}

.agendaUrlIcon {
  padding-right: var(--offset-xx-sm);
}

.linearProgress {
  margin-top: var(--offset-x-sm);
  margin-bottom: calc(-1 * var(--offset-x-sm));
}

.scheduleLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 275px;
}

.scheduleContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 2fr;
  column-gap: 73px;
}

.recurringDueDateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recurringDueDateField {
  width: 165px;
}
