.element {
  height: 18px;
  min-width: 18px;
  /* For big numbers. Adds 1px between the number and the border-radius, so the number can be read easier */
  padding: 0 1px;
  border-radius: 9px;
  line-height: 18px;
  cursor: pointer;
  text-align: center;
}

.element + .element {
  margin-left: 5px;
}

.element:hover:not(.selected) {
  background-color: var(--new-grey6);
}

.element.selected {
  background-color: var(--new-blue3);
  color: white;
}

.container {
  display: flex;
}
