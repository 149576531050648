.resultsExplanationItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--offset-x-sm) var(--offset-md);

  gap: var(--offset-xx-sm);

  background-color: white;
}

.title {
  letter-spacing: unset;
  color: var(--pink-1);
  font-weight: 700;
}

.description {
  color: var(--new-blue1);
  line-height: 13px;
  letter-spacing: normal;
  font-size: 14px;
}
