@import '_scss-variables.scss';

.topOffset {
  margin-top: var(--offset-sm);
}

.tasks {
  margin-top: var(--offset-x-lg);
}

.tasks > .tasksBox {
  padding: var(--offset-sm);
  margin-top: var(--offset-x-sm);
  border: 1px solid var(--grey-1);
  border-radius: 10px;
}
.tasks > .errorAlert {
  font-size: 1rem;
}

.addButtonWrapper {
  display: flex;
  justify-content: flex-start;
}
.addButtonWrapper > .button {
  padding: 0 var(--offset-md) 0 var(--offset-xx-sm);
  border-radius: 0 25px 25px 0;
  height: 40px;
}

.addButtonWrapper > .plusIcon {
  border-radius: 25px 0 0 25px;
  width: 40px;
  height: 40px;
  padding: 0;
}
.addButtonWrapper > .plusIcon > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addButtonWrapper > .plusIcon svg {
  font-size: 26px;
}

.addButtonWrapper > .button {
  background-color: var(--light-blue);
}

.addButtonWrapper > .plusIcon {
  background-color: var(--blue);
}

.addButtonWrapper:hover > .plusIcon {
  background-color: var(--dark-blue);
}

.addButtonWrapper:hover > .button {
  background-color: var(--blue);
}

.addCriteriaButton {
  display: inline;
}
.addCriteriaButton > .button {
  min-height: 25px;
  height: 25px;
  padding: 0 var(--offset-x-sm) 0 var(--offset-xx-sm);
  font-size: 12px;
}
.addCriteriaButton > .plusIcon {
  height: 25px;
  width: 25px;
  padding: 0;
}

.dropzoneWrapper {
  margin-top: var(--offset-x-lg);
}
.dropzoneBtn {
  margin: var(--offset-md) 0;
  color: var(--blue);
  background-color: var(--lighter-grey);

  border: 1px dashed var(--grey-2);
}

.stepper {
  background: transparent;
  padding: 10px 2px;

  &.condensed {
    padding: 10px 70px;

    @media screen and (max-width: $max-tablet-screen) {
      padding: 10px 2px;
    }
  }
}

.heading {
  margin: 40px 0 10px 0;
}

.stepperText {
  color: var(--grey-3);
}
