.wrapper {
  width: 100%;

  & + .wrapper {
    margin-top: var(--offset-xx-sm);
  }

  .container {
    display: flex;
    align-items: center;

    user-select: none;

    &:not(.withoutConnectorLine):before {
      content: '';

      left: 1px;
      top: -20px;
      position: absolute;

      width: 24px;
      height: calc(var(--connector-line-height) + 20px);

      border-left: 1px solid var(--new-grey5);
    }

    .iconContainer {
      z-index: 2;
      position: relative;

      width: 22px;

      &:after {
        content: '';

        top: 0px;
        right: -5px;
        position: absolute;

        height: 0;
        width: 25px;
        border-bottom: 1px solid var(--new-grey5);
      }

      .icon {
        width: 19px;
        height: 19px;

        line-height: 1;

        cursor: pointer;

        z-index: 2;
        top: -10px;
        left: -10px;
        position: absolute;
      }
    }

    .content {
      width: 100%;
      min-height: 40px;

      padding: 0 var(--offset-xx-sm);
      margin-left: var(--offset-xxx-sm);

      border-radius: 2px;
      border: 1px solid var(--new-grey5);

      display: flex;
      align-items: center;

      &.clickable {
        cursor: pointer;
      }

      .title {
        font-weight: 800;
        color: var(--new-blue2);

        margin-right: var(--offset-sm);
        padding: var(--offset-xx-sm) 0;

        min-width: max-content;
        max-width: 200px;
      }

      .description {
        color: var(--new-blue4);

        padding: var(--offset-xx-sm) 0;
      }

      svg {
        margin-left: auto;
      }
    }
  }

  .indented {
    margin-left: 24px;
    margin-top: var(--offset-xx-sm);

    &.withParentLevelLine:before {
      content: '';

      top: -10px;
      left: 1px;
      position: absolute;

      width: 0;
      height: 100%;

      border-left: 1px solid var(--new-grey5);
    }

    & > * + * {
      margin-top: var(--offset-xx-sm);
    }

    // These are basically child curriculum nodes.
    & > .wrapper {
      position: relative;
    }
  }
}
