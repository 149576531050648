@import '_scss-variables.scss';

.container {
  border-bottom: 1px solid var(--new-grey5);
  padding: var(--offset-xx-sm) var(--offset-x-sm);

  .topRow {
    margin-bottom: var(--offset-xx-sm);

    display: flex;
    align-items: center;
    justify-content: space-between;

    .code {
      color: var(--new-blue1);
      text-decoration: underline;
    }

    .group {
      display: flex;

      & > * {
        margin-left: var(--offset-sm);
      }

      .standardMsaWrapper {
        height: var(--offset-md);

        display: flex;
        align-items: center;

        padding: 0 var(--offset-xx-sm);

        border-radius: 5px;
        box-sizing: border-box;

        @media screen and (max-width: $max-tablet-screen) {
          height: var(--offset-sm);
        }

        > p {
          color: var(--white);
        }

        &.major {
          background: var(--new-yellow1);
        }

        &.supporting {
          background: var(--new-yellow4);
        }

        &.additional {
          background: var(--new-yellow5);

          > p {
            color: var(--new-yellow1);
          }
        }
      }
    }

    .icon {
      color: var(--new-grey4);
    }
  }
}
