.header {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr 1.2fr 0.3fr;

  text-align: start;

  margin: var(--offset-sm);
  padding: var(--offset-xx-sm) var(--offset-xx-sm);

  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  background-color: var(--new-gray-1);
}

.filters {
  margin: var(--offset-sm);
}

.pagination {
  display: flex;
  justify-content: center;
  margin: var(--offset-sm);
}
.loadingState {
  padding: var(--offset-sm);
}

.nextStepsPanel {
  margin: var(--offset-sm);
}
