.container {
  top: 30%;
  left: 40%;
  position: absolute;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 300px;
  height: 217px;
  background: var(--white);

  padding: var(--offset-lg) var(--offset-x-lg);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  .allMatchedText {
    margin-bottom: 30px;
  }

  .reviewButton {
    margin-bottom: var(--offset-md);
    background-color: var(--new-blue3);
  }
}
