.container {
  width: 100%;
  max-width: 1200px;
  max-height: 600px;
  padding: 20px;
  margin-bottom: 20px;
}

.topBar {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
}

.dropdownContainer {
  position: relative;
  flex: 1;
}

.dropdown {
  width: 100%;
  padding: 12px 16px;
  background: white;
  border: 1px solid #b2b6bd;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;

  &:hover:not(.disabled) {
    border-color: #cbd5e1;
  }

  &.disabled {
    background-color: #f1f3f6;
    cursor: not-allowed;
  }
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #b2b6bd;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.dropdownItem {
  padding: 12px 16px;
  cursor: pointer;
  border-bottom: 1px solid #e2e6ea;

  &:hover {
    background-color: #f1f3f6;
  }
}

.searchBar {
  margin-bottom: 20px;
}

.searchInput {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #b2b6bd;
  border-radius: 8px;
  outline: none;
  transition: all 0.2s ease;

  &:focus {
    border-color: #e11d48;
    box-shadow: 0 0 0 2px rgba(225, 29, 72, 0.1);
  }

  &:disabled {
    background-color: #f1f3f6;
    cursor: not-allowed;
  }
}

.content {
  display: flex;
  gap: 20px;
  height: 500px;
}

.unitList,
.lessonList {
  flex: 1;
  border: 1px solid #b2b6bd;
  border-radius: 8px;
  overflow-y: auto;
  border-bottom: 1px solid #e2e6ea;
}

.lessonList {
  flex: 2;
}

.unit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid #e2e6ea;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f1f3f6;
    border-left: 4px solid #e11d48;

    .chevron {
      color: #e11d48;
    }
  }

  &.selected {
    background-color: #f1f3f6;
    border-left: 4px solid #e11d48;
  }
}

.unitTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;

  span:first-child {
    font-weight: 600;
  }

  span:last-child {
    color: #64748b;
  }
}

.chevron {
  color: #94a3b8;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &.selected {
    color: #e11d48;
  }
}

.lesson {
  padding: 16px;
  border-bottom: 1px solid #e2e6ea;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f1f3f6;
  }

  &.selected {
    background-color: #f1f3f6;
    border-left: 4px solid #e11d48;
  }
}

.lessonTitle {
  div:first-child {
    font-weight: 600;
    margin-bottom: 4px;
  }
}

.lessonSubtitle {
  color: #64748b;
}

.loading {
  padding: 16px;
  text-align: center;
  color: #64748b;
}

.noResults {
  padding: 16px;
  text-align: center;
  color: #64748b;
  font-size: 14px;
}

.searchBar {
  position: relative;
  margin-bottom: 20px;

  .searchInput {
    padding-right: 40px;
  }

  .searchIcon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #94a3b8;
    opacity: 0.5;
    pointer-events: none;
  }
}

.dropdownItem {
  &:hover {
    background-color: #f1f3f6;
    border-left: 4px solid #e11d48;
    padding-left: 12px;
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    height: auto;
  }

  .unitList,
  .lessonList {
    max-height: 400px;
  }

  .topBar {
    flex-direction: column;
  }
}

.unitList,
.lessonList,
.dropdownMenu {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f5f9;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 4px;

    &:hover {
      background: #94a3b8;
    }
  }
}

.dropdown,
.searchInput,
.unit,
.lesson,
.chevron {
  transition: all 0.2s ease-in-out;
}
