.sectionTitle {
  color: var(--new-blue2);
  margin-bottom: var(--offset-xx-sm);
}

.innerText {
  color: var(--new-grey3);
}

.strengthSectionOuter {
  display: flex;
  flex-direction: column;
}

.strengthSectionInner {
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--new-grey5);
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  text-align: center;
  flex-grow: 1;
  gap: var(--offset-xx-sm);
  padding: var(--offset-sm) 0;

  &:hover {
    background-color: var(--new-grey7);
    color: var(--new-grey3);
    cursor: pointer;
  }
}

.strength {
  display: flex;
  flex-direction: row;
  gap: var(--offset-xx-sm);
}

.spacer {
  display: flex;
  flex-grow: 1;
}

.strengthNumber {
  color: var(--new-green4);
  line-height: 40px;
  font-size: 40px;
}

.checkIcon {
  color: var(--new-green4);
  width: 35px;
  height: 35px;
}

.strengthText {
  display: flex;
  flex-direction: column;
}

.averageText {
  margin-top: var(--offset-xx-sm);
  text-align: center;
  color: var(--new-blue2);
}
