.filtersDrawer {
  z-index: 900 !important; /* This overrides an inline style property */
}

.filtersDrawerPaper {
  width: 285px;
  height: calc(100% - 48px);
  border-top-left-radius: 5px;
  border: 1px solid var(--new-grey5);
}

.filterTitle {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.backIcon {
  margin-right: 30px;
}

.filterIcon {
  margin-right: 10px;
}

.menuSection {
  padding: var(--offset-x-sm);
  border-bottom: 1px solid var(--new-grey5);
}

.itemPadded {
  margin: var(--offset-x-sm);
  margin-bottom: 0;
}

.selectedSection {
  border-left: 3px solid var(--light-blue); /* This is the old name of blue-4*/
}

.applyButtonContianer {
  padding: var(--offset-xxx-lg) var(--offset-x-sm) var(--offset-lg)
    var(--offset-x-sm);
  border-top: 1px solid var(--new-grey5);
}

.menuList {
  height: -webkit-fill-available; /* TODO: test on safari */
  overflow: auto;
}
