@import '_scss-variables.scss';

.resultsChart {
  width: 100%;
  height: 400px;

  @media screen and (min-width: $max-mobile-screen) {
    width: 600px;
    margin-top: 0;
  }
}
