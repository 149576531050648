.container {
  padding-left: var(--offset-xx-sm);
  padding-right: var(--offset-xx-sm);
  padding-bottom: var(--offset-lg);
}

.standardButton {
  margin-top: 16px;
}

.materialsButton {
  margin-top: var(--offset-x-sm);
  margin-left: var(--offset-xx-sm);
}

.name {
  margin-bottom: var(--offset-sm);

  &:placeholder-shown {
    background-color: white;
    transition: all 0.2s ease;
  }
}
.description {
  height: 100%;
}

.actionButtons {
  margin-bottom: var(--offset-xxx-lg);
}
