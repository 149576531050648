@import '_scss-variables.scss';

.container {
  position: relative;

  .image {
    cursor: pointer;
  }

  .emptyWorkPlaceholder {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      width: 100px;
      height: 100px;

      & > * {
        fill: var(--new-grey5);
      }
    }
  }

  .cardSelectCheckbox {
    position: absolute;
    padding: 0;
    top: 12px;
    right: 12px;
  }

  .leftArrow {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }

  .topArrow {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%) rotate(270deg);
  }

  .rightArrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .bottomArrow {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) rotate(90deg);
  }
}
