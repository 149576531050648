.rightMenu {
  width: 195px;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: white;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

@media only screen and (max-width: 992px) {
  .rightMenu {
    position: fixed;
    right: -195px;
    transition: right ease 0.2s;
    z-index: 1;
  }
  .rightMenu.expanded {
    right: 0;
  }
}

.section {
  padding: var(--offset-sm) var(--offset-xx-sm) var(--offset-sm) 0;
}
.section.noPaddingRight {
  padding-right: 0;
}

.section .sectionHeading {
  padding-left: var(--offset-sm);
  padding-bottom: 6px; /* The icon buttons have 14px padding */
}

.iconsList {
  padding-left: var(--offset-x-sm);
}

.criteriaText {
  padding-left: var(--offset-xx-sm);
  line-height: normal;
  white-space: pre-wrap;
}

.statusButtons {
  padding: var(--offset-sm) var(--offset-sm) 0 var(--offset-sm);
}

.statusButtons > button + button {
  margin-top: var(--offset-sm);
}

.advanceSwitch {
  padding-left: var(--offset-sm);
  padding-right: 8px; /* the switch has 12px padding on the right, so we're making it 20 */

  display: flex;
  align-items: center;
}

.toggleButton {
  width: 38px;
  height: 38px;
  padding: 0;
}
.toggleButton[disabled] {
  opacity: 0.2;
  background: transparent;
}

.listItem {
  min-height: 48px;
}

.rubricTile {
  width: 24px;
  height: 24px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}
.responsiveRubricTile {
  width: 30px;
  height: 30px;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  margin-top: var(--offset-xx-sm);
}
.responsiveRubricTile:last-child {
  margin-bottom: var(--offset-xx-sm);
}

.scoreInput {
  margin: 0 var(--offset-sm);
}
.scoreInput input {
  text-align: center;
}
.responsiveScoreInput {
  margin: var(--offset-xx-sm);
}
.responsiveScoreInput input {
  text-align: center;
  padding-right: 4px;
  padding-left: 4px;
}

.sectionForHidingMenu {
  display: none;
}

@media only screen and (max-width: 992px) {
  .sectionForHidingMenu {
    display: block;
    padding-bottom: var(--offset-xx-sm);
  }
}

.responsiveRightMenu {
  width: 67px;
  height: 100%;
  display: none;
  background-color: white;
}

@media only screen and (max-width: 992px) {
  .responsiveRightMenu {
    display: block;
  }
}

.iconsList {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, 24px);
}

.iconContainer {
  width: 67px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsiveStatusButton {
  width: 67px;
  height: 67px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}

.responsiveStatusButtonIcon {
  margin-right: 0;
}

.responsiveStatusButtonIcon svg:first-child {
  font-size: 26px;
}

.mirrored {
  transform: scaleX(-1);
}

.rightMenuExpandButton {
  height: 59px;
}

.criteriaButton {
  height: 59px;
}

.selectedButton {
  background-color: var(--new-grey7);
}
