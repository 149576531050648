.details {
  padding: var(--offset-sm);
  padding-top: var(--offset-xx-sm);
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  gap: var(--offset-lg);

  .students {
    display: flex;
    flex-direction: column;
    gap: var(--offset-x-sm);
    flex: 1;

    .studentsContainer {
      display: flex;
      gap: var(--offset-xx-sm);
      flex-wrap: wrap;

      .studentNameContainer {
        background-color: #eae9ff;
        cursor: pointer;
        padding: var(--offset-xx-sm);
        .studentName {
          font-weight: 600;
        }
      }
    }
  }

  .nextSteps {
    flex: 1;
  }
}
