.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--offset-xx-sm);
  background-color: #f3f4ff;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.title {
  flex-grow: 1;
  color: var(--darker-blue);
  font-weight: 600;
}

.spacer {
  flex-grow: 1;
}

.filtersPopoverContent {
  width: 100%;
}
