.filterWrapper {
  margin: var(--offset-x-sm) 0;

  display: grid;
  grid-template-columns: repeat(4, 25%);
  align-items: center;
}
.filterBox {
  display: flex;
  flex-direction: column;
  padding-right: var(--offset-md);
}
.filterBox:last-child {
  padding-right: 0;
}

.filterInput {
  width: 100%;
  margin-top: var(--offset-xxx-sm);
}
