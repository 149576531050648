.container {
  height: 32px;
  max-width: 200px;
  padding: var(--offset-xxx-sm);

  color: var(--new-blue3);
  background-color: var(--new-grey6);

  border-radius: 5px;

  .content {
    display: flex;
    align-items: center;

    text-align: left;
    font-weight: 600;
    text-transform: none;

    .buttonIcon {
      font-size: 24px;
      color: var(--new-blue3);
      margin-right: var(--offset-xx-sm);
    }
  }
}
