.container {
  margin-top: var(--offset-md);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--offset-x-sm);
  margin-bottom: var(--offset-x-sm);
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.titleText {
  color: var(--pink-1);
}

.topResourcesList {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--offset-x-sm);
}
