.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 50px 40px;
}

.navTabs {
  display: flex;
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  padding-top: 25px;

  .tab {
    flex: 1;
    font-size: 11px;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 0.5px;
    position: relative;
    text-align: center;
    margin-left: 10px;
    color: #e11d48;

    &::before {
      content: '';
      position: absolute;
      top: -14px;
      left: 0;
      width: calc(100% - 1px);
      height: 4px;
      background-color: #e11d48;
      width: 100%;
      opacity: 0.3;
    }

    &.active::before {
      color: #e11d48;
      opacity: 1;
    }

    &.tabProgress {
      color: #9ca3af;
      cursor: default;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.backButton {
  background-color: #b2c1f4;
  color: #020f29;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
  font-family: 'Nunito', sans-serif;
}
