.bottomNavigation {
  height: 60px;
  background-color: var(--white);
  position: relative;
  z-index: 1000;
}

.bottomNavigation:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: 2px solid var(--black);
  padding-top: 8px;
}

.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.navItem > button {
  padding: 2px;
  padding-top: 4px;
}

.selected {
  color: #f05b94;
}
