@import '_scss-variables.scss';

.dialog {
  padding: var(--offset-lg);
  width: 100%;
  max-width: 900px;

  @media screen and (max-width: $max-mobile-screen) {
    margin: 0px;
    padding: var(--offset-x-sm);
  }
}

.dialogTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: var(--offset-sm);

  .arrowIconContainer {
    width: 30px;
    height: 30px;
  }
}

.arrow {
  position: absolute;
  cursor: pointer;
  font-size: 30px;
  color: var(--new-grey4);
  &:hover {
    color: var(--new-grey2);
  }
}

.selectContainer {
  display: flex;
  overflow-x: auto;
  justify-content: center;

  .innterSelectContainer {
    display: flex;
    justify-content: flex-start;
    gap: var(--offset-x-lg);
    flex-direction: row;
    margin: var(--offset-sm);
    padding: var(--offset-sm);
    overflow-x: auto;

    max-width: 100%;
    width: max-content;

    @media screen and (max-width: $max-mobile-screen) {
      flex-direction: column;
      margin: var(--offset-sm) 0;
      padding: var(--offset-xs);
      width: 100%;
      gap: var(--offset-x-sm);

      .selectBox {
        width: 100% !important;
      }
    }

    .selectBox {
      display: flex;
      border: 1px solid var(--new-grey3);
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: var(--offset-sm);
      width: 200px;
      min-width: 200px;
      height: 200px;
      background-color: var(--new-blue6);
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
