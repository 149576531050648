.misconceptionsContainer {
  margin-bottom: var(--offset-x-sm);
  padding-top: var(--offset-x-sm);
  padding-bottom: var(--offset-x-sm);
  border-top: 1px solid var(--new-grey7);
  border-bottom: 1px solid var(--new-grey7);
}

.summaryData {
  margin: var(--offset-xx-sm) 0;
}
.innerData {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: var(--offset-sm);
  margin-top: var(--offset-xxx-sm);
}

.misconception {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: var(--offset-xxx-sm);
  margin-top: var(--offset-xxx-sm);
  .closeIcon {
    color: #8086b3;
    cursor: pointer;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin: var(--offset-xx-sm) 0;
}

.misconceptionText {
  text-transform: none;
  max-width: 100%;
  word-wrap: break-word;
  color: var(--pink);
  font-weight: bold;
}

.analysisText {
  text-transform: none;
  max-width: 100%;
  word-wrap: break-word;
  color: var(--new-blue1);
}
