.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .familyLogo {
    width: 200px;
    height: 200px;
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 25px;
  height: 25px;
}

.instruction {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}

.formBox {
  background-color: #c7c6ec;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.form {
  width: 100%;
}

.inputGroup {
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
}

.submitButton {
  width: 100%;
  background-color: #ff4081;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
}

.loadingMessage {
  font-size: 15px;
  color: #ff4081;
  text-align: center;
  margin-top: 300px;
}
