.divider {
  color: var(--new-blue1);
  background-color: var(--new-grey7);
  padding: var(--offset-xx-sm) var(--offset-x-sm);

  border-bottom: 1px solid var(--new-grey4);

  display: flex;

  .dash {
    margin: 0 var(--offset-xxx-sm);
  }
}
