@import '_scss-variables.scss';

.pathwaysProfileJourney {
  padding: 24px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: calc(100vh - var(--top-bar-height));
  @include slide-in-from-right(0.3s, ease-in-out);
}

.scrollableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: var(--offset-md);

  width: 100%;

  overflow-y: auto;

  height: calc(
    100vh - var(--top-bar-height) - 48px - 24px - 24px
  ); // 48px is the height of the bottom button, 24px is the padding of the bottom button
}
.questionText {
  color: var(--new-blue8);

  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}

.progress {
  width: 100%;
}
.answer {
  display: flex;
  width: 327px;
  height: 52px;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  color: var(--new-grey2);
  font-size: 15px;
  font-weight: 600;
  line-height: 17.5px;

  border-radius: 5px;
  border: 0px solid #e8e8e8;
  background: var(--new-grey7);

  cursor: pointer;
  &.selected {
    justify-content: space-between;
    border: 0px solid var(--Pathways-P-Purple-2, #8d8fca);
    background: #e5e7ff;
  }

  &.disabled {
    pointer-events: none;
  }
  .checkIcon {
    color: #438642;
  }
}

.submitButton {
  width: 327px;
  background-color: var(--pink-2);
  cursor: pointer;
  &:hover {
    background-color: var(--pink-2);
  }
}

.icon {
  position: absolute;
  right: 16px;
  margin-top: -10px;
}

@media screen and (max-width: $min-mobile-screen) {
  .answer {
    width: 100%;
  }
  .submitButton {
    width: 100%;
  }
}

.percentage {
  color: var(--new-grey3);
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.progressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 97%;
  background-color: 'white';
  gap: 20px;

  @media screen and (min-width: $max-mobile-screen) {
    width: 600px;
  }
}

.bottomButton {
  position: fixed;
  bottom: var(--offset-sm);
}

.bottomButton {
  position: fixed;
  bottom: var(--offset-sm);
}
