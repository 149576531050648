.assignmentRow {
  display: grid;
  align-items: center;
  grid-column-gap: var(--offset-sm);
  grid-template-columns: 1.5fr repeat(5, 1fr);

  width: 100%;
  padding: var(--offset-lg);
}

.titleRowTablet {
  grid-column-start: 1;
  grid-column-end: 7;
}

.headerRow {
  display: inline-grid;
  align-items: center;

  grid-column-start: 2;
  grid-column-end: 7;

  grid-column-gap: var(--offset-sm);
  grid-template-columns: repeat(5, 1fr);
}

.sectionRow {
  display: inline-grid;
  align-items: center;

  grid-column-start: 1;
  grid-column-end: 7;

  grid-column-gap: var(--offset-sm);
  grid-template-columns: 1.5fr repeat(5, 1fr);

  padding: var(--offset-xx-sm) 0;
}
