.dialog {
  width: 350px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--offset-x-lg) 60px;
}
.iconButton {
  padding: var(--offset-xx-sm);
}
