.input {
  height: 24px;
  margin-right: var(--offset-sm);
}
.input input {
  padding-top: 0;
  padding-bottom: 0;
}

.form {
  display: flex;
  align-items: center;
}
