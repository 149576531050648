@import '_scss-variables.scss';

.container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.scrollableContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.titleConteiner {
  width: 100%;
  height: 224px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('./assets/background.png');
}

.title {
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: 600;
  color: #bdc0ff;

  padding: 80px 20px 20px 20px;
}

.contentContainer {
  padding: var(--offset-sm);
  padding-bottom: 140px;
}

/* There are some embedded img in the lesson content, which we'd like to be at 100% width */
.contentContainer img {
  width: 100%;
  height: auto;
}
.contentTitle {
  padding-bottom: var(--offset-sm);
}

.bottomButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  position: fixed;
  bottom: 65px;
  background-color: #fff;
  border-top: 1px solid #ededed;
  left: 0;
  right: 0;
}

.submitButton {
  width: 327px;
  max-width: 600px;
  background-color: var(--pink-2);
  cursor: pointer;

  &:hover {
    background-color: var(--pink-2);
  }
}

.icon {
  position: absolute;
  right: 16px;
  right: var(--offset-x-sm);
  margin-top: -10px;
}

.navigationTitle {
  width: 100%;
  color: var(--new-blue1);
  font-weight: 600;
  text-align: center;
  padding-left: var(--offset-x-lg);

  @media screen and (min-width: $min-desktop-screen) {
    padding-left: 100px;
  }
}

.backButton {
  cursor: pointer;
  position: absolute;
  left: var(--offset-xx-sm);
  padding-top: var(--offset-xx-sm);
  & > svg {
    fill: var(--new-blue3);
  }
}

.progress {
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  width: 60px;
}
