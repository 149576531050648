@import '_scss-variables.scss';
.pathwaysProfilePersonalGoals {
  padding: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: calc(100vh - var(--top-bar-height));
}
