.messageContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
}

.icon {
  position: absolute;
  z-index: var(--offset-xx-sm);
  width: 24px;
  height: 24px;
  top: -6px;
  right: -6px;
}

.queryContainer {
  display: flex;
  width: 271px;
  padding: var(--offset-x-sm);
  justify-content: center;
  align-items: center;
  gap: var(--offset-xx-sm);
  border-radius: var(--offset-md) var(--offset-md) 0;
  border: 3px solid #f1f2ff;
  background-color: #fff;
  color: #33385c;
  word-break: break-word;
  text-align: left;
}
