.sectionTitle {
  color: var(--new-blue2);
  margin-bottom: var(--offset-xx-sm);
}

.innerText {
  color: var(--new-grey3);
}

.strength {
  display: flex;
  flex-direction: row;
  gap: var(--offset-xx-sm);
}

.spacer {
  display: flex;
  flex-grow: 1;
}

.whereToGrowSectionInner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: var(--offset-xx-sm);
  border-radius: 4px 4px 0px 0px;
  border: 1px solid var(--new-grey5);
}

.assignmentsNumber {
  color: var(--new-yellow3);
  line-height: 40px;
  font-size: 40px;
}

.bellIcon {
  color: var(--new-yellow3);
  width: 35px;
  height: 35px;
}

.conceptsNumber {
  color: var(--pink-3);
  line-height: 40px;
  font-size: 40px;
}

.progressIcon {
  color: var(--pink-3);
  width: 35px;
  height: 35px;
}

.growDataAssignmentsAssigned {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--offset-xx-sm);
  padding: var(--offset-sm) 0;
}

.growDataWhereToGrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--offset-xx-sm);
  padding: var(--offset-sm) 0;

  &:hover {
    background-color: var(--new-grey7);
    color: var(--new-grey3);
    cursor: pointer;
  }
}

.averageContainer {
  display: flex;
  flex-direction: row;
  margin-top: var(--offset-xx-sm);
  flex-wrap: wrap;

  > p {
    color: var(--new-blue2);
    flex: 1 0 100px; /* Each child will take at least 100px of width */
    box-sizing: border-box; /* Ensure padding and border are included in the width and height */
    text-align: center;
  }
}
