.dialogPaper {
  width: 275px;
  padding: var(--offset-lg) var(--offset-x-lg);
  display: inline-grid;
}

.yayImg {
  width: 145px;
  margin: var(--offset-x-sm) auto var(--offset-sm) auto;
}

.submittedHeader {
  margin-bottom: var(--offset-md);
}

.doneButton {
  margin-bottom: var(--offset-sm);
}
