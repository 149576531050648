.agendaDropzone {
  height: 40px;
}

.agendaFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.agendaFileInfo {
  display: flex;
  align-items: center;
  gap: var(--offset-xxx-sm);
}
