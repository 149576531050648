@import '_scss-variables.scss';

.resultsAccordion {
  width: 100%;
  margin-bottom: var(--offset-sm);
  margin-top: -45px;

  @media screen and (min-width: $max-mobile-screen) {
    width: 600px;
    margin-top: 0;
  }
}

.resultsAccordionTitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 19px;
}
