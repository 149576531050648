@import '_scss-variables.scss';

.tabsWrapper {
  height: 66px;
  background-color: var(--new-blue1);

  display: flex;
  justify-content: center;

  padding: var(--offset-sm) var(--offset-sm) var(--offset-xx-sm)
    var(--offset-sm);

  color: var(--new-blue5);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;

  @media screen and (max-width: $max-tablet-screen) {
    border-right: 0;
    border-left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tab {
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      margin-right: 7px;
    }

    box-sizing: border-box;
    padding: 0 var(--offset-xx-sm) var(--offset-xx-sm) var(--offset-xx-sm);

    cursor: pointer;
    border-bottom: 1px solid var(--new-blue5);

    & + .tab {
      margin-left: var(--offset-lg);
    }

    &.activeTab {
      color: var(--white);
      border-bottom: 1px solid var(--white);
      box-sizing: border-box;
    }
  }
}
