.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dotsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
}

.dot:nth-child(1) {
  background-color: var(--pink-3);
}

.dot:nth-child(2) {
  background-color: var(--lighter-pink);
}

.dot:nth-child(3) {
  background-color: var(--pink-5);
}

.dot {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  opacity: 0.4;
  transform: scale(0.4);
  animation-timing-function: linear;
}

.loading.forward-animation .dot {
  animation: forward-motion 1s infinite;
}

.loading.forward-animation .dot:nth-child(1) {
  animation-delay: 0.25s;
}

.loading.forward-animation .dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading.forward-animation .dot:nth-child(3) {
  animation-delay: 0.75s;
}

.loading.bounceAnimation .dot:nth-child(1) {
  animation: delayed-blink 0.8s infinite;
  animation-delay: 0.2s;
}

.loading.bounceAnimation .dot:nth-child(2) {
  animation: fast-blink 0.4s infinite;
  animation-delay: 0.4s;
}

.loading.bounceAnimation .dot:nth-child(3) {
  animation: delayed-blink 0.8s infinite;
  animation-delay: 0.6s;
}

.text {
  color: var(--blue);
}

@keyframes forward-motion {
  25% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fast-blink {
  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes delayed-blink {
  25% {
    transform: scale(1);
    opacity: 1;
  }
  0%,
  50%,
  100% {
    opacity: 0.4;
    transform: scale(0.4);
  }
}

.imageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & svg {
    width: 250px;
    height: 300px;
  }
}
