@import '_scss-variables.scss';

.personalGoals {
  display: flex;
  flex-direction: column;
  gap: var(--offset-x-lg);

  width: 100%;
  max-width: 600px;
}

.label {
  color: var(--new-blue8);
  text-transform: initial;
}

.title {
  color: var(--new-blue8);
  text-transform: initial;
}

.redText {
  color: #d03673;
}

.content {
  white-space: normal;
}

.scrollableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;
  gap: var(--offset-md);

  width: 100%;

  overflow-y: auto;

  height: calc(
    100vh - var(--top-bar-height) - 48px - 24px - 24px
  ); // 48px is the height of the bottom button, 24px is the padding around the button
}

.submitButton {
  width: 327px;
  max-width: 600px;
  background-color: var(--pink-2);
  cursor: pointer;

  &:hover {
    background-color: var(--pink-2);
  }
}

.icon {
  position: absolute;
  right: 16px;
  right: var(--offset-x-sm);
  margin-top: -10px;
}

@media screen and (max-width: $min-mobile-screen) {
  .submitButton {
    width: 100%;
  }
}

.formControl {
  width: 100%;
}

.bottomButton {
  position: fixed;
  bottom: var(--offset-sm);

  display: flex;
  flex-direction: column;
  align-items: center;
}
