.wrapper {
  display: flex;
  flex-direction: column;

  .subTitle {
    text-align: center;
    margin-top: var(--offset-md);
  }

  .matchingStudents {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: var(--offset-x-lg);

    .syncIcon {
      color: var(--new-blue3);
      width: 40px;
      height: 40px;

      animation: spin 1000ms infinite;
      animation-timing-function: linear;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(-359deg);
        }
      }
    }

    .matchingHeader {
      color: var(--new-blue4);
      margin-left: 15px;
    }
  }
}

.confetti {
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;

  pointer-events: none;

  width: 100%;
  height: 100%;
}
