.container {
  padding: var(--offset-x-sm) var(--offset-sm);
}

.headerLimiter {
  display: grid;
  grid-template-columns: [start] repeat(auto-fill, 330px) [end];
  max-width: 1200px;
  grid-gap: var(--offset-xx-sm);
}

.labelContainer {
  grid-column: start / end;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamsLabelContainer {
  margin-top: var(--offset-sm);
}

.teamsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  grid-gap: var(--offset-xx-sm);
  margin-top: var(--offset-x-sm);
}

/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .headerLimiter {
    grid-template-columns: [start] repeat(auto-fill, 320px);
  }
  .teamsContainer {
    grid-template-columns: repeat(auto-fill, 320px);
  }
}

.noResultsContainer {
  padding: var(--offset-x-sm);
  margin-top: var(--offset-sm);
  max-width: 1010px;
}

.addButton > button + button {
  width: 200px;
}
