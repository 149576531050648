.accordionComponentSummary {
  &::before {
    content: '';
    left: -30px;
    position: absolute;
    width: 30px;
    height: 19px;
    top: 15px;
  }
  &::before {
    background-image: url('./assets/expand.svg');
  }
  &.expanded::before {
    background-image: url('./assets/collapse.svg');
  }
}

.accordionComponent .accordionDetails {
  overflow-x: scroll;
  padding-left: 20px;
  padding-right: 20px;
}

.dialogData {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 4px;
}

.accordionSummaryContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
