.zoomControlls {
  background-color: rgba(243, 243, 243, 0.5);
}

.zoomOutIcon {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 50px;
  height: 50px;
  background-color: rgba(243, 243, 243, 0.5);
  border-right: 0;
}

.zoomInIcon {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 50px;
  height: 50px;
  background-color: rgba(243, 243, 243, 0.5);
}

/* instead of writing !important, make the priority bigger by specifying the rule better */
.percentage.percentage.percentage {
  border-right: 0;
}
