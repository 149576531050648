.paper {
  margin: 20px auto;
  width: 500px;
  padding: 30px 40px 40px 40px;
  border-radius: 5px;
  border: 1px solid var(--new-grey5);
  box-shadow: none;
}

.subheader {
  margin-top: 10px;
  margin-bottom: 30px;
}

.option {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.searchContainer {
  position: relative;
}

.searchBarLoading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.searchBarLoadingColor {
  background-color: rgba(0, 0, 0, 0.1);
}

.searchBarBarLoadingColor {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
}
