.section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  text-decoration: none;
  background-color: var(--white);

  width: 100%;
  height: 52px;
  max-width: 720px;
  border: 1px solid var(--new-grey5);

  padding: 0 var(--offset-sm);

  transition: background-color 0.3s linear;

  &:first-of-type {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:last-of-type {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &:hover {
    background-color: var(--new-grey7);
  }

  & + .section {
    border-top: 0;
  }

  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .submissionCount {
    display: flex;
    align-items: center;

    white-space: nowrap;

    margin-left: var(--offset-md);

    .arrowRight {
      margin-left: var(--offset-md);
    }
  }
}
