@import '_scss-variables.scss';

.grid {
  display: grid;
  grid-gap: var(--offset-sm);
  grid-template-columns: repeat(4, 280px);

  padding: var(--offset-sm) var(--offset-sm) 0px;

  @media screen and (max-width: $max-tablet-screen) {
    grid-template-columns: repeat(4, 1fr);
  }
}
