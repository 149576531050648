.scrollableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;

  width: 100%;

  overflow-y: auto;

  height: calc(
    100vh - var(--top-bar-height) - 64px
  ); //  64px is the height of the bottom navigation
}

.progressContent {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 600px;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: var(--offset-x-sm);
  gap: var(--offset-x-sm);
  max-width: 600px;
}
