.dialog {
  width: 480px;
  padding: var(--offset-lg) var(--offset-x-lg) var(--offset-x-lg)
    var(--offset-x-lg);
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
}

.formWrapper {
  margin-top: 20px;
}

.urlInput {
  margin-bottom: 20px;
}

.searchIcon {
  margin-right: var(--offset-xx-sm);
}
