.pageWrapper {
  height: 100vh;

  background-color: var(--lighter-grey);

  /* TODO: Use material */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation {
  width: 100%;
  height: 3rem;

  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
}

.top.navigation {
  top: 0;
  background-color: var(--dark-blue);
}

.backIcon {
  color: var(--white-grey);
}

.backButton {
  position: absolute !important;
  left: 0;
}

.title {
  flex: 1;
}

.topNavContent {
  width: 100%;
  height: 100%;
  padding: 0.75rem 0;

  display: flex;
  align-items: center;

  text-align: center;

  background-color: var(--dark-blue);
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  font-weight: bold;

  position: relative;
}

.contentWrapper {
  overflow-y: auto;

  flex: 1;

  display: flex;
  justify-content: center;

  max-width: 768px;
  width: 100%;

  outline: 1px solid black;
}

@media (min-width: 768px) {
  /* Tablets and desktop */
  .topNavContent {
    width: 768px;
  }
}
