.scoreBox {
  margin: 0;
  padding: 0;
  border-radius: 5px;
  min-width: 110px;
  box-shadow: 0px 0px 5px var(--new-grey3);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.arrow {
  font-size: 20px;
  color: var(--white);
  top: 3px;
}
.scorePopper {
  pointer-events: all;
}

.optionsList {
  padding: 0;
}
.scoreCheckGradeItem {
  padding: var(--offset-xxx-sm) var(--offset-xx-sm);
}
.scoreCheckGradeItem:hover > * {
  color: var(--new-blue1) !important; /* Override typography color */
}
.scoreCheckGradeItem > svg {
  margin-right: var(--offset-xxx-sm);
}
.scoreCheckGradeItem:not(:last-child) {
  border-bottom: 1px solid var(--new-grey6);
}

.rubricOptionsList {
  padding: 0 var(--offset-xx-sm);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.rubricOption {
  background-color: var(--new-grey5);
  padding: var(--offset-xxx-sm) var(--offset-xx-sm);
  border-radius: 2px;
  margin: var(--offset-xx-sm) var(--offset-xxx-sm);
}

.rubricOption:hover {
  background-color: var(--new-green4);
}
.rubricOption:hover > * {
  color: var(--new-blue1) !important;
}

.scoreTextNumber {
  margin: 0 auto;
}

.numberInput {
  margin: var(--offset-xx-sm);
  width: 110px;
}

.numberInput input {
  text-align: center;
}
