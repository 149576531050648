@import '_scss-variables.scss';

.selectAssignmentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: var(--offset-xx-lg);

  .title {
    margin-bottom: var(--offset-lg);
  }

  .selectionRowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search {
    width: 330px;
    height: 40px;
    margin-right: 15px;
    background-color: #c1eacb;
    color: #4d4d4d;
  }

  .search:hover {
    background-color: #c1eacb;
  }
  .search::placeholder {
    color: #4d4d4d;
  }
  .search:focus {
    background-color: #c1eacb;
  }

  .divider {
    position: relative;

    color: var(--new-grey5);

    margin: var(--offset-md) 0;
  }

  .buttonGroup {
    width: 330px;
    height: 40px;
    margin-left: 15px;

    .addIconButton {
      background-color: #555893;
      color: var(--white);
    }

    .createButton {
      width: 100%;
      background-color: #7477b8;
    }

    .label {
      color: var(--white);
    }
  }

  .recentAssignments {
    margin-top: var(--offset-lg);

    .assignmentItem {
      display: flex;
      align-items: center;

      padding: var(--offset-xxx-sm) var(--offset-xx-sm);
      margin-top: var(--offset-xx-sm);

      width: 330px;
      min-height: 32px;

      background: var(--new-grey6);
      border-radius: 5px;

      cursor: pointer;
      transition: background-color 0.3s linear;

      &:hover {
        background-color: var(--new-grey5);
      }
    }
  }
}
