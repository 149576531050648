.container {
  display: flex;
  align-items: center;

  .element {
    height: 18px;
    min-width: 18px;
    padding: 0 1px;
    border-radius: 9px;
    line-height: 18px;
    cursor: pointer;
    text-align: center;

    &:hover:not(.selected) {
      background-color: var(--new-grey6);
    }

    + .element {
      margin-left: 5px;
    }

    &.selected {
      background-color: var(--new-blue3);
      color: var(--white);
    }
  }

  .leftArrow {
    margin-right: 5px;
    cursor: pointer;
  }

  .rightArrow {
    transform: rotate(180deg);
    margin-left: 5px;
    cursor: pointer;
  }

  .disabled {
    cursor: not-allowed;
  }
}
