.container {
  width: 100%;
  max-width: 1200px;
  padding: 32px;
}

.wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 24px;
}

.cardsGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
}

.card {
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #d0d0d0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cardTitle {
  font-size: 16px;
  font-weight: bold;
  color: #292e52;
  margin-bottom: 16px;
  text-align: center;
}

.iconWrapper {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 90px;
  height: 90px;
  color: #818cf8;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardDescription {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: center;
  flex-grow: 1;
}

.buttonBase {
  width: 100%;
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.buttonPrimary {
  composes: buttonBase;
  background: #ec4899;
  color: white;
  margin-bottom: 10px;

  &:hover {
    background: #db2777;
  }
}

.buttonSecondary {
  composes: buttonBase;
  background: #bdc0ff;
  margin-bottom: 10px;
  color: #292e52;

  &:hover {
    background: #c7d2fe;
  }
}

.searchWrapper {
  width: 100%;
  margin-bottom: 10px;

  :global {
    .MuiAutocomplete-root {
      width: 100%;
    }

    .MuiInputBase-root {
      background-color: white;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #bcbdbe;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiInputBase-input {
      font-size: 14px;
      color: #374151;

      &::placeholder {
        color: #9ca3af;
      }
    }
  }
}

.oldFlowWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
}
