.dialog {
  width: 480px;
  padding: var(--offset-lg) var(--offset-x-lg) var(--offset-x-lg)
    var(--offset-x-lg);

  .label {
    padding-bottom: var(--offset-md);
  }

  .button {
    margin-top: var(--offset-lg);
    width: 100%;
  }

  .caution {
    margin-top: var(--offset-lg);
  }

  .permissionsInfo {
    margin-top: var(--offset-xx-sm);
  }
}
