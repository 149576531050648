@import '_scss-variables.scss';

.appBar {
  height: var(--top-bar-height);
  width: calc(100% - var(--side-nav-width-small));
  border: none;
  box-shadow: 0 1px 0 0 var(--new-grey5);
  background-color: var(--white);
}
.appBar.small {
  width: calc(100% - var(--side-nav-width-big));
}
.appBar.fullWidth {
  width: 100%;
}
@media only print {
  .appBar {
    width: 100%;
  }
}
.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.contentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: hidden;
}
.contentWrapper > * {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--offset-sm);
  box-shadow: 1px 0 0 0 var(--new-grey5); /* Border right */
  flex: 1;
  overflow-x: hidden;
}
.contentWrapper > *:last-child {
  box-shadow: none;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--offset-xx-sm);
  flex: 100px;
  height: 100%;
}

.sideNavButtonWrapper {
  width: 60px;
  box-shadow: 1px 0 0 0 var(--new-grey5); /* Border right */
  display: flex;
  align-items: center;
  justify-content: center;
}
.sideNavButton {
  padding: 0;
}

.noPaddingLeft {
  padding: 0 var(--offset-sm) 0 0;
}

.backArrow {
  padding: var(--offset-sm);

  @media screen and (max-width: $max-tablet-screen) {
    padding: 12px;
  }
}
