.sortCardContainer {
  .cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 50px;

    background-color: var(--new-blue3);
    padding: var(--offset-xx-sm) var(--offset-sm);

    .editIcon {
      visibility: hidden;
      opacity: 0;

      transition: opacity 0.3s linear;
    }
  }

  &:hover {
    .editIcon {
      visibility: visible;
      opacity: 1;
    }
  }
}
