.chip {
  display: flex;
  border-radius: 5px;
  align-items: center;
  background-color: var(--new-grey6);
  margin: var(--offset-xxx-sm) 0px;
  margin-right: var(--offset-sm);
}

.attendeeChip {
  max-width: 180px;
  height: 24px;
  padding: 6px 10px;
}

.attendeeChipText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
