@import '_scss-variables.scss';

.pathwaysStart {
  display: flex;
  justify-content: center;
  background-color: white;
  height: calc(100vh - var(--top-bar-height));
}
.text {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profileSetupTitle {
  width: 100%;
  color: var(--new-blue1);
  font-weight: 600;
  text-align: center;
  padding-left: var(--offset-x-lg);

  @media screen and (min-width: $min-desktop-screen) {
    padding-left: 100px;
  }
}
