.container {
  width: 100%;
  max-width: 568px;

  border-radius: 5px;
  border: 1px solid var(--new-grey5);
  background-color: var(--new-grey7);

  display: flex;
  align-items: center;

  white-space: nowrap;
  padding: var(--offset-xx-sm) var(--offset-sm);

  .spinner {
    min-width: 32px;
    height: 32px;
    margin-right: var(--offset-xx-sm);

    border-radius: 50%;
    border: 6px solid var(--new-blue5);
    border-bottom-color: var(--new-blue4);

    animation: spin 0.6s linear infinite;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(359deg);
      }
    }
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--new-blue3);
  }

  .size {
    color: var(--new-grey3);
    margin-left: auto;
  }
}
