.container {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr 1.2fr 0.3fr;

  align-items: center;

  margin: var(--offset-sm);
  padding: var(--offset-x-sm) var(--offset-x-sm);

  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  background-color: #ffffff;

  cursor: pointer;

  &:hover {
    background-color: var(--new-grey7);
  }

  min-height: 150px;

  .progress {
    > div {
      justify-content: initial;
    }
  }
}

.standardsList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: var(--offset-xx-sm);
}
