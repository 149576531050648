.exportButton {
  width: 79px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: var(--new-grey3);
  }
}
