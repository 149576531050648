.selectContainer {
  width: 120px;
}

.selectFilter {
  height: 22px;
}

.root.root > div {
  background-color: var(--new-grey7);
  border-radius: 2px 2px 1px 1px;
}
