.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #fff;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .familyLogo {
    width: 200px;
    height: 200px;
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .icon {
    font-size: 30px;
  }
}

.instruction {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #333;
}

.studentList {
  width: 100%;
  margin-bottom: 20px;
}

.studentItem {
  width: 100%;
  background-color: #8c88c2;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &.selected {
    background-color: #4439cc;
  }

  .studentName {
    flex-grow: 1;
    font-size: 15px;
    color: #fff;
  }
}

.helperText {
  text-align: center;
  margin: 20px auto;
  font-size: 14px;
  font-weight: bold;
  color: #666;
  line-height: 1.5;
  max-width: 80%;
}

.continueButton {
  width: 100%;
  background-color: #ff4081;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
}
