.container {
  padding: var(--offset-x-sm) var(--offset-sm);
}

.paper {
  height: 285px;
}
.paperHeader {
  padding-left: var(--offset-lg);
}
.paperContent {
  padding: var(--offset-lg);
  height: 235px;
}

.invoicesTableContainer {
  margin-top: var(--offset-x-sm);
}
.headerCell {
  background-color: var(--white);
}

.quickGrid {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: var(--offset-x-sm);
  column-gap: var(--offset-x-sm);
}

.nextPayment > p:not(:last-child) {
  margin-bottom: var(--offset-md);
}
