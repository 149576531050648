.item {
  padding: 0 var(--offset-x-sm);

  height: 73px;

  display: grid;
  align-items: center;
  grid-gap: var(--offset-xx-sm);
  grid-template-columns: 1fr 14px;

  color: var(--new-blue2);

  border-bottom: 1px solid var(--new-grey5);

  &.withIcon {
    grid-template-columns: 24px 1fr 14px;
  }

  .arrowRight {
    color: var(--new-grey4);
  }
}
