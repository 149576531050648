.menuPaper {
  border-radius: 5px;
}
.menuList {
  padding: 0;
}
.menuList > li {
  padding: 10px 20px;
}
.item {
  color: var(--new-grey2);
}
.itemSelected {
  color: var(--new-grey1);
}

.optionContainer {
  display: flex;
  align-items: center;

  > svg {
    margin-right: 5px;
  }
}

.arrowDownIcon {
  top: auto; // Override the default top coming from MUI
}
