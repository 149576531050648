.task {
  border-bottom: 1px solid var(--new-blue3);
  padding: var(--offset-xx-sm) 0 var(--offset-sm) var(--offset-x-sm);
}
@media (min-width: 768px) {
  .task {
    padding: var(--offset-xx-sm) var(--offset-sm) var(--offset-lg)
      var(--offset-lg);
  }
}

.taskName {
  padding-bottom: var(--offset-xx-sm);
}
@media (min-width: 768px) {
  .taskName {
    padding-bottom: var(--offset-x-sm);
  }
}

.criteria svg {
  margin-right: 10px;
}
.criteriaItem + .criteriaItem {
  padding-top: 3px;
}

.taskInfo {
  padding-bottom: var(--offset-xx-sm);
}
@media (min-width: 768px) {
  .taskInfo {
    padding-left: var(--offset-sm);
    padding-bottom: var(--offset-sm);
  }
}
.taskInfo:last-child {
  padding-bottom: 0;
}

.criteriaCheckIcon {
  width: 20px;
  height: 20px;
}

.numberObjective {
  background-color: var(--new-blue5);
  border-radius: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lightTask {
  background-color: var(--new-grey7);
  padding: var(--offset-sm) var(--offset-x-sm) 0 var(--offset-x-sm);
}
@media (min-width: 768px) {
  .lightTask {
    padding: var(--offset-lg) var(--offset-lg) var(--offset-lg)
      var(--offset-xx-sm);
  }
}

.squareIconContainer {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.roundIconContainer {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--new-blue3);
  padding: 2px;
}
