.itemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-blue);
  gap: var(--offset-xx-sm);
  color: var(--white);
  height: 52px;
}

.dots {
  width: 20px;
  height: 20px;
  color: var(--white);
  margin-right: var(--offset-xx-sm);
}

.spacer {
  flex-grow: 1;
}

.displayText {
  padding-left: var(--offset-xx-sm);
  display: block;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
