@import '_scss-variables.scss';

.dialogText {
  color: #535c9f;
}

.dialogStyles {
  padding: 40px;
  background-color: white;

  @media screen and (max-width: $max-mobile-screen),
    screen and (max-height: $max-mobile-screen) {
    div[class*='closeButton'] {
      color: #dfdfdf;
      background: none;
      top: 0px;
      right: 0px;
    }
  }

  .tooltipText {
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'left';
    align-items: 'left';
    gap: '10px';
  }

  .dialogData {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 4px;
  }

  & .rubricListContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
