@import '_scss-variables.scss';

.studentMenu {
  font-size: small;
}

.studentMenuName {
  margin-left: 8px;
}

.studentMenuAvatar {
  height: 30px;
  width: 30px;
  font-size: small;
  color: white;
}
