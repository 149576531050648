@import '_scss-variables.scss';

.summaryWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: $max-tablet-screen) {
    flex-direction: row;
  }
}
.titleWrapper {
  display: flex;
  flex-direction: column;
}
.buttonsWrapper {
  display: flex;
}
.blueButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.blueButtonsWrapper > * {
  margin-right: var(--offset-lg);

  @media screen and (max-width: $max-tablet-screen) {
    margin-right: var(--offset-sm);
  }
}
.blueButtonsWrapper > *:last-child {
  margin-right: var(--offset-x-lg);
}

.iconButtonsWrapper {
  display: flex;
  align-items: center;
}
.iconButtonsWrapper > button {
  margin-right: var(--offset-sm);
}
.iconButtonsWrapper > button:last-child {
  margin-right: 0;
}

.standardsList {
  margin-top: var(--offset-md);

  @media screen and (max-width: $max-tablet-screen) {
    margin-top: var(--offset-sm);
  }
}

.dataButtonIsActive {
  background-color: var(--new-blue5);
}
