.commentsList {
  padding: 0 var(--offset-xx-sm);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 160px;
  overflow-x: auto;
}
.comment {
  border-radius: 5px;
  margin-bottom: 4px;
  padding: 0 var(--offset-xx-sm);
}
.comment.studentComment {
  background-color: var(--new-grey7);
}
.commentsList > *:last-child {
  margin-bottom: var(--offset-xx-sm);
}

.inputWrapper {
  width: 100%;
  padding: 0 var(--offset-xx-sm) var(--offset-xx-sm) var(--offset-xx-sm);
}
.inputField {
  height: 36px;
}

.closeIconWrapper {
  height: 20px;
  width: 100%;
  padding: 4px 4px 0 0;

  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  display: flex;
  align-items: center;
  border-radius: 50%;
  background: var(--new-grey5);
  padding: 1px;
  cursor: pointer;
}
.closeIcon > svg {
  width: 14px;
  height: 14px;
}

.commentsTooltip {
  padding: 0px;
  background-color: var(--white);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  margin-top: 0;
  pointer-events: all;
}

.tooltipArrow::before {
  background-color: var(--white);
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
}
