@import '_scss-variables.scss';

.swiperContainer {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}

.swiperWrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.swiperSlide {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--offset-md) var(--offset-md);
  text-align: center;
  flex-grow: 1;
  height: calc(100vh - var(--top-bar-height));
  @media screen and (min-width: $max-mobile-screen) {
    gap: var(--offset-lg);
    justify-content: flex-start;
  }
}
.textWrapper {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  pointer-events: none;

  min-height: 100px;
  @media screen and (min-width: $max-mobile-screen) {
    width: 600px;
  }
}

.carouselImage {
  width: 100%;
  height: auto;
  max-width: 280px;
  margin-bottom: 25px;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  pointer-events: none;
}

.carouselTitle {
  color: var(--new-blue3);
  margin-bottom: var(--offset-xx-sm);
  text-transform: capitalize;

  @media screen and (min-width: $min-desktop-screen) {
    font-size: 22px;
  }
  @media screen and (min-width: $min-tablet-screen) {
    font-size: 20px;
  }
}

.carouselContent {
  font-weight: 600;
  color: var(--new-blue1);

  @media screen and (min-width: $min-desktop-screen) {
    font-size: 18px;
  }
  @media screen and (min-width: $min-tablet-screen) {
    font-size: 16px;
  }
}

.carouselButton {
  position: fixed;
  bottom: var(--offset-md);
  width: 90%;
  max-width: 600px;
}

.stepperRoot {
  background-color: inherit;
}

.customDot {
  width: 12px;
  height: 6px;
  margin: 0 2px;
  background-color: var(--pink-4);
  border-radius: 3px;
}

.dotActive {
  width: 36px;
}
