.commentSection {
  padding: 1rem 1.5rem 3rem 1.5rem;
}

.commentSection > .comments {
  display: flex;
  flex-direction: column;
}
.commentSection > .comments > .comment {
  margin-bottom: 1rem;
  font-size: 12px;
  line-height: 16px;

  padding-right: 4rem;
  padding-left: 0;
}

.commentSection > .comments > .comment.isStudent {
  padding-right: 0;
  padding-left: 4rem;
}
.commentSection > .comments > .comment:last-child {
  margin-bottom: 1.5rem;
}
.commentSection > .comments > .comment > .author {
  font-weight: bold;
  color: var(--darker-blue);
  padding-right: 5px;
}
.commentSection > .comments > .comment > .text {
  color: var(--dark-grey);
}

.commentSection > form .input {
  flex-grow: 1;
  background-color: var(--lighter-grey);
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 3px 1rem;
  font-size: 12px;
  line-height: 200%;
  color: var(--darker-blue);
}

.commentSection > form .input::placeholder,
.commentSection > form .input:-ms-input-placeholder,
.commentSection > form .input::-webkit-input-placeholder {
  letter-spacing: 1px;
  color: var(--grey-2);
}

.commentSection > form .submit {
  width: 20px;
  height: 20px;
  margin-left: 1rem;
}
