.cardElement {
  padding: 10px;
  height: 40px;

  border-radius: 5px 5px 1px 1px;
  border-bottom: 2px solid var(--grey-4);
}

.cardElement:hover {
  background-color: var(--light-grey);
  border-bottom: 2px solid var(--dark-grey);
}

.cardElement.active {
  background-color: var(--light-grey);
  border-bottom: 2px solid var(--darker-green);
}
