@import '_scss-variables.scss';
.dialog {
  .paperRoot {
    width: 100%;
    max-width: 80vw;
    max-height: 100vh;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--offset-sm);

  .notMatchedCount {
    display: flex;
  }

  .close {
    cursor: pointer;
    color: var(--new-grey1);
  }
}

.content {
  display: grid;
  grid-template-columns: 2.5fr 1fr;

  .imageContainer {
    position: relative;

    width: 100%;
    height: calc(100vh - 120px); // 120 header

    padding: var(--offset-x-sm) 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--new-grey7);

    @media screen and (max-width: $max-tablet-screen) {
      padding: 0 var(--offset-sm);
    }

    .image {
      height: 100%;

      @media screen and (max-width: $max-tablet-screen) {
        width: 100%;

        align-self: start;
      }
    }

    .leftImageArrow {
      position: absolute;
      top: 50%;
      left: 0;
      cursor: pointer;
    }

    .rightImageArrow {
      position: absolute;
      top: 50%;
      right: 0;
      cursor: pointer;
    }
  }

  .studentSearch {
    height: calc(100vh - 120px); // 120 header

    .pageSelectContainer {
      border-top: 1px solid var(--new-grey5);

      .pageSelect {
        padding: var(--offset-sm);
      }
    }

    .findStudentContainer {
      border-top: 1px solid var(--new-grey5);

      .findStudent {
        padding: var(--offset-sm);
      }
    }

    .selectStudent {
      border-top: 1px solid var(--new-grey5);

      .unmatchedText {
        padding: var(--offset-sm) var(--offset-sm) 0 var(--offset-sm);
        margin-bottom: var(--offset-x-sm);
      }

      .studentsList {
        overflow-y: scroll;
        height: calc(100vh - 550px);

        @media screen and (max-width: $max-tablet-screen) {
          height: calc(100vh - 570px);
        }

        .studentItem {
          cursor: pointer;

          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: var(--offset-xxx-sm) var(--offset-sm);
          min-height: 40px;

          transition: background-color 0.3s linear;
          border-bottom: 1px solid var(--new-grey5);

          &:hover {
            background-color: var(--new-grey7);
          }

          .studentName {
            color: var(--new-grey2);
          }
        }
      }
    }

    .buttonContainer {
      margin-top: var(--offset-x-lg);

      padding: 0 var(--offset-sm);

      .excludeButton {
        width: 100%;
        margin-top: var(--offset-md);
      }
    }
  }
}
