.dialog {
  width: 100%;
  max-width: 620px;

  padding: var(--offset-x-lg);

  .header {
    border-radius: inherit;
    padding-bottom: var(--offset-lg);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .button {
      width: 125px;

      margin-left: var(--offset-sm);
    }
  }

  .dialogContent {
    .recentAssignments {
      margin-top: var(--offset-lg);

      .assignmentItem {
        display: flex;
        align-items: center;

        padding: var(--offset-xxx-sm) var(--offset-xx-sm);
        margin-top: var(--offset-xx-sm);

        width: fit-content;

        max-width: 100%;
        min-height: 32px;

        background: var(--new-grey6);
        border-radius: 5px;

        cursor: pointer;
        transition: background-color 0.3s linear;

        &:hover {
          background-color: var(--new-grey5);
        }

        .buttonIcon {
          color: var(--new-blue3);
          margin-right: var(--offset-xxx-sm);
        }

        .assignmentName {
          max-width: 93%;
          overflow-wrap: break-word;
        }
      }
    }
  }
}
