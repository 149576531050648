.container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    color: var(--new-blue1);

    margin-top: var(--offset-lg);
    margin-bottom: var(--offset-md);
  }

  .uploadContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    padding-top: var(--offset-x-sm);

    .upload {
      width: 316px;
      height: 397px;

      padding: var(--offset-sm);
      margin: var(--offset-sm);

      align-items: center;
      display: flex;
      justify-content: center;

      background-color: #fbfbfb;
      border: 2px dashed var(--new-grey5);
      border-radius: 5px;

      cursor: pointer;

      .uploadInnerContainer {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .uploadIcon {
          margin-bottom: var(--offset-sm);
        }

        .uploadText {
          padding: var(--offset-xx-sm);
        }
        .checkText {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .checkIcon {
          color: #ef5a93;
        }
      }
    }

    .qrCode {
      width: 316px;
      height: 397px;

      padding: var(--offset-sm);
      margin: var(--offset-sm);

      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;

      background-color: #fbfbfb;
      border: 2px dashed var(--new-grey5);
      border-radius: 5px;

      .title {
        padding: var(--offset-sm);
      }

      .description {
        text-align: center;
        padding: var(--offset-sm);
      }
      .appStoreLinks {
        align-items: center;
        display: flex;
        justify-content: center;
      }
      .appStoreButton {
        margin: var(--offset-x-sm);
      }
    }
  }
}
