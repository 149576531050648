.container {
  margin-bottom: var(--offset-md);

  .transparentBackgroundInput {
    background-color: transparent;
  }
}

.inputRow {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: var(--offset-xx-sm) var(--offset-xx-sm);

  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--grey-4);

  &.withTopBorderRadius {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &.withBottomBorderRadius {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: var(--offset-sm);
    border-bottom-width: 1px;
  }

  .withLeftOffset {
    margin-left: var(--offset-xx-sm);
    margin-right: var(--offset-xx-sm);
  }
}

.recentSections {
  padding-bottom: var(--offset-lg);
}

.button {
  height: 32px;

  color: var(--new-blue3);
  background-color: var(--new-grey6);
  padding: var(--offset-xxx-sm) var(--offset-xx-sm);

  &:hover {
    background-color: var(--new-grey5);
  }
}

.buttonContent {
  display: flex;
  align-items: center;
  text-transform: none;
}

.buttonIcon {
  font-size: 24px;
  margin-right: var(--offset-xxx-sm);
}

.deleteContainer {
  display: flex;

  .delete {
    margin: 0 -16px;

    height: 25px;
    width: 25px;
    color: var(--new-grey3);

    &:hover {
      color: var(--new-grey1);
      cursor: pointer;
    }
  }
}
