/* SCREEN SIZE VARIABLES */
$min-mobile-screen: 320px;
$max-mobile-screen: 767px;

$min-tablet-screen: 768px;
$max-tablet-screen: 992px;

$min-small-desktop-screen: 993px;
$max-small-desktop-screen: 1279px;

$min-desktop-screen: 1280px;

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@mixin slide-in-from-right($duration: 1s, $timing-function: ease) {
  animation: slideInFromRight $duration $timing-function;
}
