@import '_scss-variables.scss';

.wrapper {
  top: 0;
  left: 0;
  position: fixed;

  width: 100vw;
  height: 100vh;

  overflow-x: auto;

  background-color: var(--white);

  .header {
    display: flex;
    align-items: center;

    padding: var(--offset-x-lg);

    .backButton {
      width: 114px;
      margin-right: var(--offset-x-lg);
    }

    .notMatchedCount {
      display: flex;
    }

    .close {
      cursor: pointer;
      color: var(--new-grey1);
    }
  }

  .content {
    display: flex;
    justify-content: space-between;

    .imageContainer {
      position: relative;

      width: 100%;
      height: calc(100vh - 120px); // 120 header

      padding: var(--offset-x-sm) 0;

      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--new-grey7);

      @media screen and (max-width: $max-tablet-screen) {
        padding: 0 var(--offset-sm);
      }

      .image {
        height: 100%;

        @media screen and (max-width: $max-tablet-screen) {
          width: 100%;
          height: unset;

          align-self: start;
        }
      }
    }

    .studentSearch {
      width: 252px;
      height: calc(100vh - 120px); // 120 header

      @media screen and (max-width: $max-tablet-screen) {
        width: 241px;
      }

      .pageSelectContainer {
        border-top: 1px solid var(--new-grey5);

        .pageSelect {
          padding: var(--offset-sm);
        }
      }

      .findStudentContainer {
        border-top: 1px solid var(--new-grey5);

        .findStudent {
          padding: var(--offset-sm);

          .search {
            width: 162px;
          }
        }
      }

      .selectStudent {
        border-top: 1px solid var(--new-grey5);

        .unmatchedText {
          padding: var(--offset-sm) var(--offset-sm) 0 var(--offset-sm);
          margin-bottom: var(--offset-x-sm);
        }

        .studentsList {
          overflow-y: scroll;
          height: calc(100vh - 550px);

          @media screen and (max-width: $max-tablet-screen) {
            height: calc(100vh - 570px);
          }

          .studentItem {
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: var(--offset-xxx-sm) var(--offset-sm);
            height: 40px;

            transition: background-color 0.3s linear;
            border-bottom: 1px solid var(--new-grey5);

            &:hover {
              background-color: var(--new-grey7);
            }

            .studentName {
              color: var(--new-grey2);
            }
          }
        }
      }

      .buttonContainer {
        margin-top: var(--offset-x-lg);

        padding: 0 var(--offset-sm);

        .excludeButton {
          width: 100%;
          margin-top: var(--offset-md);
        }
      }
    }
  }
}
