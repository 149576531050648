.studentJourneyContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--offset-sm);
  padding: var(--offset-sm);
  gap: var(--offset-sm);
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  color: var(--new-blue2);
}

.innerContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: var(--offset-sm);
}

.leftContainer {
  display: flex;
  padding: var(--offset-sm) var(--offset-sm) 0 0;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  gap: var(--offset-sm);
}
