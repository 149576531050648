.container {
  display: flex;
  flex-direction: column;
  gap: var(--offset-md);
}

.selectedSections {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sectionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background: #ccd6f8;
  border-radius: 8px;
  border: 1px solid var(--grey-4);
  transition: all 0.2s ease;
  margin-bottom: 10px;

  &:hover {
    border-color: var(--grey-3);
  }

  .sectionContent {
    display: grid;
    grid-template-columns: 250px 1fr 1fr;
    align-items: center;
    gap: 16px;
    flex: 1;

    .sectionName {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dateButton {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 6px 12px;
      color: var(--new-blue3);
      border-radius: 5px;
      border: 1px;
      cursor: pointer;
      font-size: 14px;
      justify-self: start;

      &:hover {
        border-radius: 4px;
        color: #ef4444;
        font-weight: 500;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .deleteButton {
    color: var(--grey-3);
    background: transparent;
    border: none;
    padding: 6px;
    cursor: pointer;

    &:hover {
      color: #ef4444;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.recentSections {
  margin-top: var(--offset-sm);
  border: 1px solid var(--grey-4);
  border-radius: 8px;
  padding: 16px;

  .recentTitle {
    color: var(--grey-3);
    margin-bottom: 12px;
  }

  .recentItem {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid var(--grey-4);
    background: white;
    margin-bottom: 8px;
    transition: all 0.2s ease;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background: #e2ebfb;
      border-color: var(--grey-3);
    }

    svg {
      color: var(--new-blue3);
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
  }
}

.searchInput {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--gray-200);
  border-radius: 0.5rem;
  font-size: 20px;
  transition: border-color 0.2s, box-shadow 0.2s;

  &:focus {
    border-color: var(--blue-500);
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  }
}
