@import '_scss-variables.scss';

.container {
  user-select: none;

  overflow: hidden;
  border-radius: 5px;
  background-color: var(--white);
  border: 1px solid var(--new-grey5);

  animation: expand 0.3s linear;

  @media screen and (max-width: $max-mobile-screen) {
    border-radius: unset;
    border-width: 0 0 1px 0;
  }

  @keyframes expand {
    0% {
      max-height: 0;
    }

    99% {
      max-height: 300px;
    }

    100% {
      max-height: unset;
    }
  }

  .actionRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 34px;

    padding: var(--offset-xx-sm) var(--offset-x-sm) 0 18px;

    .selectField {
      > svg {
        display: none;
      }

      &:after {
        border: none;
      }

      &:before {
        border: none;
      }
    }

    .selectFieldRoot {
      padding: var(--offset-xxx-sm);
      font-size: 13px;
      font-weight: 400;
      color: var(--new-grey1);

      &:hover {
        background-color: var(--new-grey6);
        border-radius: 5px;
      }
    }

    .taskButtons {
      display: flex;
      justify-content: flex-end;
    }

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  .bottomRow {
    padding: var(--offset-x-sm) var(--offset-xxx-sm) var(--offset-x-sm)
      var(--offset-sm);

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .selectWork {
    min-width: 57px;

    & > div {
      width: 100%;
      height: 24px;
    }
  }
}
