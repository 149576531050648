@import '_scss-variables.scss';

.card {
  height: 100%;
  cursor: pointer;

  .cardContent,
  .cardContent:last-child {
    /* Override default Mui card content styles */
    padding: var(--offset-md) var(--offset-sm) var(--offset-sm) var(--offset-lg);

    @media screen and (max-width: $max-tablet-screen) {
      padding: var(--offset-md);
    }
  }

  .valueWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 40px;

    .value {
      font-size: 48px;
      padding-right: var(--offset-xx-sm);
    }

    .icon {
      font-size: 40px;
    }
  }
}

.link {
  text-decoration: none;
}

.widgetTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 24px;

  margin-top: var(--offset-xx-sm);
}
