@import '_scss-variables.scss';

.nextStepsPanelContainer {
  margin-bottom: var(--offset-lg);
  margin-top: var(--offset-sm);
  padding: var(--offset-sm);
  gap: var(--offset-sm);
  width: 100%;
}

.header {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  color: #7477b8;
  padding-right: 3px;
}

.helpIcon {
  fill: #555893;
}
