.wrapper {
  display: flex;
  justify-content: center;

  margin-top: 70px;

  .container {
    width: 100%;
    max-width: 553px;
    position: relative;

    .image {
      max-height: 500px;
      max-width: 100%;
    }

    .text {
      width: 100%;

      text-align: center;

      top: 42px;
      position: absolute;
    }
  }
}
