.lessonListContainer {
  display: flex;
  flex-direction: column;
  padding: 0 var(--offset-x-sm);
  gap: var(--offset-md);
}

.lessonItem {
  display: flex;
  flex-direction: row;
  height: 72px;
  border-radius: 36px;
  padding: var(--offset-xx-sm);
  gap: 5px;
  cursor: pointer;

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    position: relative;
  }

  .middleContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;

    .itemTitle {
      color: #5a608d;
      text-transform: none;
    }

    .itemContent {
      overflow: hidden;
      max-height: var(--offset-lg);
    }
  }

  .arrowContainer {
    display: flex;
    max-width: 26px;
    padding: var(--offset-xx-sm);
    justify-content: center;
    align-items: center;
  }
}
