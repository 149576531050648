:root {
  /* NAVIGATIONS */
  --top-bar-height: 60px;
  --side-nav-width-big: 190px;
  --side-nav-width-small: 90px;
  --right-nav-width-big: 195px;
  --right-nav-width-small: 68px;

  /* OFFSETS */
  --offset-xxx-sm: 4px;
  --offset-xx-sm: 10px;
  --offset-x-sm: 16px;
  --offset-sm: 20px;
  --offset-md: 24px;
  --offset-lg: 30px;
  --offset-x-lg: 40px;
  --offset-xx-lg: 45px;
  --offset-xxx-lg: 50px;

  /* DIALOGS */
  --dialog-close-button-size: 45px;
}
