@import '_scss-variables.scss';

.container {
  user-select: none;

  overflow: hidden;
  border-radius: 5px;
  background-color: var(--white);
  border: 1px solid var(--new-grey5);

  position: relative;

  z-index: 3;

  @media screen and (max-width: $max-mobile-screen) {
    border-radius: unset;
    border-width: 0 0 1px 0;
  }

  .multiSelectCardOverlay {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;

    z-index: 2;
  }
  .actionRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: var(--offset-sm) var(--offset-sm) 0 var(--offset-sm);

    .icons {
      width: unset;
      align-items: unset;
      height: 24px;

      .iconRoot {
        padding: 0;

        &:hover {
          background-color: var(--white);
        }
      }

      .iconLabel {
        display: block;
      }
    }

    .messageIcon {
      cursor: pointer;
      color: var(--new-grey4);
      margin-left: 8px;
    }

    .selectField {
      > svg {
        display: none;
      }

      &:after {
        border: none;
      }

      &:before {
        border: none;
      }
    }

    .selectFieldRoot {
      padding: var(--offset-xxx-sm) 0 var(--offset-xxx-sm) var(--offset-xxx-sm);
      font-size: 13px;
      font-weight: 400;
      color: var(--new-grey1);

      min-width: 64px;

      &:hover {
        background-color: var(--new-grey6);
        border-radius: 5px;
      }
    }

    .taskButtons {
      display: flex;
      justify-content: flex-end;
    }

    svg {
      width: 24px;
    }
  }

  .name {
    display: flex;
    align-items: center;

    padding: var(--offset-sm);
  }

  .selectWork {
    width: 65px;

    > div {
      width: 100%;
      height: 24px;
    }
  }

  .taskInfoContainer {
    padding: var(--offset-sm);

    .spaceBetween {
      height: 24px;
      align-items: center;
      justify-content: space-between;
    }

    .criteria {
      padding-left: var(--offset-x-sm);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: var(--offset-xxx-sm);
    }

    .criteriaStatus {
      width: 32px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .taskScore {
      width: 32px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background: var(--new-grey6);
      padding: var(--offset-xxx-sm);
      color: var(--new-blue4);
    }

    .taskScore > svg {
      width: 16px;
      height: 16px;
    }
  }

  .comments {
    padding-bottom: var(--offset-sm);
  }
}

.selected > .multiSelectCardOverlay {
  box-shadow: inset 0px 0px 0px 5px var(--new-blue5);
}

.cardsAnimated {
  animation-name: pulse;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.03);
  }

  100% {
    transform: scale(1);
  }
}
