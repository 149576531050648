.loaderChooserContainer {
  text-align: center;
}

.button {
  width: 300px;
  margin-top: var(--offset-lg);
  background-color: white;
  box-sizing: border-box;
  border-radius: 2px;
}

.googleClassroomButton {
  border: 2px solid #23a566;
}

.cleverButton {
  border: 2px solid #4274f6;
}

.skipButton {
  width: 300px;
  margin-top: var(--offset-lg);
  color: var(--new-gray1);
}
