.container {
  padding-left: var(--offset-xx-sm);
  padding-right: var(--offset-xx-sm);
  padding-bottom: var(--offset-lg);

  .standardButton {
    margin-top: var(--offset-x-sm);
  }

  .materialsButton {
    margin-top: var(--offset-x-sm);
    margin-left: var(--offset-xx-sm);
  }
}

.name {
  margin-bottom: var(--offset-sm);

  &:placeholder-shown {
    background-color: white;

    transition: all 0.2s ease;
  }
}

.description {
  height: 100%;
}

.linearProgress {
  margin-top: var(--offset-x-sm);
}

.dialogHeader {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  display: grid;
  align-items: center;
  grid-gap: var(--offset-sm);
  grid-template-columns: 125px 1fr 125px;

  @media only screen and (max-width: 1023px) {
    grid-template-columns: 1fr 125px;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: unset;
  }
}

.stepperContainer {
  grid-column-start: 2;

  @media only screen and (max-width: 1023px) {
    grid-column-start: unset;
  }
}
