@import '_scss-variables.scss';

.table tr:last-child td {
  border-bottom: 0;
}

.headerCell {
  background-color: var(--white); /* Counteracts the MUI default color */
}

.headerCell:not(:first-child) {
  max-width: 110px;
  text-align: center;
}

.cell:first-child {
  width: 23%;
}

.cell:not(:first-child) {
  text-align: center;
}

.noUnderline {
  text-decoration: none;
}

.copyLinkText {
  padding-left: var(--offset-xxx-sm);
}

.tableContainer {
  background: var(--white);

  border: 1px solid var(--grey-1);
  border-radius: 5px;
  box-sizing: border-box;

  @media screen and (max-width: $max-tablet-screen) {
    border: 0;
  }
}
