.dialog {
  padding: var(--offset-lg) var(--offset-x-lg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createButton {
  width: 170px;
}

.sortTitleInput {
  margin-top: var(--offset-sm);
}

.meetingShareSelect {
  margin-top: var(--offset-sm);
  border: 1px solid var(--new-grey4);
  border-radius: 5px;
}

.selectAll {
  display: flex;
  align-items: center;
  background-color: var(--new-grey7);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
}
.selectableMeetingShare {
  display: flex;
  align-items: center;
}
.selectableMeetingShare:hover {
  cursor: pointer;
  background-color: var(--new-grey7);
}
.selectableMeetingShare.selected {
  background-color: var(--new-grey6);
}
