.row > *:not(:last-child) {
  border-right: 1px solid var(--grey-1);
}

.progressBar {
  height: 3px !important;
}

.labelContainer {
  max-width: 200px;
  background: var(--grey-1);
  border-radius: 10px;
  margin: 0 0 var(--offset-xx-sm) var(--offset-xx-sm);
}

.labelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.valueLabel {
  max-width: 65%;
  display: inline-block;

  font-weight: bold;
}

.percentageLabel {
  padding-left: var(--offset-xxx-sm);
}

.modalLabelContainer {
  max-width: 350px;
}

.modalLabel {
  border-radius: 5px;
  padding: var(--offset-xx-sm) var(--offset-x-sm);
  background-color: var(--blue);
}

.modalValueLabel {
  max-width: 70%;
}

.modalCheckValueLabel > svg {
  width: 15px;
  height: 15px;
  fill: white;
  margin-right: var(--offset-xxx-sm);
}
