.chip {
  display: flex;
  border-radius: 5px;
  align-items: center;
  background-color: var(--new-grey6);
  margin: var(--offset-xxx-sm) 0px;
  margin-right: var(--offset-sm);

  &:hover {
    background-color: var(--new-grey5);
  }
}

.agendaChip {
  max-width: 220px;
  height: 32px;
  padding: 6px 10px;
}

.agendaChipText {
  margin-left: var(--offset-xxx-sm);
  max-width: 172px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
