@import '_scss-variables.scss';

.container {
  @media screen and (max-width: $max-mobile-screen) {
    display: none;
  }
}

.item {
  color: var(--new-grey2);
}

.itemSelected {
  color: var(--new-grey1);
}

.optionContainer {
  display: flex;
  align-items: center;

  color: white;

  > svg {
    margin-right: 5px;
  }
}

.menuList {
  padding: 0;

  .optionContainer {
    color: unset;
  }

  li {
    padding: var(--offset-xx-sm) var(--offset-sm);
  }
}

.arrowDownIcon {
  color: white;
  top: auto; // Override the default top coming from MUI

  @media screen and (max-width: $max-mobile-screen) {
    display: none;
  }
}
