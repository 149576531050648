.loadingWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}
