@import '_scss-variables.scss';

.grid {
  display: grid;
  grid-gap: var(--offset-sm);
  grid-template-columns: repeat(3, 316px);

  @media screen and (max-width: $max-tablet-screen) {
    grid-template-columns: repeat(3, 1fr);
  }

  .card {
    height: 100%;
  }

  .cardContent,
  .cardContent:last-child {
    /* Override default Mui card content styles */
    padding: var(--offset-md) var(--offset-md) var(--offset-sm) var(--offset-lg);

    @media screen and (max-width: $max-tablet-screen) {
      padding: var(--offset-md);
    }
  }

  .widgetTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--offset-x-sm);

    &.extraOffset {
      margin-top: calc(var(--offset-x-sm) + var(--offset-sm));
    }
  }

  .syncButton {
    width: 100%;
    padding: 0;
  }
  .syncButtonLabel {
    justify-content: space-between;
  }
}
