.content {
  min-height: 100%;
  background-color: var(--new-blue1);
}

.assignmentInfoContainer {
  padding: var(--offset-lg) var(--offset-x-sm);
}

.assignmentInfo {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--offset-sm);
}

.cameraButtonIcon {
  height: 20px;
  width: 20px;
  padding-bottom: 4px;
}

.description {
  color: var(--white);
  padding-bottom: var(--offset-sm);
  white-space: pre-wrap;
  word-wrap: break-word;
}

.description a {
  color: var(--lighter-blue);
}

.attachment {
  display: flex;
  align-items: flex-end;

  padding-bottom: var(--offset-sm);
}

.attachment > svg {
  margin-right: var(--offset-xx-sm);
}

.tasksContainer {
  border-top: 1px solid var(--new-blue3);
}

.goButton {
  width: 200px;
}
@media (min-width: 768px) {
  .goButton {
    width: 250px;
  }
}

.phoneButton {
  width: 130px;
  margin-left: var(--offset-lg);
}
