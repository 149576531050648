.statusBadge {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  padding: 0 var(--offset-xx-sm);
  border-radius: 5px;
}

.statusBadge > .label {
  padding-left: var(--offset-xxx-sm);
}
.statusBadge > svg {
  width: 15px;
  height: 15px;
}

.tableWrapper {
  border-collapse: separate;
}

.leftBorderCell {
  border-right: 1px solid var(--grey-1);
}

.rightBorderCell {
  border-left: 1px solid var(--grey-1);
}

.fixedCell {
  /* Fixed cells are sticked to the top, left & right borders of the table */
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.headCell {
  padding: var(--offset-xx-sm);
}

.studentsCell {
  min-width: 150px;
}

.bodyCell {
  /* Remove the default bottom border of Material's TableCell */
  border-bottom: none;
}

.taskName {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
