.comparisonChartContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.chartWrapperOuterContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}

.chartTitleSection {
  position: relative;
}

.chartSubTitleSection {
  padding-left: var(--offset-sm);
}

.barSubTitle {
  font-size: 18px;
  color: var(--new-blue2);
}

.infoIcon {
  fill: var(--new-blue2);
  margin-left: var(--offset-xxx-sm);
  cursor: pointer;

  :hover {
    fill: var(--new-blue7);
  }
  > g {
    scale: 0.9;
  }
}

.chartSubTitleSectionContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.standardContainer {
  display: flex;
  flex-direction: row;
  gap: var(--offset-xxx-sm);
  margin-left: var(--offset-x-sm);
  color: var(--dark-grey);
}

.spacer {
  display: flex;
  flex-grow: 1;
}

.standardIcon {
  height: 18px;
}

.tooltipInfo {
  border-radius: 2px;
  background-color: var(--new-blue2);
  padding: var(--offset-sm);
}

.tooltipText {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  gap: var(--offset-xx-sm);

  & > span {
    font-weight: 600;
  }
}

.tooltipArrow {
  color: var(--new-blue2);
}

.loader {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
