.tabContainer {
  display: flex;
  gap: var(--offset-xxx-sm);
  margin: var(--offset-x-sm) 20% var(--offset-xxx-sm) 20%;

  .tab {
    flex-grow: 1;
    text-align: center;
    padding: var(--offset-xx-sm) 0;
    cursor: pointer;
    color: #949494;
    border-bottom: 3px solid #d9d9d9;

    .header {
      text-transform: none;
      font-weight: 600;
    }

    &:hover {
      background-color: #f0f0f0;
    }
    &.active {
      color: #f75d9a;
      border-bottom: 3px solid #f75d9a;
    }
  }
}
