@import '_scss-variables.scss';

.tabsWrapper {
  height: 66px;
  background-color: var(--white);

  display: grid;
  grid-template-columns: 94px 80px 125px;
  grid-gap: 30px;

  border: 1px solid var(--new-grey5);
  border-top: 0;
  box-sizing: border-box;
  border-radius: 0px 0px 5px 5px;

  padding: var(--offset-sm) var(--offset-sm) var(--offset-xx-sm)
    var(--offset-sm);

  color: var(--new-grey3);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;

  @media screen and (max-width: $max-tablet-screen) {
    border-right: 0;
    border-left: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tab {
    user-select: none;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      margin-right: 7px;
    }

    box-sizing: border-box;
    padding-bottom: var(--offset-xx-sm);

    cursor: pointer;
  }

  .activeTab {
    color: var(--pink-1);
    border-bottom: 1px solid var(--pink-1);
    box-sizing: border-box;
  }
}
