.dialog {
  width: 577px;
  padding: var(--offset-x-lg) var(--offset-x-lg) var(--offset-lg)
    var(--offset-x-lg);
}
.dialog.hidden {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.shareButton {
  width: 150px;
}

.container {
  margin-top: var(--offset-lg);
}

.label {
  margin-top: var(--offset-sm);
}

.selectOrCreateContainer {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 0.5fr 1fr;
}
.createNewButton {
  width: 150px;
  height: 100%;
}

.notes {
  height: 100%;
  margin-top: var(--offset-x-lg);
}
.textarea {
  /* Imitating Typography B-Text-2 */
  font-size: 15px;
  font-weight: 400;
}

.studentCommentsContainer > * + * {
  margin-top: var(--offset-x-sm);
}

.studentCommentRow {
  display: grid;
  grid-template-columns: 0.6fr 1.4fr;
  grid-gap: var(--offset-x-sm);
  align-items: center;
}

.linearProgress {
  margin-top: var(--offset-x-sm);
  margin-bottom: calc(-1 * var(--offset-x-sm));
}

.meetingSelectPaper {
  max-width: 380px;
}
