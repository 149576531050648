.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  padding-bottom: 40px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.paper {
  margin-top: 100px;
  height: fit-content;
  width: 688px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid var(--new-grey5);
  padding: 60px 100px 70px 100px;
}
.paper.smallerPaper {
  width: 560px;
}

.logoBig {
  display: block;
  width: 180px;
  margin: 0px auto 50px auto;
}
.logoSmall {
  position: absolute;
  left: 50px;
  top: 30px;
  width: 100px;
}

@media only screen and (max-width: 670px) {
  .paper {
    width: 100%;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    padding: 30px;
    margin-top: 0;
    background: transparent;
  }
  .paper.smallerPaper {
    width: 100%;
  }
}
