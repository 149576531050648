.cardContent {
  padding: 0;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px 8px 8px;
  border-bottom: 1px solid var(--new-grey5);
}

.actionButtonsContainer {
  display: flex;
  align-items: center;
}

.taskInfoContainer {
  padding: 20px;
}

.taskNameAndScore {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crietria {
  padding-left: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.criteriaStatus {
  width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.taskScore {
  width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--new-grey6);
  border-radius: 5px;
  padding: 4px;
  color: var(--new-blue4);
}

.taskScore > svg {
  width: 16px;
  height: 16px;
}
