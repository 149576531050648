.tooltip {
  background-color: var(--new-blue1);
  max-width: 100%;
  padding: 0;
}
.popper {
  pointer-events: all;
  position: fixed !important;
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.tab {
  display: flex;
  background-color: var(--new-blue1);
  padding: 20px;
  cursor: pointer;
  box-sizing: border-box;
}
.tab:last-child {
  border-left: 1px solid var(--new-blue3);
  border-radius: 0 5px 5px 0;
}
.tab:first-child {
  border-radius: 5px 0 0 5px;
}
.tab.active {
  background-color: var(--new-blue2);
  box-shadow: inset 0 -3px 0 var(--pink-2);
}

.tabIcon {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
.tabIcon > * {
  fill: white;
}
.tabIconDisabled > * {
  fill: var(--new-blue5);
}

.tab:hover {
  opacity: 0.85;
  transition: opacity 0.2s;
}
