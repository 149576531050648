.workCardDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.studentNameText {
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #292e52;
}

.infoIcon {
  width: 26px;
  height: 26px;
  visibility: visible;
  fill: #333966;
  cursor: pointer;

  :hover {
    fill: #535c9f;
  }
  > g {
    scale: 0.9;
  }
}

.tooltipInfo {
  background-color: var(--new-blue2);
  max-width: 326px;
  padding: 25px 20px;
}

.tooltipText {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  gap: 10px;

  & > span {
    font-weight: 600;
  }
}

.tooltipArrow {
  color: var(--new-blue2);
}

.dialogText {
  display: flex;
  flex-direction: row;
  gap: 3px;
}
