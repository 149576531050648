.dialogHeader {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  display: grid;
  align-items: center;
  grid-gap: var(--offset-sm);
  grid-template-columns: 125px 1fr 125px;
}

.dialogContent {
  overflow-y: unset;
}

.navTabs {
  display: flex;
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.navTabs .tab {
  flex: 1;
  font-size: 11px;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.5px;
  position: relative;
  text-align: center;
  margin-left: 5px;
  color: #e11d48;
}

.navTabs .tab:first-child {
  margin-left: 15px;
}

.navTabs .tab:last-child {
  margin-right: 15px;
}

.navTabs .tab::before {
  content: '';
  position: absolute;
  top: -14px;
  left: 0;
  width: calc(100% - 3px);
  height: 4px;
  background-color: #e11d48;
}

.navTabs .tab:first-child::before {
  background-color: #e11d48;
  width: calc(100% - 3px);
}

.navTabs .tab:nth-child(2)::before {
  background-color: #e11d48;
  width: calc(100% - 3px);
}

.navTabs .tab:last-child::before {
  background-color: #e11d48;
  width: calc(100% - 3px);
}

.navTabs .tab.active {
  color: #e11d48;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.backButton {
  background-color: #b2c1f4;
  color: #020f29;
  margin-left: 30px;
  border-radius: 6px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
  font-family: 'Nunito', sans-serif;
}

.nextButton {
  background-color: #e31b5e;
  color: white;
  border-radius: 6px;
  margin-right: 30px;
  padding: 10px 16px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s;
  font-family: 'Nunito', sans-serif;
}

.backButton:hover {
  background-color: #9eb1f3;
}

.nextButton:hover {
  background-color: #be185d;
}

.nextButton:disabled {
  opacity: 1;
  cursor: not-allowed;
}
