.progressContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--offset-lg);
  gap: var(--offset-x-sm);
}

.gaugeContainer {
  width: 200px;
}

.text {
  text-transform: none;
}

.percentageText {
  left: 50%;
  top: 65%;
  width: auto;
  transform: translateX(-50%);
  position: absolute;
  font-size: 35px;
  font-weight: 700;
}
