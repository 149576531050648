.dialogPaper {
  width: 420px;
  padding: var(--offset-x-lg) 60px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: var(--offset-lg);
}
.gif {
  margin-bottom: var(--offset-lg);
  width: 300px;
  height: 200px;
}
.qrCode {
  margin-bottom: var(--offset-lg);
}
.copyButton {
  margin-bottom: var(--offset-sm);
}
.copyButtonIcon {
  font-size: 14px;
}
