.studentsTasksContainer {
  overflow: auto;
  margin: 0 var(--offset-sm);
}

.studentRow {
  display: flex;
  flex-wrap: nowrap;

  align-items: flex-start;
}

.studentRow > * {
  width: 330px;
  flex: 330px 0;
  flex-shrink: 0;
}

.studentRow > * + * {
  margin-left: 10px;
}

.studentRow + .studentRow {
  margin-top: 20px;
}
.studentRow:last-child {
  margin-bottom: var(--offset-xx-lg);
}
