.inputContainer {
  width: 480px;
  margin: var(--offset-lg) auto 0 auto;
  text-align: left;

  @media only screen and (max-width: 670px) {
    width: 100%;
  }

  .inputLabel {
    margin-bottom: var(--offset-xx-sm);
  }

  .textField {
    .icon {
      opacity: 0.5;
    }

    input:focus + * .icon {
      opacity: 1;
    }
  }
}

.submitButton {
  margin-top: var(--offset-x-lg);
}

.differentSchoolButton {
  margin-top: var(--offset-x-lg);
  color: var(--new-gray1);
}
