@import '_scss-variables.scss';

.outerContainer {
  background-color: rgba(255, 255, 255, 0.35);
  border-top: 1px solid #ededed;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  padding: var(--offset-xx-sm);
  backdrop-filter: blur(10px);
}

.inputContainer {
  position: relative;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  @media screen and (min-width: $min-desktop-screen) {
    padding-left: var(--side-nav-width-small);
    max-width: 500px;
  }
}

.input {
  background: #f6f5ff;
  min-height: 56px;
  height: auto;
  padding-right: var(--offset-xxx-lg);
}

.icon {
  position: absolute;
  right: var(--offset-xx-sm);
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.disabledIcon {
  opacity: 0.5;
  cursor: not-allowed;
}
