.container {
  height: 24px;
  width: 90px;

  padding: var(--offset-xxx-sm);

  border-radius: 5px 5px 1px 1px;

  border-bottom-color: var(--new-grey3);
  border-bottom-width: 1px;
  border-bottom-style: solid;

  .content {
    font-weight: 600;
    text-transform: none;

    color: var(--new-grey1);

    cursor: pointer;

    &:hover {
      color: var(--new-blue1);
    }
  }

  &.selected {
    background-color: var(--new-blue6);
    border-bottom-color: var(--new-blue4);

    .content {
      color: unset;
    }
  }

  &:hover {
    background-color: var(--new-grey6);
    border-bottom-color: var(--new-blue4);
  }
}
