@import '_scss-variables.scss';

.confirmAssignmentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .imageContainer {
    position: relative;
    width: 50%;

    .imageButtonsContainer {
      position: fixed;
      top: 85%;
      left: 52%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 1000000;

      .imageButtonGroup button {
        background-color: #333966;
        color: white;
        white-space: nowrap;
      }
    }
  }
}

.imageContainer img {
  width: 100%;
  height: auto;
}
