@import '_scss-variables.scss';

.standardNodeContainer {
  display: flex;
  margin-bottom: var(--offset-xx-sm);
  align-items: stretch;

  .leftBorderContainer {
    display: flex;
    .leftBorder {
      height: calc(100% + 18px);
      width: 0;
      border-left: 1px solid var(--new-grey5);
      margin: -18px 13px 0 7px;
    }

    .leftBorder.transparent {
      border-left: 1px solid transparent;
    }
  }

  .iconContainer {
    position: relative;

    width: 28px;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    > svg {
      z-index: 1;
      margin-left: 1px;
      cursor: pointer;
    }
  }

  .iconContainer::before {
    content: '';
    position: absolute;
    left: 7px;
    top: -12px;
    border-left: 1px solid var(--new-grey5);

    height: calc(var(--before-height) + 11px);
  }

  .iconContainer.rootWithNoChildrenAndHasSiblings::before {
    top: 20px;

    @media screen and (max-width: $max-tablet-screen) {
      top: 16px;
    }
  }

  .iconContainer.rootWithNoChildrenAndNoSiblings,
  .iconContainer.rootWithNoChildrenAndNoSiblings::before,
  .iconContainer.rootWithChildrenAndNoSiblings::before {
    display: none;
  }

  .iconContainer.rootWithChildrenAndHasSiblings::before {
    top: 20px;
    height: calc(var(--before-height) + 6px);
  }

  .iconContainer.lastRootStandard::before {
    height: calc(100% - 5px);

    @media screen and (max-width: $max-tablet-screen) {
      height: calc(100% - 1px);
    }
  }

  .iconContainer.lastChild::before {
    height: calc(50% + 12px);
  }

  .iconContainer.leafChild::before {
    height: var(--before-width);
  }

  .standardNode {
    display: flex;
    flex-direction: column;

    width: 100%;

    .standardBox {
      min-height: var(--offset-x-lg);
      padding: 0 var(--offset-xx-sm);
      background: var(--white);

      flex: 1;

      user-select: none;

      display: flex;
      align-items: center;
      justify-content: space-between;

      border: 1px solid var(--new-grey5);
      box-sizing: border-box;
      border-radius: 2px;

      cursor: pointer;

      @media screen and (max-width: $max-tablet-screen) {
        min-height: 32px;
      }

      .standardCode {
        color: var(--new-blue2);

        font-weight: 800;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        text-decoration-line: underline;

        margin-right: 5px;

        cursor: pointer;
      }

      .standardDescription {
        color: var(--new-blue4);
        margin-right: 5px;
      }

      .standardChildrenCount {
        color: var(--new-blue3);
      }

      .standardMsaWrapper {
        height: var(--offset-md);

        display: flex;
        align-items: center;

        padding: 0 var(--offset-xx-sm);
        margin: 0 10px;

        border-radius: 5px;
        box-sizing: border-box;

        @media screen and (max-width: $max-tablet-screen) {
          height: var(--offset-sm);
        }

        > p {
          color: var(--white);
        }

        &.major {
          background: var(--new-yellow1);
        }

        &.supporting {
          background: var(--new-yellow4);
        }

        &.additional {
          background: var(--new-yellow5);

          > p {
            color: var(--new-yellow1);
          }
        }
      }

      position: relative;
    }

    .standardBox:after {
      content: '';
      position: absolute;
      width: 21px;
      left: -22px;
      top: 50%;
      border-top: 1px solid var(--new-grey5);
    }

    .standardBox.rootWithNoChildrenAndNoSiblings:after {
      display: none;
    }

    .standardBox.lastChildAndHasChildren {
      border-bottom-left-radius: 0;
    }

    .standardBox.hasNoChildren {
      cursor: default;
    }

    .assignmentsContainer.lastChildAndHasChildren,
    .assignmentsContainer.hasChildren {
      border-left: 1px solid var(--new-grey5);
    }
  }
}
