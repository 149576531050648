@import '_scss-variables.scss';

.dataCell {
  padding: var(--offset-xx-sm);
  padding-left: 0px;
  background-color: white;
}

.checkboxItem {
  display: flex;
  align-items: center;
}

.checkbox {
  padding: 0px 5px 0px 0px;
}

.stepDescription {
  font-weight: 600;
}
.resourceDescription {
  font-weight: 600;
  cursor: pointer;
}
