.workContainer .imageContainer {
  width: 100%;
}

.workContainer .imageContainer img {
  width: 100%;
}

.workContainer > .workBar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  text-align: center;
  padding: 0.5rem;
}
.workContainer > .workBar > * {
  margin: 0;
}
.workContainer > .workBar > .workDate {
  font-weight: 300;
  font-size: 10px;
  color: var(--dark-grey);
}
.workContainer > .workBar > .workType {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--darker-blue);
}
.workContainer > .workBar > .workPaginator {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.workContainer > .workBar > .workPaginator > div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 14px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}
.workContainer > .workBar > .workPaginator > div.selected {
  background-color: var(--dark-grey);
  color: #fff;
}

.workPlaceholder {
  margin: var(--offset-lg) var(--offset-x-sm) 0 var(--offset-x-sm);
  padding: var(--offset-lg);

  background: var(--new-grey7);
  border: 1px dashed var(--new-grey5);
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.workPlaceholder > svg {
  width: 40px;
  height: 40px;
  margin: auto;
}
