.strategyContainer {
  padding-top: var(--offset-sm);
  padding-bottom: var(--offset-sm);
  border-top: 1px solid var(--new-grey7);
}

.summaryData {
  margin: var(--offset-xx-sm) 0;
}

.selectContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--offset-xx-sm);
  flex-direction: row;
  gap: var(--offset-xxx-sm);
  width: 100%;
  > div {
    width: 100%;
  }
}
.innerData {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: var(--offset-xxx-sm);
  margin-top: var(--offset-sm);
}

.lineText {
  line-height: 15px;
  text-transform: none;
  max-width: 100%;
  word-wrap: break-word;
  color: var(--new-blue1);
}

.tooltipInfo {
  background-color: var(--new-blue2);
  width: 135px;
  max-width: 326px;
  padding: var(--offset-md) var(--offset-sm);
}

.tooltipArrow {
  color: var(--new-blue2);
}

.textAreaRoot {
  border-radius: 0px;
  border: 1px solid var(--new-grey2);
  width: 100%;
  height: auto;
}

.confirmationHeader {
  margin-bottom: var(--offset-xx-sm);
}
