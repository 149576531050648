@import '_scss-variables.scss';

.container {
  height: 94px;
  background-color: var(--white);

  border: 1px solid var(--grey-1);
  border-radius: 5px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-sizing: border-box;

  @media screen and (max-width: $max-tablet-screen) {
    border-right: 0;
    border-left: 0;

    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .content {
    display: flex;

    padding: var(--offset-sm);

    .studentInfo {
      width: 229px;

      @media screen and (max-width: $max-tablet-screen) {
        width: 150px;
      }
    }
  }
}

.text {
  font-weight: 400;
}

.filters {
  display: flex;
  align-items: baseline;

  .filter {
    margin: 0 4px;

    button {
      height: 24px;
    }
  }
}

.filtersContainer {
  & > :first-child {
    margin-bottom: var(--offset-xx-sm);
  }
}
