@import '_scss-variables.scss';

.tableContainer {
  background: var(--white);

  border: 1px solid var(--grey-1);
  border-radius: 5px;
  box-sizing: border-box;

  @media screen and (max-width: $max-tablet-screen) {
    border: 0;
  }

  .table tr:last-child td {
    border-bottom: 0;
  }

  .headerCell {
    background-color: var(--white); /* Counteracts the MUI default color */

    &:not(:first-child) {
      max-width: 110px;
      text-align: center;
    }
  }

  .cell {
    &:first-child {
      width: 23%;
    }

    &:not(:first-child) {
      text-align: center;
    }

    .noUnderline {
      text-decoration: none;
    }

    .copyLinkText {
      padding-left: var(--offset-xxx-sm);
    }
  }
}

.noResultsPlaceholderContainer {
  display: flex;
  justify-content: center;
}
