.commentSection {
  padding: var(--offset-md) var(--offset-md) var(--offset-xx-sm)
    var(--offset-md);
}

.commentSection > .comments {
  margin-bottom: var(--offset-x-sm);
}

.commentSection > .comments > .comment {
  margin-bottom: var(--offset-xx-sm);
}

.commentSection > .comments > .comment.isStudent {
  padding-left: var(--offset-md);
}

.commentSection > .comments > .comment > .author {
  font-weight: bold;
  padding-right: var(--offset-xxx-sm);
}

.commentSection > form .input {
  flex-grow: 1;
  background-color: var(--lighter-grey);
  border-radius: 5px;
  border: none;
}

.commentSection > form .input:focus {
  outline: 0;
}

.commentSection > form .submit {
  width: 41px;
  height: 41px;
  color: var(--darker-grey);
}

.commentSection > form .submit.dirty {
  color: var(--green);
}
