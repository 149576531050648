@import '_scss-variables.scss';

.incompleteAssignmentsContainer {
  margin: var(--offset-sm) var(--offset-xxx-lg) var(--offset-xx-sm)
    var(--offset-xxx-lg);

  @media screen and (max-width: $max-small-desktop-screen) {
    margin: var(--offset-sm) var(--offset-sm) var(--offset-xx-sm)
      var(--offset-sm);
  }

  @media screen and (max-width: $max-tablet-screen) {
    margin: 0;
    max-width: 100%;
  }

  .loadMoreButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: var(--offset-sm);
  }
}
