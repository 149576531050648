.container {
  display: flex;
  align-items: flex-start;
  padding: var(--offset-x-sm) var(--offset-xx-sm);
}

.columnSkeleton {
  margin-right: var(--offset-xx-sm);
}
.columnSkeleton > *:first-child {
  margin-bottom: var(--offset-xxx-sm);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.columnSkeleton > *:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
