.wrapper {
  display: flex;
  flex-direction: column;

  .matchingStudents {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: var(--offset-x-lg);

    .syncIcon {
      color: var(--new-blue3);
      width: 40px;
      height: 40px;

      animation: spin 1000ms infinite;
      animation-timing-function: linear;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(-359deg);
        }
      }
    }

    .matchingHeader {
      color: var(--new-blue4);
      margin-left: 15px;
    }
  }
}
