.testimonialsContainer {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--new-blue2);
}

.testimony {
  display: flex;
  width: 700px;
  margin: auto;
}

.testimonialText {
  margin-right: 24px;
  text-align: right;
  flex: 1;
}

.testimonialClient {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonialClient .testimonialIcon {
  margin-right: 20px;
}

.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

/* Mobile + Tablet */
@media only screen and (max-width: 992px) {
  .testimonialText {
    width: 500px;
    max-width: 100%;
    margin-bottom: 20px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }

  .testimony {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .testimonialsContainer {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .testimony {
    width: 100%;
  }
  .testimonialText {
    width: 100%;
    padding: 0 24px;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 17.73px;
  }
  .testimonialClient .testimonialIcon {
    margin-right: 10px;
  }
  .avatar {
    width: 40px;
    height: 40px;
  }
}
