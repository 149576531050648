.row {
  cursor: pointer;

  .cell {
    &:first-child {
      width: 23%;
    }

    &:not(:first-child) {
      text-align: center;
    }

    .noUnderline {
      text-decoration: none;
    }

    .copyLinkText {
      padding-left: var(--offset-xxx-sm);
    }
  }
}
