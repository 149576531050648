@import '_scss-variables.scss';

.container {
  @media screen and (max-width: $max-mobile-screen) {
    display: none;
  }
}

.item {
  color: var(--new-grey2);
}

.itemSelected {
  color: var(--new-grey1);
}

.optionContainer {
  display: flex;
  align-items: center;

  color: white;

  > svg {
    margin-right: 5px;
  }
}

.menuList {
  padding: 0;

  .optionContainer {
    color: unset;
  }

  li {
    padding: var(--offset-xx-sm) var(--offset-sm);
  }
}

.arrowDownIcon {
  color: white;
  top: auto; // Override the default top coming from MUI

  @media screen and (max-width: $max-mobile-screen) {
    display: none;
  }
}

.pageWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pageWrapper .container {
  max-width: 768px;
  width: 100%;
  margin: auto;
}

.pageWrapper .gridContainer {
  height: 48px;
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media screen and (max-width: $max-mobile-screen) {
    grid-template-columns: 48px 1fr 48px;
  }
}

.pageWrapper .title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  text-align: center;
}

.pageWrapper .topNavigation {
  padding-top: 12px;
  height: 54px;
  background-color: var(--white);
  color: var(--black);
  border-bottom: 2px solid var(--black);
}

.familyLogo {
  height: 48px;
  width: 117px;
}

.pageWrapper .content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--white);
  border: 1px var(--grey-1);

  &.greyOnDesktop {
    background-color: var(--new-grey7);

    @media screen and (max-width: $max-mobile-screen) {
      background-color: var(--white);
    }
  }
}

.group {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .viewSwitcher {
    margin-right: 90px;

    @media screen and (max-width: $max-tablet-screen) {
      margin-right: 40px;
    }

    @media screen and (max-width: $max-mobile-screen) {
      margin-right: unset;
    }
  }
}

.cameraWrapper {
  position: absolute;
  z-index: 10;
  top: -33px;
  left: 50%;
  margin-left: -33px;
  width: 66px;
  height: 66px;
  background-color: var(--white);
  border: 2px solid var(--grey);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cameraWrapper > button {
  width: 100%;
  height: 100%;
}

.cameraWrapper svg {
  fill: var(--dark-blue);
}

.filterIcon {
  width: 48px;
}

.invisible {
  visibility: hidden;
}

.profile {
  align-items: left;
}
