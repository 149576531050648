.infoBoxContainer {
  grid-column-gap: var(--offset-xx-sm);
  max-width: 1010px;
}

.paper {
  padding: var(--offset-sm) 0 var(--offset-md) 0;
  grid-column: start / end;
  border-bottom: 0 !important; /* Override default paper border style */
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--offset-sm);

  padding-left: var(--offset-sm);
  padding-right: var(--offset-sm);

  margin-bottom: var(--offset-sm);

  .shareButton {
    width: 149px;

    > span {
      &:first-child {
        width: 100px;
        justify-content: space-around;
      }
    }
  }
}

.createSortButton {
  min-width: 160px;
}

.commentsContainer {
  grid-column: start / end;
}

.comments.comments {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.chipsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.standardsContainer {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr auto auto;
}

.infoSectionTitle {
  min-width: 90px;
  margin-right: 20px;
}

.infoSection {
  display: flex;
  align-items: center;
  margin-top: var(--offset-md);
  min-height: 24px;

  padding-left: var(--offset-sm);
  padding-right: var(--offset-sm);

  margin-bottom: var(--offset-sm);
}
.infoSection:hover .addButtonContainer {
  opacity: 1;
}

.infoSection:last-of-type {
  margin-bottom: 0;
}

.commentsContainer {
  grid-column: start / end;
  margin-bottom: var(--offset-x-sm);
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.standardChip {
  max-width: 250px;
  padding: 6px 6px 6px 10px;
  height: unset;
  display: flex;
  justify-content: space-between;
}

.removeStandardIcon {
  cursor: pointer;
}

.addButtonContainer {
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  width: 250px;
  transition: opacity 0.3s linear;
}
