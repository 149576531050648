.bold {
  font-weight: 700;
}

.offset {
  margin-bottom: var(--offset-xx-sm);
}

.currentPaymentMethod {
  margin-left: var(--offset-sm);
}

.header {
  background-color: var(--grey-7);
  padding: var(--offset-sm) var(--offset-lg);

  margin-bottom: var(--offset-lg);

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
