.addCommentBox {
  margin: 0;
  padding: 10px;
  border-radius: 5px;

  width: 200px;
  height: 200px;
  box-shadow: 0 0 10px var(--new-grey3);
  background-color: var(--white);
}
.addCommentArrow {
  font-size: 20px;
  color: var(--white);
  top: 3px; /*Fixes the placement of the arrow after the changes to the size*/
}
.addCommentPopper {
  pointer-events: all;
}
.commentInput {
  height: 134px;
}
