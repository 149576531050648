.meetingShareSkeletonWrapper {
  position: relative;
}

.notes {
  display: flex;
}

.notesContent {
  width: 100%;
  margin-left: var(--offset-xx-sm);
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  grid-column-gap: var(--offset-xx-sm);
  margin-top: var(--offset-xx-sm);
}

.cardContent {
  padding: 0;
  padding-bottom: 0 !important; /* Override mui card default padding*/
}

.contentSection {
  padding: var(--offset-x-sm);
}

.flexWithSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.animationStep {
  margin: var(--offset-sm) 0;
  position: absolute;
  width: 100%;
}

.animationFirstStep {
  animation: firstStepTransition;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
}
@keyframes firstStepTransition {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animationSecondStep {
  animation: secondStepTransition;
  animation-duration: 2.1s;
  animation-iteration-count: infinite;
}
@keyframes secondStepTransition {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  67% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 320px);
    grid-column-gap: var(--offset-xx-sm);
  }
}
