.acceleratedOuterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 26px;
}

.acceleratedInnerContainer {
  display: flex;
  gap: var(--offset-xxx-sm);
  justify-content: center;
  align-items: center;
}

.acceleratedText {
  color: var(--dark-blue);
}

.acceleratedTooltip {
  background-color: var(--new_blue9);
}
