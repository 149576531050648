@import '_scss-variables.scss';

.wrapper {
  top: 0;
  left: 0;
  height: 100vh;

  overflow: scroll;

  background-color: var(--white);

  padding: var(--offset-x-lg);

  @media screen and (max-width: 1024px) {
    padding: var(--offset-sm);
  }

  .header {
    display: flex;
    justify-content: space-between;

    align-items: center;

    .buttonWrapper {
      display: flex;
      justify-content: space-between;

      width: 250px;

      @media screen and (max-width: 1024px) {
        grid-area: buttons;
        display: block;

        width: 100px;
      }
    }

    .nextButton {
      height: 40px;
      width: 110px;
      margin-bottom: var(--offset-sm);
    }

    .backButton {
      background-color: var(--new-blue6);
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .loadingOverlay {
    top: 0;
    left: 0;
    position: fixed;

    width: 100%;
    height: 100%;
    z-index: 2;

    background-color: rgba(255, 255, 255, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: wait;

    animation: fadeIn 0.2s linear;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    &:after {
      content: '';
      width: 60px;
      height: 60px;

      border-radius: 50%;
      border: 6px solid var(--new-blue5);
      border-bottom-color: var(--new-blue4);

      animation: spin 0.6s linear infinite;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(359deg);
        }
      }
    }
  }
}
