.paper {
  border-radius: 5px;
  margin: 16px 20px 10px 20px;
  box-shadow: none;
  border: 1px solid var(--new-grey5);
}

.assignmentInfo {
  padding: 20px;
}

.flexRowContainer {
  display: grid;
  grid-template-columns: 100px 300px 1fr;
  grid-gap: 20px;
  margin-top: 15px;
  margin-bottom: 16px;
}

.progressBarContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.progressBarContainer > * + * {
  margin-left: 10px;
}

.tasksContainer {
  padding: 0 20px 20px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 330px);
  grid-gap: 10px;
}
/* Tablet Landscape and below */
@media only screen and (max-width: 1024px) {
  .tasksContainer {
    grid-template-columns: repeat(auto-fit, 320px);
  }
}

.studentTaskCard {
  width: 330px;
}

.attachment {
  display: inline-flex;
  align-items: flex-end;
  margin-top: 10px;
}

.attachmentName {
  margin-left: 10px;
}
